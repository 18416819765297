<template>
    <div> 
    <nav class=" mobile-footer navbar-light" v-if="windowWidth < 767">
         <div class="text-center" style="min-width:100%;"> 

      <!-- <p class="pb-2">© 2019-2020 reQuid Technologies Ltd.</p> -->

      <p class="pt-4">  © 2019-2021 reQuid Technologies Ltd. </p>
      <p>71A Molade Okoya Thomas Street, Victoria Island, Lagos, Nigeria.</p>


        <!-- <p>71A Molade Okoya Thomas Street, Victoria Island, Lagos, Nigeria.</p> -->

    </div>
</nav>
       <nav class="navbar fixed-bottom navbar-light" v-if="windowWidth > 767">
         <div class="text-center" style="min-width:100%;"> 

          <p >© 2019-2021 reQuid Technologies Ltd.</p>
             <p>71A Molade Okoya Thomas Street, Victoria Island, Lagos, Nigeria.</p>


         </div>
</nav>
    </div>
</template>

<script>
import {
    commonJs
} from '../dashboard/mixins/commonJs.js';
export default {
mixins: [commonJs],

}

</script>

<style scoped>
.fixed-bottom{
  background: #fff;
  z-index: 1 !important; 
  display: flex;
  align-items: center;
  min-height: 40px;
}
.mobile-footer{
  background: #fff;
  z-index: 1 !important; 
  display: flex;
  align-items: center;
  min-height: 70px;
}
</style>
