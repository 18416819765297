import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const domElements = {
  namespaced: true,

  state: {
    sideBarStatus: true,
    filter: true,
    toggleMenu: true,
    toggleMenu2: false,
    windowWidth: null,
    dropDownStatus: false,
    tipsObject: {
      firstTip: true,
      secTip: false,
      thiTip: false,
      fourTip: false
    },

    showTip: true,

    userNotificationMessage: "",
    notifyUserFromDomStore:false,
    productAddedToCart:false,
    notifyUserOfSuccess:false,
    notifyUserOfSuccessMessage:''
  },

  mutations: {

    startAddedToCartAnimation(state) {
      state.productAddedToCart = true;
    },

    stopAddedToCartAnimation(state) {
      state.productAddedToCart = false;
    },

    openNotificationMessage(state) {
      state.notifyUserFromDomStore = true;
    },


    openSuccessNotificationMessage(state) {
      state.notifyUserOfSuccess = true;
    },

    closeSuccessNotificationMessage(state) {
      state.notifyUserOfSuccess = false;
    },
   
    saveNotificationMessage(state, payload) {
      state.userNotificationMessage = payload;

    },
    closeNotificationMessage(state) {
      state.notifyUserFromDomStore = false;
    },

    hideTip(state) {
      state.showTip = false;
    },

    showSideBar(state) {
      state.sideBarStatus = true;
    },

    activateDropDownStatus(state) {
      state.dropDownStatus = true;
    },
    
    deactivateDropDownStatus(state) {
      state.dropDownStatus = false;
    },

    hideSideBar(state) {
      state.sideBarStatus = false;
    },
    showToggleMenu2(state) {
      state.toggleMenu2 = true;
      state.toggleMenu = false;
    },

    showToggleMenu1(state) {
      state.toggleMenu = true;
      state.toggleMenu2 = false;
    },

    saveWindowWidth(state, payload) {
      state.windowWidth = payload;
    }
  },
  actions: {}
};

export default domElements;
