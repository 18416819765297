const api = {
  //Auth
  login: "api/v1/login",
  logout: "api/v1/auths/logout",
  signup: "api/v1/in/signup",
  verifyToken: "api/v1/in/verification",
  resendToken: "api/v1/in/token",
  forgotPassword: "api/v1/in/password/forgot",
  updatePasssword: "api/v1/in/password/new",
  getUserProfile: "api/v1/account/profile",
  updateUserProfile: "api/v1/account/update-profile",
  verifyActivatorLink: "api/v1/in/verification/activator",
  completeActivatorProcess: "api/v1/in/token/activator",
  activatorApi: "api/v1/in/signup/activator",
  activatorank: "api/v1/activator/ranking",
  changePassword: "api/v1/account/password",
  saveBvn: "api/v1/account/bvn",
  forgotPin: "api/v1/account/pin/reset",
  verifyEmailToken: "api/v1/in/pin/verifyEmailToken",

//Get Products
getAllProducts: "api/v1/product",
getProductById: "api/v1/product/detail",
getAllAtps: "api/v1/product/atps",
testimonial: "api/v1/account/testimonial",
pin: "api/v1/account/pin",
resetPin: "api/v1/account/pin/reset",
getBookingSettings: "api/v1/booking/settings",
bookFunds:"api/v1/booking/funds",
bookFundsHistory:"api/v1/booking/funds/history",
bookFundsSummary:"api/v1/booking/funds/summary",
mailingList:"api/v1/notifications/list",
euroBondHistory:"api/v1/product/eurobond/history",
getEurobondata:"api/v1/product/eurobond",
purchaseEuroBond:"api/v1/product/eurobond",

//Sell Portfolio
sellProduct:"api/v1/transaction/part",
getTransHistory:"api/v1/transaction/summary",
//cancelsale
cancelSale:"api/v1/transaction/sale",
tags:"api/v1/transaction/tags",

verifyPhone:"api/v1/account/phone/verification",
verifyPhoneToken:"api/v1/account/phone/verification/confirmation",
updatePhone:"api/v1/account/phone/update",

  //Product detail
  atpDetail: "api/v1/product/atps/id",

  //Payment
  payOffline: "api/v1/account/funding/offline-payment-details",
  payOnline: "api/v1/account/funding/web",
  addBankAccount: " api/v1/account/bank",
  verifyPaymentByCard: "api/v1/billing/transaction",
  withdrawFund: "api/v1/account/funds",
  withdrawDollarFund: "api/v1/account/funds/usd",

  // purchase product

  //Banks
  getAllBanks: "api/v1/account/bank",
  getUserAccountName: "api/v1/account/bank/name",
submitKyc:"api/v1/account/kyc",
  //add,get,delete and update cart items in one Api
  addGetDeleteUpdateCart: "api/v1/product/cart",
  purchaseProduct: "api/v1/product",
  getTransaction: "api/v1/transaction",
  getWalletData: "api/v1/transaction/wallet",
  //transactions (get and sell in one api)
  getSellTransactions: "api/v1/billing/transaction",
  getAccount: "api/v1/account",
  getUserBalance: "api/v1/account/data/funding",

  //vestLock
  // getOneVestLockProduct: "api/v1/vestlock/product/one",
  getVestLockProduct: "api/v1/vestlock/product",

  getVestPortfolio: "api/v1/vestlock/transactions",
  purchaseVestProduct: "api/v1/vestlock/product",


  //Transfer
  transferInvestment:"api/v1/transfers/investments",
  transferFunds:"api/v1/transfers/funds",
  transferFundsToRecipient:"api/v1/transfers/claim",
};

export default api;
