import { mapState } from "vuex";
import trans from "../../../../translations";
import http from "../../../http/index";
import api from "../../../apis/index";
import moment from "moment";

window.$ = window.jQuery = require("jquery");
export const commonJs = {
  data() {
    return {
      tran: "",
      noResult: false,
      rfqSelected: "",
      trans: {},
      sideBarModal: false,
      removeBar: false,
      radio: "",
      secondaryRate: "",
      imgUrlFromServer: "",
      productUrl: "",
      dollarProduct:{},
      payInactive: true,
      informUserAboutRateChange: true
    };
  },

  methods: {
    getTranslations() {
      this.tran = trans;
    },

    emptySearchString(event) {
      this.$store.commit("market/emptySearchString");
      event.target.value = "";
    },

    sendToSearchPage(event) {
      this.$store.commit("market/saveSearchString", event.target.value);
    },

    searchCart(event) {
      this.$store.commit("market/saveSearchString", event.target.value);
    },
    gotoMarket() {
      this.$router.push("/market")
    },

    searchPortfolio(event) {
      this.$store.commit("market/saveSearchString", event.target.value);
    },

    searchWallet(event) {
      this.$store.commit("market/saveSearchString", event.target.value);
    },


    checkUnitValue(event, prod) {
      let unitAdded = parseInt(event.target.value, 10);

      unitAdded = isNaN(unitAdded) ? 0 : unitAdded;
      let totalAmountUserIsBuyingFor = unitAdded * prod.cost_per_unit;

      //check if unit added is greater than 1 change unit to units

      // if (unitAdded == 1) {
      //   event.target.parentNode.parentNode.querySelector(
      //     ".unit-string"
      //   ).textContent = this.tran.unit;
      // }

      // if (unitAdded > 1) {
      //   event.target.parentNode.parentNode.querySelector(
      //     ".unit-string"
      //   ).textContent = this.tran.units;
      // }

      let atpRate = this.getAtpRate(prod, totalAmountUserIsBuyingFor);

      if (prod.category !== "resell") {
        let rateOfUser = atpRate * 0.01;
        let finalAmount = rateOfUser * totalAmountUserIsBuyingFor;

        event.target.parentNode.parentNode.parentNode.parentNode.querySelector(
          ".user-amount"
        ).textContent = (
          parseFloat(totalAmountUserIsBuyingFor) + parseFloat(finalAmount)
        )
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        event.target.parentNode.parentNode.parentNode.parentNode.querySelector(
          ".cost-unit"
        ).textContent = parseFloat(totalAmountUserIsBuyingFor)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        event.target.parentNode.parentNode.parentNode.parentNode.querySelector(
          ".user-roi"
        ).textContent = " " + this.formatAmount(atpRate) + "%";

        //   if (totalAmountUserIsBuyingFor < prod.vendor_cost_per_unit && this.informUserAboutRateChange) {

        //     this.$toast.warn({

        //       timeOut: 5000,
        //       message:
        //         this.tran.you_are_buying_less_than +' '+ prod.atp +' ' +  this.tran.current_rate + atpRate+'%',
        //       position: "top right"
        //     });

        //   this.informUserAboutRateChange = false;

        // }

        // if(totalAmountUserIsBuyingFor >= prod.vendor_cost_per_unit &&   this.informUserAboutRateChange == false){

        //   this.$toast.info({
        //     timeOut: 5000,
        //     message: this.tran.your_roi_is_now_on_par + ' ' + prod.atp + this.tran.current_rate + atpRate + '%',
        //     position: "top right"
        // });
        //   this.informUserAboutRateChange = true
        // }
      }

      if (prod.category == "resell") {
        let totalAmountUserIsBuyingFor = unitAdded * prod.cost_per_unit;

        let rateOfUser = prod.resell_roi * 0.01;

        let finalAmount = rateOfUser * totalAmountUserIsBuyingFor;

        this.$helpers.log(finalAmount, "roi flag");

        event.target.parentNode.parentNode.parentNode.parentNode.querySelector(
          ".user-amount"
        ).textContent = (
          parseFloat(totalAmountUserIsBuyingFor) + parseFloat(finalAmount)
        )
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        event.target.parentNode.parentNode.parentNode.parentNode.querySelector(
          ".cost-unit"
        ).textContent = (parseFloat(prod.cost_per_unit) * parseFloat(unitAdded))
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      if (event.target.value > 0) {
        event.target.parentNode.parentNode.parentNode.parentNode
          .querySelector(".fa-minus")
          .classList.remove("fade-elem");
        event.target.parentNode.parentNode.parentNode.parentNode
          .querySelector(".cart-btn")
          .classList.remove("fade-elem");
      } else {
        event.target.parentNode.parentNode.parentNode.parentNode
          .querySelector(".cart-btn")
          .classList.add("fade-elem");
        event.target.parentNode.parentNode.parentNode.parentNode
          .querySelector(".fa-minus")
          .classList.remove("fade-elem");
        event.target.value = "";
      }

      if (
        prod.units_available < unitAdded ||
        prod.resell_units_available < unitAdded
      ) {
        event.target.parentNode.parentNode.parentNode.parentNode.querySelector(
          ".user-amount"
        ).textContent = "";
        event.target.parentNode.parentNode.parentNode.parentNode.querySelector(
          ".cost-unit"
        ).textContent = "";

        event.target.parentNode.parentNode.parentNode.parentNode
          .querySelector(".fa-minus")
          .classList.add("fade-elem");

        event.target.parentNode.parentNode.parentNode.parentNode
          .querySelector(".cart-btn")
          .classList.add("fade-elem");

        event.target.value = event.target.value.substring(
          0,
          unitAdded.length - 1
        );

        this.$toast.warn({
          timeOut: 5000,
          icon: require("../../../assets/images/cautionicon.png"),
          message:
            this.tran.please_put_a_val +
            (prod.units_available
              ? prod.units_available
              : prod.resell_units_available) +
            " " +
            this.tran.unit_s.toLowerCase(),
          position: "top right"
        });
      }
      if (
        unitAdded == prod.units_available ||
        unitAdded == prod.resell_units_available
      ) {
        event.target.parentNode.parentNode.parentNode.parentNode
          .querySelector(".fa-plus")
          .classList.add("fade-elem");
      }
    },

    getAtpRate(prod, totalAmountUserIsBuyingFor) {
      let atpRate = prod.roi;
      let unitOfAtp = prod.vendor_cost_per_unit;
      if (prod.category == "primary") {
        if (totalAmountUserIsBuyingFor < unitOfAtp && prod.unitIsLessThanAtp1Unit == false) {
          if (prod.roi > 24) {
            return (atpRate = prod.roi - this.roiSetting.less_atp_unit_gt24);
          } else if (prod.roi >= 15) {
            return (atpRate = prod.roi - this.roiSetting.less_atp_unit_gt15);
          } else if (prod.roi < 15) {
            return (atpRate = prod.roi - this.roiSetting.less_atp_unit_lt15);
          }
        }
      }

      return atpRate;
    },
    
    onlyNumber($event) {
      //this.$helpers.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    getUserRoi(event, prod, actionType) {
      let unitAdded = parseInt(
        event.target.parentNode.querySelector(".cart-input").value,
        10
      );

      event.target.parentNode.parentNode.parentNode.parentNode.querySelector(
        ".user-amount"
      ).textContent;

      unitAdded = isNaN(unitAdded) ? 0 : unitAdded;
      if (actionType == "add") {
        unitAdded++;
      } else if (actionType == "minus") {
        unitAdded--;
      }

      if (prod.units_available < unitAdded) {
        event.target.parentNode
          .querySelector(".fa-plus")
          .classList.add("fade-elem");
      }

      let totalAmountUserIsBuyingFor = unitAdded * prod.cost_per_unit;

      let atpRate = this.getAtpRate(prod, totalAmountUserIsBuyingFor);

      if (prod.category !== "resell") {
        let rateOfUser = atpRate * 0.01;

        let finalAmount = rateOfUser * totalAmountUserIsBuyingFor;

        event.target.parentNode.parentNode.parentNode.parentNode.querySelector(
          ".user-amount"
        ).textContent = (totalAmountUserIsBuyingFor + finalAmount)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        event.target.parentNode.parentNode.parentNode.parentNode.querySelector(
          ".cost-unit"
        ).textContent = parseFloat(totalAmountUserIsBuyingFor)
          .toFixed(2)
          .toString()

          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        event.target.parentNode.parentNode.parentNode.parentNode.querySelector(
          ".user-roi"
        ).textContent = this.formatAmount(atpRate)+"%" + " Interest";

        let minUnit = Math.ceil(prod.vendor_cost_per_unit / prod.cost_per_unit);

        let totalUnitAvailable = prod.units_available * prod.cost_per_unit;

        if (
          totalAmountUserIsBuyingFor < prod.vendor_cost_per_unit &&
          this.informUserAboutRateChange &&
          totalUnitAvailable &&
          prod.category == "primary" &&
          minUnit <= prod.units_available
        ) {
          this.$toast.warn({
            title:
              this.tran.new_rate + "(" + this.formatAmount(atpRate) + "%" + ")",
            timeOut: 6000,
            icon: require("../../../assets/images/cautionicon.png"),
            message:
              this.tran.you_are_buying_less_than +
              //" " +
              //prod.atp +
              //this.tran.current_rate +
              //atpRate +
              //"%"
              minUnit +
              " " +
              this.tran.unit_s.toLowerCase(),
            position: "top right"
          });

          this.informUserAboutRateChange = false;
        }

        if (
          totalAmountUserIsBuyingFor >= prod.vendor_cost_per_unit &&
          this.informUserAboutRateChange == false &&
          totalUnitAvailable > prod.vendor_cost_per_unit &&
          prod.category == "primary"
        ) {
          this.$toast.info({
            title:
              this.tran.new_rate + "(" + this.formatAmount(atpRate) + "%" + ")",
            timeOut: 6000,
            icon: require("../../../assets/images/successicon.png"),
            message:
              this.tran.your_roi_is_now_on_par +
              //" " +
              //prod.atp +
              this.tran.current_rate +
              atpRate +
              "%",
            position: "top right"
          });
          this.informUserAboutRateChange = true;
        }
      }

      if (prod.category == "resell") {
        let totalAmountUserIsBuyingFor = unitAdded * prod.cost_per_unit;

        let rateOfUser = prod.resell_roi * 0.01;

        let finalAmount = rateOfUser * totalAmountUserIsBuyingFor;

        event.target.parentNode.parentNode.parentNode.parentNode.querySelector(
          ".user-amount"
        ).textContent = (
          parseFloat(totalAmountUserIsBuyingFor) + parseFloat(finalAmount)
        )
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        event.target.parentNode.parentNode.parentNode.parentNode.querySelector(
          ".cost-unit"
        ).textContent = (parseFloat(prod.cost_per_unit) * parseFloat(unitAdded))
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      if (actionType == "add") {
        event.target.parentNode.parentNode.parentNode.parentNode
          .querySelector(".cart-btn")
          .classList.remove("fade-elem");

        event.target.parentNode.parentNode.parentNode.parentNode
          .querySelector(".fa-minus")
          .classList.remove("fade-elem");

        var value = parseInt(
          event.target.parentNode.querySelector(".cart-input").value,
          10
        );

        value = isNaN(value) ? 0 : value;
        value++;

        event.target.parentNode.querySelector(".cart-input").value = value;

        if (
          unitAdded == prod.units_available ||
          unitAdded == prod.resell_units_available
        ) {
          event.target.parentNode.parentNode.parentNode.parentNode
            .querySelector(".fa-plus")
            .classList.add("fade-elem");
        }
        // event.target.parentNode.parentNode.querySelector(
        //   ".unit-string"
        // ).textContent = this.tran.units;
      } else if (actionType == "minus") {
        let inputValue = event.target.parentNode.querySelector(".cart-input")
          .value;
        if (inputValue !== 0 && inputValue !== "") {
          var value = parseInt(
            event.target.parentNode.querySelector(".cart-input").value,
            10
          );
          value = isNaN(value) ? 0 : value;
          value--;
          this.cartInput = value;
          event.target.parentNode.querySelector(".cart-input").value = value;
          event.target.parentNode
            .querySelector(".fa-plus")
            .classList.remove("fade-elem");
        }

        if (event.target.parentNode.querySelector(".cart-input").value < 2) {
        
          event.target.parentNode
            .querySelector(".fa-minus")
            .classList.add("fade-elem");
          event.target.parentNode
            .querySelector(".fa-plus")
            .classList.remove("fade-elem");
        }

        //Change units to unit when user selects up to 1 unit
        // if (event.target.parentNode.querySelector(".cart-input").value == 1) {
        //   event.target.parentNode.parentNode.querySelector(
        //     ".unit-string"
        //   ).textContent = this.tran.unit;
        // }
      }
    },
    showMobileDetailComponent(prod, category) {

      try{

        ga("send", "event", "engagement","click product", "product clicked");

      }

      catch{


      }
      this.$helpers.log(this.$route.params.id, "params ID");

      if (category == "upcoming") {
        this.$store.commit("market/activateIsComingSoon");
      } else {
        this.$store.commit("market/deactivateIsComingSoon");
      }
      this.$store.commit("market/showMobileProductDetailComponent");
      this.$store.commit("market/saveProductObject", prod);

      try{
        this.$router.push({
          params: {
            id: prod.id
          },
          path: `/market/${prod.category}/${prod.name
            .toLowerCase()
            .split(" ")
            .join("")}/${prod.id}`
        });
      }

      catch{


      }
   
      if (
        prod.defaultUnitSelected >= prod.units_available ||
        prod.defaultUnitSelected >= prod.resell_units_available
      ) {
        this.$store.commit("market/stopAddUnitsonMobile");
      } else {
        this.$store.commit("market/AddUnitsonMobile");
      }
    },

    showMobileComponentWithUrl(prod, category) {

      try{

        ga("send","event", "engagement","click product", "product clicked");

      }

      catch{

      }
      if (category == "upcoming") {
        this.$store.commit("market/activateIsComingSoon");
      } else {
        this.$store.commit("market/deactivateIsComingSoon");
      }

      if (
        prod.defaultUnitSelected >= prod.units_available ||
        prod.defaultUnitSelected >= prod.resell_units_available
      ) {
        this.$store.commit("market/stopAddUnitsonMobile");
      } else {
        this.$store.commit("market/AddUnitsonMobile");
      }

      this.$store.commit("market/showMobileProductDetailComponent");
      this.$store.commit("market/saveProductObject", prod);

      // this.$router.push({
      //   params: {
      //     id: prod.id
      //   },
      //   path: `/market/${prod.category}/${prod.id}`
      // });
    },

    getProductObject(prod, event) {

      try{

        ga("send", "event", "engagement","click product", "product clicked");

      }

      catch{

      }
      this.$helpers.log(
        event.currentTarget.parentNode.parentNode.querySelector(".user-roi")
      );
      event.preventDefault();
      event.currentTarget.parentNode.parentNode
        .querySelector(".img-div-1")
        .classList.add("showHideDiv");

      let unitAdded = parseInt(
        event.currentTarget.parentNode.querySelector(".cart-input").value,
        10
      );

      if (
        prod.units_available < unitAdded ||
        prod.units_available == unitAdded ||
        prod.resell_units_available < unitAdded ||
        prod.resell_units_available == unitAdded
      ) {
        event.currentTarget.parentNode
          .querySelector(".fa-plus")
          .classList.add("fade-elem");
      }

      unitAdded = isNaN(unitAdded) ? 0 : unitAdded;

      let totalAmountUserIsBuyingFor =
        prod.defaultUnitSelected * prod.cost_per_unit;
      let atpRate = this.getAtpRate(prod, totalAmountUserIsBuyingFor);

      if (prod.category !== "resell") {
        this.$helpers.log(atpRate, "atp rate");
        // this.secondaryRate = atpRate;
        // this.$helpers.log(event.target.parentNode.parentNode.parentNode.parentNode.querySelector(".user-roi"))


        // ************edited this
        // event.currentTarget.parentNode.parentNode.querySelector(
        //   ".user-roi"
        // ).innerHTML = this.formatAmount(atpRate) + "%";

        // event.target.parentNode.parentNode.parentNode.parentNode.querySelector(
        //   ".user-roi"
        // ).textContent = atpRate + "%";
        let rateOfUser = atpRate * 0.01;
        let finalAmount = rateOfUser * totalAmountUserIsBuyingFor;

        if (prod.units_available > 0 || prod.resell_units_available > 0) {
          event.currentTarget.parentNode.querySelector(
            ".user-amount"
          ).textContent = (
            parseFloat(totalAmountUserIsBuyingFor) + parseFloat(finalAmount)
          )
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

          event.currentTarget.parentNode.querySelector(
            ".cost-unit"
          ).textContent = parseFloat(totalAmountUserIsBuyingFor)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      }

      if (prod.category == "resell") {
        let totalAmountUserIsBuyingFor = unitAdded * prod.cost_per_unit;

        let rateOfUser = prod.resell_roi * 0.01;

        let finalAmount = rateOfUser * totalAmountUserIsBuyingFor;
        if (prod.resell_units_available > 0) {
          event.currentTarget.parentNode.querySelector(
            ".user-amount"
          ).textContent = (
            parseInt(finalAmount) + parseInt(totalAmountUserIsBuyingFor)
          )
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

          event.currentTarget.parentNode.querySelector(
            ".cost-unit"
          ).textContent = (
            parseFloat(prod.cost_per_unit) * parseFloat(unitAdded)
          )
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        event.currentTarget.parentNode.querySelector(
          ".cart-input"
        ).value = unitAdded;
      }

      this.$store.commit("market/saveProductObject", prod);

      if (this.windowWidth < 500) {
        this.$store.commit("market/showMobileProductDetailComponent");
      }
    },

    sliceData(data) {
      if (this.windowWidth >= 1540) {
        return data.slice(0, 8);
      } else if (this.windowWidth < 1540) {
        return data.slice(0, 8);
      }
    },
    gotoBvn() {
      this.$router.push("/savebvn")
      this.$toast.warn({
        title: 'KYC Completion',
        timeOut: 6000,
        message: "Please submit your BVN before proceeding.",
        position: "top right",
      });
    },

    
    modifyRate(data) {
      return (
        parseFloat(data)
          .toFixed(3)
          .replace(/\.(\d\d)\d?$/, ".$1") + "%"
      );
    },

    sendUserToAtpDetailView(data) {
      // this.$store.commit("market/saveProductObject", data);
      this.$router.push("/atp" + `/${data}` + "/detail");
    },
    floorNumb(number){
      return Math.floor(number/7)
      
      },
    //Copy to clipboard function
    copyTransIdToClipboard() {
      let testingCodeToCopy = document.querySelector("#testing-code");
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();
      const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000
      });
      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        Toast.fire({
          type: "success",
          title: this.tran.copied_to_clipboard
        });
      } catch (err) {}

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },

    //Add to cart general function
    addToCart(prod, event) {
      let cartObject = {
        product_id: prod.id,
        units: event.target.parentNode.querySelector(".cart-input").value,
        market: prod.category
      };

      this.$store.commit("market/startLoader");

      //     let infoDiv =
      //       event.currentTarget.parentNode.parentNode.parentNode.parentNode;
      //     let arrow = event.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode.querySelector(
      //       ".fa-angle-down"
      //     );
      //     let itemDivToggler =  event.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode
      //     .querySelector(".img-div-1")

      //  let imgShadow =   event.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode
      //     .querySelector(".item")

      event.target.parentNode.querySelector(".cart-input").style.opacity = 0;

      let inputField = event.target.parentNode.querySelector(".cart-input");

      setTimeout(() => {
        inputField.style.opacity = 1;
      }, 3000);
      http
        .put(api.addGetDeleteUpdateCart, cartObject)

        .then(response => {
          this.$store.commit("market/stopLoader");

          this.$router.push('/cart')

          try{

            //facebook pixel
            fbq("track", "AddToCart");
  
            ga("send", "event", "business goals", "add to cart", prod.name);

          }
          
          catch{

            
          }

          this.$helpers.log(response);
          this.$store.dispatch("market/getCartItems");

          this.$store.commit("domElements/startAddedToCartAnimation");
          this.$toast.success({
            title: this.tran.success,
            timeOut: 5000,
            icon: require("../../../assets/images/successicon.png"),
            message:
              prod.name + " " + "by" + " " + prod.atp + this.tran.added_tocart,
            position: "top center"
          });

          // infoDiv.style.display = "none";
          // arrow.style.display = "none";
          // infoDiv.classList.remove("show-elem");
          // imgShadow.classList.remove("add-shadow");
          // itemDivToggler.classList.remove("showHideDiv");
          // arrow.classList.remove("show-elem-inline");
          this.$store.commit("market/stopLoader");
          this.$store.dispatch("market/getAllProducts");
          this.$store.dispatch("market/getCategoryData", cartObject);
          setTimeout(() => {
            this.$store.commit("domElements/stopAddedToCartAnimation");
          }, 1000);
        })

        .catch(error => {
          this.$store.commit("market/stopLoader");
          this.$store.commit("domElements/saveNotificationMessage", error.response.data.message);
          this.$store.commit("domElements/openNotificationMessage");

   
          this.$helpers.log(error.response);
        });
    },

    //Format naira value function
  //   formatAmount(num) {
  //     let number = parseFloat(num)
  //     var p = number.toFixed(2).split(".");
  //     return  p[0].split("").reverse().reduce(function(acc, number, i, orig) {
  //         return  number=="-" ? acc : number + (i && !(i % 3) ? "," : "") + acc;
  //     }, "") + "." + p[1];
  // },
    formatAmount(num) {

  try{


    
    if (
      num
      .toString()
      .replace(/,/g, "")
      .indexOf(".") !== -1
      ) {
        
        
        return parseFloat(num).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
        
      } else {
        return parseFloat(num).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
        
      }
    }
    catch {}
    },

    //Format number value function
    formatNumber(num) {
     
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
     
    },

    // close Dynamic div under product card
    closeInfoDiv(event) {
      this.$store.commit("auth/saveRouteVisited", "/market");
      event.stopPropagation();  

      this.$helpers.log(
        event.currentTarget.parentNode.parentNode.parentNode.parentNode
          .parentNode
      );
      event.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode
        .querySelector(".img-div-1")
        .classList.remove("showHideDiv");
      event.currentTarget.parentNode.parentNode.parentNode.parentNode.style.display =
        "none";

      event.currentTarget.parentNode.parentNode.parentNode.parentNode.classList.remove(
        "show-elem"
      );

      event.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode
        .querySelector(".fa-angle-down")
        .classList.remove("show-elem-inline");
      event.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode
        .querySelector(".item")
        .classList.remove("add-shadow");

      event.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode.querySelector(
        ".fa-angle-down"
      ).style.display = "none";

      // event.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode
      //   .querySelector(".img-div-1")
      //   .classList.remove("showHideDiv");
      // event.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode.style.display = "none";

      // event.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode.querySelector(
      //   ".fa-angle-down"
      // ).style.display = "none";

      // event.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode.classList.remove("show-elem");

      // event.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode
      //   .querySelector(".item")
      //   .classList.remove("add-shadow");
      // this.$router.push('/market')
    },

    closeAllDivs(event) {
      this.$store.commit("auth/saveRouteVisited", "/market");

      event.currentTarget.parentNode.classList.remove("add-shadow");

      event.stopPropagation();

      event.currentTarget.parentNode.parentNode
        .querySelector(".img-div-1")
        .classList.remove("showHideDiv");

      event.currentTarget.parentNode.parentNode
        .querySelector(".info")
        .classList.remove("show-elem");
      event.currentTarget.parentNode.parentNode
        .querySelector(".info")
        .classList.add("hide-elem");
      event.currentTarget.parentNode.parentNode.querySelector(
        ".fa-angle-down"
      ).style.display = "none";
      event.currentTarget.parentNode.parentNode
        .querySelector(".fa-angle-down")
        .classList.remove("show-elem-inline");
      event.currentTarget.style.display = "none";
    },

    copyProductUrl(event) {
      let testingCodeToCopy = event.currentTarget.parentNode.querySelector(
        ".prod-url"
      );

      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();

      try {
        var successful = document.execCommand("copy");  
        var msg = successful ? "succe ssful" : "unsuccessful";
      } catch (err) {}

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },


        copyRefCode(event) {
            let testingCodeToCopy = event.currentTarget.parentNode.querySelector(
                ".ref-url"
            );

            testingCodeToCopy.setAttribute("type", "text");
            testingCodeToCopy.select();

            try {
                var successful = document.execCommand("copy");
                var msg = successful ? "successful" : "unsuccessful";
            } catch (err) {}

            /* unselect the range */
      
            window.getSelection().removeAllRanges();
        },
    sendGuestTosignUp() {
      this.$router.push("/signup");
    }
  },
  filters: {
    momentFormat: function(date) {
      return moment(date).format("ddd, D-MM-YYYY, h:mma");
    },
    momentFormatDayMonthYear: function(date) {
      return moment(date).format("D-MM-YYYY");
    },
    dateTime: function(date) {
      return moment(date).format("D-MM-YYYY, h:mma");
    },
    dateSlashTime: function(date) {
      return moment(date).format("D/MM/YYYY, h:mma");
    },
    formatTimeDate: function(date) {
      return moment(date).format("Do MMM YYYY,  h:mm a");
    },
    formatDate: function(date) {
      return moment(date).format("Do MMM, YYYY");
    },
    spaceOutNumbers(number) {
try{
  return number.toString().replace(/\d{3,4}?(?=...)/g, '$& ')

}
catch{}
    }
  },
  watch: {
    //Multi notification toaster
    notifyUser() {
      if (this.notifyUser == true) {
        this.$toast.success({
          title: this.tran.success,
          icon: require("../../../assets/images/successicon.png"),
          timeOut: 5000,
          message: this.notifyMessage,
          position: "top center"
        });
      }

      this.$store.commit("market/closeNotification");
    },
    notifyUserFromDomStore() {
      if (this.notifyUserFromDomStore) {
        this.$toast.warn({
          title: this.tran.sorry,
          icon: require("../../../assets/images/cautionicon.png"),
          timeOut: 5000,
          message: this.userNotificationMessage,
          position: "top center"
        });
      }
      this.$store.commit("domElements/closeNotificationMessage");
    },
    
  },
  computed: {
    ...mapState({
      products: state => state.market.allProducts,
      customerRoiAmount: state => state.market.customerRoiAmount,
      searchString: state => state.market.searchString,
      loader: state => state.market.loader,
      searchPageStatus: state => state.market.searchPageStatus,
      userProfileInfo: state => state.auth.userProfileInfo,
      isFetching: state => state.market.isFetching,
      windowWidth: state => state.domElements.windowWidth,
      preLoader: state => state.market.preLoader,
      notifyUser: state => state.market.notifyUser,
      cartTotalAmount: state => state.market.cartTotalAmount,
      cartTotalUnits: state => state.market.cartTotalUnits,
      notifyMessage: state => state.market.notifyMessage,
      cartItemLength: state => state.market.cartItemLength,
      cartItemData: state => state.market.cartItemData,
      roiSetting: state => state.market.roiSetting,
      token: state => state.auth.token,
      bvnRedirectRoute: state => state.auth.bvnRedirectRoute,
      authLoader: state => state.auth.loader,
      userNotificationMessage: state =>
        state.domElements.userNotificationMessage,
      notifyUserFromDomStore: state => state.domElements.notifyUserFromDomStore,
      totalBookedAccruedInterestValue: state => state.market.totalBookedAccruedInterestValue,
      totalBookedValue: state => state.market.totalBookedValue,
    }),

    filteredList() {
      let vm = this;
      return this.products.filter(prod => {
        if (
          prod.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
          prod.atp.toLowerCase().includes(this.searchString.toLowerCase())
        ) {
          if (prod) {
            vm.noResult = false;
          }
          return prod;
        }
      });
    },

    returnNoResult() {
      return this.noResult;
    },
    serVerImage() {
      return this.imgUrlFromServer;
    }
  },

  beforeDestroy() {
    this.$store.commit("market/stopLoader");
    // this.$store.commit("market/closeNotification");
  },

  mounted() {
    let dollarFromLocalStorage = localStorage.getItem('dollarProduct')

    this.dollarProduct =JSON.parse(dollarFromLocalStorage) 
    this.getTranslations();

    setTimeout(() => {
      $(document).on("click", ".item", function(e) {
        e.preventDefault();
        e.stopPropagation();

        $(".item").removeClass("add-shadow");
        $(".scrollDiv").removeClass("intro");
        $("div.img-div-1").css("display", "none");
        $("div.img-div-1").removeClass("showHideDiv");
        $("div.info").css("display", "none");
        $(".fa-angle-down").css("display", "none");

        $(this)
          .find("+ div.info")
          .css("display", "block");

        $(this)
          .find("+ div.info")
          .removeClass("hide-elem");

        $(this)
          .find("+ div.info")
          .find(".cart-input")
          .focus();

        $(this)
          .find(".scrollDiv")
          .addClass("intro");

        $(this)
          .find("div.img-div-1")
          .addClass("showHideDiv");

        $(this)
          .find(".fa-angle-down")
          .css("display", "inline");
        $(this).addClass("add-shadow");

        $("html, body").animate(
          {
            scrollTop: $(".intro").offset().top
          },
          10
        );
      });
    }, 50);

    // let baseUrl = `${process.env.VUE_APP_API_URL}`;
    //this.productUrl = `${process.env.VUE_APP_PRODUCT_URL}`;

    setTimeout(() => {
      let baseUrl = localStorage.getItem("baseUrl");

      this.imgUrlFromServer = baseUrl + "storage/";
      if (baseUrl == "https://staging-api.requid.com/") {
        this.productUrl = "https://stage.requid.com";
      } else {
        this.productUrl = "https://market.requid.com";
      }
    }, 500);
  }
};
