<template>
<div>
    <ul class="navbar-nav sidebar" id="accordionSidebar" v-show="sideBarStatus">

        <!-- Divider -->
        <!-- <hr class="sidebar-divider my-0"> -->

        <!-- Nav Item - Dashboard -->

        <!-- Divider -->
        <!-- <hr class="sidebar-divider"> -->

        <!-- Nav Item - Pages Collapse Menu -->
        <!-- <li class="nav-item">
            <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
          <i class="fa fa-cog"></i>

          <span>Components</span>
        </a>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                <div class="bg-white py-2 collapse-inner rounded">
                    <h6 class="collapse-header">Custom Components:</h6>
                    <a class="collapse-item" href="buttons.html">Buttons</a>
                    <a class="collapse-item" href="cards.html">Cards</a>
                </div>
            </div>
        </li> -->

        <!-- Nav Item - Utilities Collapse Menu -->
        <!-- <li class="nav-item">
            <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities" aria-expanded="true" aria-controls="collapseUtilities">
          <i class="fa fa-wrench"></i>
          <span>Utilities</span>
        </a> -->

        <!-- show children sidebar by default immediately any of them is visited by adding a class of 'show' -->
        <!-- <div id="collapseUtilities" class="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                <div class="bg-white py-2 collapse-inner rounded">
                    <h6 class="collapse-header">Custom Utilities:</h6>
                    <a class="collapse-item" href="utilities-color.html">Colors</a>
                    <a class="collapse-item" href="utilities-border.html">Borders</a>
                    <a class="collapse-item" href="utilities-animation.html">Animations</a>
                 <router-link to="/wallet">
                <i class="fa fa-folder-open-o"></i>
                <span>{{tran.wallet}}</span>
            </router-link>
                </div>
            </div>
        </li> -->
        <!-- <tippy  to="tippy02" 

        interactive="true" 
        theme="light" 
        :distance="20"
        watch-props="true"
        arrow="true"
        showOnInit="true"
        hideOnClick="false"
        sticky="true">
    <div style="padding:20px; text-align:left">
        Can coffee make you a better developer?
    <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        Voluptatibus quia, nulla! 
        Maiores et perferendis eaque, exercitationem praesentium nihil.
    </p>
    <div>
<div class="text-right">

<button class="mt-3">next 4</button>
</div>

    </div>
    </div>

</tippy> -->
        <li class="nav-item" name="tippy02" @click="emptySearchString($event)" style="padding-top:60px">
            <router-link :to="routeVisited" :class="{'router-link-active':marketPlaceRoute}">
                <img src="../../assets/images/cart3.png" height="20" style="padding-bottom:3px; padding-right:15px">
                <span>{{tran.marketPlace}}</span>
            </router-link>
        </li>

        <!-- <li class="nav-item" @click="emptySearchString($event)" v-if="token !== null">

            <router-link :to="vestRouteVisited" :class="{'router-link-active':vestLockRoute}">
              
                <img src="../../assets/images/vest3.png" height="20" style="padding-bottom:3px; padding-right:15px">
                <span>{{tran.vest_lock}}</span>
            </router-link>

        </li> -->
        <li class="nav-item" @click="emptySearchString($event)" v-if="token !== null">

            <router-link to="/portfolio">
                <img src="../../assets/images/portf3.png" height="20" style="padding-bottom:3px; padding-right:15px">
                <span>{{tran.portfolio}}</span>
            </router-link>

        </li>
        <li class="nav-item" @click="emptySearchString($event)" v-if="token !== null">

            <router-link to="/book" :class="{'router-link-active':BookingRoute}">
                <img src="../../assets/images/bookin.png" height="20" style="padding-bottom:3px; padding-right:15px">
                <span>{{tran.booking}}</span>
            </router-link>

        </li>
        <li class="nav-item" @click="emptySearchString($event)" v-if="token !== null">
            <router-link to="/wallet">
                <img src="../../assets/images/wallet3.png" height="20" style="padding-bottom:3px; padding-right:15px">
                <span>{{tran.wallet}}</span>
            </router-link>
        </li>
        <hr class="sidebar-divider">
        <!-- <li class="nav-item">
            <router-link to="/help">
               <img src="../../assets/images/help.png" height="20" style="padding-bottom:3px; padding-right:15px">
                <span>{{tran.help_supp}}</span>
            </router-link>
        </li> -->

        <!-- Nav Item - Utilities Collapse Menu -->
        <li class="li_dropdown nav-item" style="cursor:pointer">

            <a class="li_dropdown collapsed"  data-toggle="collapse" data-target="#collapseUtilities" aria-expanded="true" aria-controls="collapseUtilities">
    <img src="../../assets/images/help2.png" class="li_dropdown" height="20" style="padding-bottom:3px; padding-right:15px">
        <span class="help_menu">{{tran.help_supp}}</span>
    </a>

            <!-- show children sidebar by default immediately any of them is visited by adding a class of 'show' -->
            <div id="collapseUtilities" class="collapse" :class="{'show':dropDownStatus}" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                <div class="bg-white py-2 collapse-inner rounded">

                    <router-link to="/faq">
                        <img src="../../assets/images/faq2.png" height="20" style="padding-bottom:3px; padding-right:15px">
                        <span>{{tran.faq}}</span>
                    </router-link>
                    <!-- <router-link to="/vest/explainer">
                        <img src="../../assets/images/vest3.png" height="20" style="padding-bottom:3px; padding-right:15px">
                        <span>{{tran.vest_lock}}</span>
                    </router-link> -->

                    <router-link to="/terms">
                        <img src="../../assets/images/terms2.png" height="20" style="padding-bottom:3px; padding-right:15px">
                        <span v-if="windowWidth < 768">{{tran.terms_condition_mob}}</span>
                        <span v-else>{{tran.terms_condition}}</span>
                    </router-link>
                    <router-link to="/privacy">
                        <img src="../../assets/images/privacy.png" height="20" style="padding-bottom:3px; padding-right:15px">
                        <span>{{tran.privacy_policy}}</span>
                    </router-link>
                    <router-link to="/contact">
                        <img src="../../assets/images/support.png" height="20" style="padding-bottom:3px; padding-right:15px">
                        <span>{{tran.contact_support}}</span>
                    </router-link>

                    <router-link to="/about">
                        <img src="../../assets/images/reqside.png" height="20" style="padding-bottom:3px; padding-right:15px">
                        <span>{{tran.abt_req}}</span>
                    </router-link>
               

                </div>
            </div>

        </li>
        <li>
                 <router-link :to="$route.path" class="drift-open-chat" style="background:transparent">
                        <img src="../../assets/images/chat3.png" height="20" style="padding-bottom:3px; padding-right:15px" class="drift-open-chat">
                        <span class="drift-open-chat">{{tran.chat_us}}</span>
                    </router-link>
        </li>
        <hr class="sidebar-divider">
        <!-- <li class="nav-item" @click="emptySearchString($event)">
            <router-link to="/cart">
                <i class="fa fa-shopping-cart"></i>

                <span>{{tran.cart}}</span>
            </router-link>
        </li> -->

        <!-- <li class="nav-item">
            <a class="nav-link collapsed" href="#">
          <i class="fa fa-slideshare"></i>
          <span>{{tran.cart}}</span>
        </a>

        </li> -->

        <!-- Divider -->

        <!-- Sidebar Toggler (Sidebar) -->
        <!-- <div class="text-center d-none d-md-inline">
            <button class="rounded-circle border-0" id="sidebarToggle"></button>
        </div> -->

    </ul>
</div>
</template>

<script>
import {
    mapState
} from 'vuex';

import trans from '../../../translations'
import {
    commonJs
} from '../dashboard/mixins/commonJs.js';
import ClickOutside from 'vue-click-outside'
export default {
    mixins: [commonJs],

    data() {

        return {
            tran: {}

        }
    },

    methods: {

        hideSideBar() {
            this.$store.commit("domElements/hideSideBar");
        }
    },
    directives: {
        ClickOutside
    },
    computed: {
        ...mapState({
            sideBarStatus: state => state.domElements.sideBarStatus,
            dropDownStatus: state => state.domElements.dropDownStatus,
            routeVisited: state => state.auth.routeVisited,
              vestRouteVisited: state => state.auth.vestRouteVisited,
              bookRouteVisited: state => state.auth.bookRouteVisited,
        }), 
        marketPlaceRoute() {

            return this.$route.name === 'categoryMarket' || this.$route.name === 'atpDetail' || this.$route.name === 'dollarHome' ||  this.$route.name === 'DollarInvest'||  this.$route.name === 'DollarTenor' ||  this.$route.name === 'DollarInvestmentSummary'; 
        },

        
        vestLockRoute() {

            return this.$route.name === 'vestHome' || this.$route.name === 'vestAmount'|| this.$route.name === 'initSum' || this.$route.name === 'fullSum' || this.$route.name === 'finalView' || this.$route.name === 'vestPort';
        },
        BookingRoute() {

            return this.$route.name === 'bookHomeTwo' || this.$route.name === 'bookFunds'|| this.$route.name === 'bookSuccess' || this.$route.name === 'bookUnSuccess' || this.$route.name === 'bookHistory'|| this.$route.name === 'bookExplainer' 
        },
    },

    mounted() {

        this.tran = trans
        window.addEventListener('mouseup', function (event) {

            let vm = this
            let sidebar = document.getElementById('accordionSidebar');
            let closeSideBar = document.getElementById('sidebarToggleTop2');
            let openSideBar = document.getElementById('sidebarToggleTop');
            let windowWidthSize = document.documentElement.clientWidth
            let li_dropdown = document.getElementById('li_dropdown');
            if (event.target != sidebar && event.target.parentNode != sidebar && windowWidthSize < 991 && event.target !== li_dropdown && sidebar !== null) {


                    sidebar.classList.remove("show-elem");

                     openSideBar.style.display = 'block'
                closeSideBar.classList.add("hide-elem");
                openSideBar.classList.add("displ-menu-btn");
            }

            if (event.target == closeSideBar && event.target !== li_dropdown) {

                sidebar.classList.remove("show-elem");
                closeSideBar.classList.add("hide-elem");
                openSideBar.style.display = 'block'
            }

            // if (event.srcElement.classList[4] == "closeBtn") {
            //      sidebar.classList.remove("show-elem");
            // }

        });

        window.addEventListener('click', function (event) {
            let sidebar = document.getElementById('accordionSidebar');

            let openSideBar = document.getElementById('sidebarToggleTop');
            let faBars = document.getElementById('faBars');
            let closeSideBar = document.getElementById('sidebarToggleTop2');
            let dropDownList = document.getElementById('help_menu');
            let li_dropdown = document.getElementById('li_dropdown');

            if (event.target == openSideBar || event.target == faBars || event.target == li_dropdown) {

                sidebar.classList.add("show-elem");
                closeSideBar.classList.remove("hide-elem");
                openSideBar.classList.remove("displ-menu-btn");
                openSideBar.style.display = 'none'

            }
        });

        window.addEventListener('click', function (event) {
            let li_dropdown = document.getElementsByClassName("li_dropdown");

            let sidebar = document.getElementById('accordionSidebar');

            for (let i = 0; i < li_dropdown.length; i++) {
                li_dropdown[i].addEventListener('click', () => {

                    sidebar.classList.add("show-elem");
                });
            }

            if (event.target == li_dropdown) {

                sidebar.classList.add("show-elem");

            }

        })

        if (this.$route.path == '/faq' || this.$route.path == '/terms' || this.$route.path == '/privacy' || this.$route.path == '/contact' || this.$route.path == '/about' || this.$route.path == '/vest/explainer') {

            this.$store.commit("domElements/activateDropDownStatus");
        } else {

            this.$store.commit("domElements/deactivateDropDownStatus");

        }

        (function () {

            var DRIFT_CHAT_SELECTOR = '.drift-open-chat'
            /* http://youmightnotneedjquery.com/#ready */
            function ready(fn) {
                if (document.readyState != 'loading') {
                    fn();
                } else if (document.addEventListener) {
                    document.addEventListener('DOMContentLoaded', fn);
                } else {
                    document.attachEvent('onreadystatechange', function () {
                        if (document.readyState != 'loading')
                            fn();
                    });
                }
            }
            /* http://youmightnotneedjquery.com/#each */
            function forEachElement(selector, fn) {
                var elements = document.querySelectorAll(selector);
                for (var i = 0; i < elements.length; i++)
                    fn(elements[i], i);
            }

            function openSidebar(driftApi, event) {
                event.preventDefault();
                driftApi.sidebar.open();
                return false;
            }
            ready(function () {
                drift.on('ready', function (api) {
                    var handleClick = openSidebar.bind(this, api)
                    forEachElement(DRIFT_CHAT_SELECTOR, function (el) {
                        el.addEventListener('click', handleClick);
                    });
api.widget.hide();
                });
            });
        })();
    }
}
</script>

<style>

</style>
