<template>
<div>

    <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 shadow-r static-top">

        <!-- Sidebar Toggle (Topbar) -->
        <button id="sidebarToggleTop" class="btn btn-link displ-menu-btn rounded-circle" style="position: relative; right: 15px;">
            <i class="fa fa-bars" id="faBars"></i>
        </button>

        <button id="sidebarToggleTop2" class="btn btn-link rounded-circle hide-elem" style="position: relative;
    right: 15px;">
            <i class="fa fa-bars closeS" ></i>
        </button>

        <a class="sidebar-brand d-flex justify-content-center" v-if="windowWidth > 991" style="padding-left:0px !important">
            <div class="sidebar-brand-icon">
                <a href="https://requid.com" target="_blank">
              <img src="../../assets/images/logo.png" loading="lazy" class="nav-img">
                
                </a>
            </div>
          </a>
        <a class="sidebar-brand d-flex align-items-center pg-title">
            <div class="">
              <h5 class="text-left head-font">{{heading}}</h5>
              <p class="nav-subheading">{{subheading}}</p>
            </div>
            <!-- <div class="sidebar-brand-text mx-3"><b>VESTFORT</b></div> -->
        </a>

        <!-- <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3" @click.prevent="toggleMenu" v-if="toggleMenu2">
                    <i class="fa fa-bars"></i>
        </button> -->

        <ul class="navbar-nav ml-auto" v-if="token == null">
            <form class="vertical-align-center">
                <router-link to="/signup" class="auth-nav-btn" >
                    <button class="theme-btn guest-auth-btn"  style="display:flex; background:#E4FAE1; color:#58B46F">
                       <span style="flex-grow:1">
                       {{tran.signup}}

                       </span>
                    </button>
                </router-link>
                <router-link to="/login" class="auth-nav-btn">
                    <button class="theme-btn guest-auth-btn"  style="display:flex; background:#E4FAE1; color:#58B46F">

                       <span style="flex-grow:1">
                       {{tran.login}}

                       </span>
                </button>
                </router-link>
            </form>
        </ul>

        <!-- Topbar Navbar -->
        <ul class="navbar-nav" :class="{'ml-auto': token !== null}">
            <!-- 
                <form class="vertical-align mt-12" v-if="windowWidth > 769 && searchObjects.products">
                <input type="text" class="form-input"  @keyup="sendToSearchPage($event)" placeholder="Search products...." aria-label="Search" aria-describedby="basic-addon2">
                <span class="relat-abs-ele">
                    <i class="fa fa-search"></i>
                </span>
            </form>

            <form class="vertical-align mt-12" v-if="windowWidth > 769 && searchObjects.cart">
                <input type="text" class="form-input"  @keyup="searchCart($event)" placeholder="Search products...." aria-label="Search" aria-describedby="basic-addon2">
                <span class="relat-abs-ele">
                    <i class="fa fa-search"></i>
                </span>
            </form>

            <form class="vertical-align mt-12" v-if="windowWidth > 769 && searchObjects.portfolio">
                <input type="text" class="form-input"  @keyup="searchPortfolio($event)" placeholder="Search products...." aria-label="Search" aria-describedby="basic-addon2">
                <span class="relat-abs-ele">
                    <i class="fa fa-search"></i>
                </span>
            </form>

            <form class="vertical-align mt-12" v-if="windowWidth > 769 && searchObjects.wallet">
                <input type="text" class="form-input"  @keyup="searchWallet($event)" placeholder="Search products...." aria-label="Search" aria-describedby="basic-addon2">
                <span class="relat-abs-ele">
                    <i class="fa fa-search"></i>
                </span>
            </form> -->

            <form class="vertical-align-center"  v-if="token !== null">
                <router-link to="/cart">
                    <button class="theme-btn ml-4" @click="emptySearchString($event)" v-if="windowWidth > 769" style="display:flex; background:#F5F5F5; color:#000; justify-content:space-evenly; display:flex; border:1px solid #F5F5F5">


                <span>
                       {{tran.proceed_to_cart}}

                       </span>

                <span class="animated fadeIn">
                      

                   
                     <img src="../../assets/images/cart1.png" loading="lazy" height="18">
                      <span class="cart-count mr-2" :class="{'animated zoomInUp' : productAddedToCart}" v-if="cartItemLength > 0 && cartItemLength !== undefined">{{cartItemLength}}</span>
                        </span>

                </button>
                </router-link>
            </form>

            <!-- Nav Item - Search Dropdown (Visible Only XS) -->
            <!-- <li class="nav-item dropdown no-arrow " :class="{'d-sm-none':windowWidth > 769}">
                <a class="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-search fa-fw"></i>
                 </a> -->
            <!-- Dropdown - Messages -->
            <!-- <div class="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
                    <form class="form-inline mr-auto w-100 navbar-search">
                        <div class="input-group">
                            <input type="text" class="form-input bg-light border-0 small" placeholder="Search for..." @keyup="sendToSearchPage($event)" aria-label="Search" aria-describedby="basic-addon2" autofocus>

                        </div>
                    </form>
                </div>
            </li> -->

            <div class="topbar-divider d-none d-sm-block"></div>
            <!-- Nav Item - Alerts -->
            <!-- <li class="nav-item dropdown no-arrow mx-1">
                  <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> -->
                <!-- <i class="fa fa-bell fa-fw"></i>  -->
            <!-- Counter - Alerts -->
         <!-- <span class="badge badge-danger"> New </span>
              </a>  -->
            <!-- Dropdown - Alerts -->
             <!-- <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">

                    <h2 class="dropdown-header" style="font-size:18px;">
                        New Features
                    </h2>



<div class="feature-div" >
<div>
    <span class="font-weight-bold">Transfer Funds </span>
    <span class="badge badge-success badge-counter"> New </span>

</div>
<div>

       <img src="../../assets/images/trf.png" height="28">
</div>
</div>
<div class="feature-div" >
<div>
             
                    <span class="font-weight-bold">Transfer Investments </span>
    <span class="badge badge-success badge-counter"> New </span>

</div>
<div>

       <img src="../../assets/images/transferbtn.png" height="18">
</div>
</div>
        <div class="feature-div" >
<div>
             
                    <span class="font-weight-bold">Part Liquidation </span>
    <span class="badge badge-success badge-counter"> New </span>

</div>
<div>

       <img src="../../assets/images/sellbtn.png" height="30">
</div>
</div>           -->
                    <!-- <a class="dropdown-item d-flex align-items-center" href="#">
                  <div class="mr-3">
                    <div class="icon-circle bg-success">
                      <i class="fa fa-donate text-white"></i>
                    </div>
                  </div>
                  <div>
                    <div class="small text-gray-500">December 7, 2019</div>
                    $290.29 has been deposited into your account!
                  </div>
                </a>
                    <a class="dropdown-item d-flex align-items-center" href="#">
                  <div class="mr-3">
                    <div class="icon-circle bg-warning">
                      <i class="fa fa-exclamation-triangle text-white"></i>
                    </div>
                  </div>
                  <div>
                    <div class="small text-gray-500">December 2, 2019</div>
                    Spending Alert: We've noticed unusually high spending for your account.
                  </div>
                </a> -->
                    <!-- <a class="dropdown-item text-center small text-gray-500" href="#">Show All Features</a>
                </div>
            </li> -->

            <li class="nav-item dropdown no-arrow mx-1" v-if="windowWidth < 769 && token !== null" >
                <a class="nav-link dropdown-toggle" @click.prevent="sendTocart">
                    <span class="animated fadeIn">
                     <img src="../../assets/images/cart1.png" loading="lazy" height="18" @click.prevent="sendTocart">
                       <span class="cart-count" v-if="cartItemLength > 0 && cartItemLength !== undefined" :class="{'animated zoomInDown' : productAddedToCart}" >{{cartItemLength}}</span>
                    </span>
                 </a>
            </li>

            <!-- Nav Item - User Information -->
            <li class="nav-item dropdown no-arrow">
                <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <!-- <span class="badge badge-danger mr-2" style="background: #F1FAF0; font-weight:300; color:#58B46F;padding: 1em 1.4em;"> New </span> -->
                <span class="mr-2 d-none d-lg-inline text-gray-600 small" v-if="token !== null" >{{userProfileInfo.first_name}} {{userProfileInfo.last_name}}</span>
                <img class="img-profile rounded-circle" loading="lazy" src="../../assets/images/dummy.png" v-if=" token !== null" >
              </a>
                      <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown" id="userNotyDiv">
                <!-- <a class="dropdown-item"  @click.prevent="sendToProfile" style="background-color:#FAFAFA; min-height:40px; display:flex; align-items:center; border-bottom: 1px solid #e3e6f0;">
                  <i class="fa fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                 View {{tran.profile}}  
                </a> -->



<div class="feature-div" @click.prevent="sendToProfile" style="background-color:#FAFAFA; min-height:40px; display:flex; align-items:center"  v-if=" token !== null">
<div >
    <span class="" >  View {{tran.profile}}  </span>
   
</div>
<div>
       <!-- <img class="img-profile rounded-circle" height="20" src="../../assets/images/dummy.png"> -->
     <i class="fa fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
</div>
</div>


<div class="feature-div" @click.prevent="logout" style="background-color:#FAFAFA; min-height:40px; display:flex; align-items:center"  v-if=" token !== null">
<div @click.prevent="logout">
    <span class="" style="color:#828282"> {{tran.logout}}</span>
   
</div>
<div>
   <i class="fa fa-sign-out fa-sm fa-fw mr-2 text-gray-400"></i>    
</div>
</div>
                    
                    <!-- <a class="dropdown-item" @click.prevent="logout" >
                        <i class="fa fa-sign-out fa-sm fa-fw mr-2 text-gray-400"></i>
                        {{tran.logout}}
                     </a> -->


                    
<!-- <div class="feature-div" style="background-color:#fff">
<div>
  <span class="font-weight-bold" style="color:#828282; font-family:circular">New Features</span>

</div>
<div style="display:flex; justify-content:center; flex-basis:17%">

    
</div>
</div> -->


<!-- <div class="feature-div" @click="gotoWallet">
<div >
    <span class="font-weight-bold">Transfer Funds </span>
    <span class="badge badge-success badge-counter"> New </span>

</div>
<div>

       <img src="../../assets/images/transferbtn.png" loading="lazy" height="18">
</div>
</div> -->
<!-- <div class="feature-div" @click="gotoPortfolio">
<div>
             
                    <span class="font-weight-bold">Transfer Investments </span>
                    <span class="badge badge-success badge-counter"> New </span>

</div>
<div >

       <img src="../../assets/images/transferbtn.png" loading="lazy" height="18">
</div>
</div> -->
        <!-- <div class="feature-div" @click="gotoPortfolioView">
                    <div>
             
                    <span class="font-weight-bold">Part Liquidation </span>
                    <span class="badge badge-success badge-counter"> New </span>

                </div>
            <div>

       <img src="../../assets/images/sellbtn.png" loading="lazy" height="30">
</div>
</div>           -->
                    <!-- <a class="dropdown-item d-flex align-items-center" href="#">
                  <div class="mr-3">
                    <div class="icon-circle bg-success">
                      <i class="fa fa-donate text-white"></i>
                    </div>
                  </div>
                  <div>
                    <div class="small text-gray-500">December 7, 2019</div>
                    $290.29 has been deposited into your account!
                  </div>
                </a>
                    <a class="dropdown-item d-flex align-items-center" href="#">
                  <div class="mr-3">
                    <div class="icon-circle bg-warning">
                      <i class="fa fa-exclamation-triangle text-white"></i>
                    </div>
                  </div>
                  <div>
                    <div class="small text-gray-500">December 2, 2019</div>
                    Spending Alert: We've noticed unusually high spending for your account.
                  </div>
                </a> -->
                    <!-- <a class="dropdown-item text-center small text-gray-500" href="#">Show All Features</a> -->
                </div>
                <!-- Dropdown - User Information -->
                <!-- <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                    <a class="dropdown-item"  @click.prevent="sendToProfile">
                  <i class="fa fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                  {{tran.profile}}
                </a> -->
                    <!-- <a class="dropdown-item" href="#">
                  <i class="fa fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                  Settings
                </a> -->

                    <!-- <div class="dropdown-divider"></div>
                    <a class="dropdown-item" @click.prevent="logout">
                        <i class="fa fa-sign-out fa-sm fa-fw mr-2 text-gray-400"></i>
                        {{tran.logout}}
                     </a>
                </div> -->
            </li>
        </ul>
    </nav>

    <!-- <div  class="notification-bar" v-show="token && showNotification && userProfileInfo.has_testimonial == false && portf.length > 0">
        <div style="min-height:30px; position: fixed; min-width:100%; display:flex; align-items:center; justify-content:center; color:#fff;max-width:100%;padding: 4px;">
            <p class="pr-4" data-toggle="modal" data-target="#exampleModal"> Hi {{ userProfileInfo.first_name }} {{ userProfileInfo.last_name }} tell us about your experience on reQuid </p>
            <img src="../../assets/images/close-icon.png" style="position: absolute;
    right: 13px" height="18" alt="" @click.prevent="removeNotification" >
        </div>
    </div> -->




    <!-- Modal -->
    <div class="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" v-if="showModal">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content" >
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"> </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                            <span aria-hidden="true">&times;</span>
                    </button>
                  
                </div>
         
                
              
                <div class="modal-body">

                 
                  
                  <div v-show="fillTestimony">

             
                    <h4 class="head-font  text-center mb-4">{{tran.rate_us}}</h4>

                    <p class="text-center">{{tran.let_us_know}}</p>

                    <div class="text-center pt-4" style="display:flex; justify-content:center; flex-direction:column">

                        <center>
                            <div style="width:40%; border-top: 1px solid #FDC23C;">
                            </div>
                        </center>
                        <p class="pt-2"><b>{{tran.rate_your_expe}}</b></p>
                    </div>

                    <div class="text-center rate-div">
                        <fieldset class="rating text-center">
                            <input type="radio" id="star5" name="rating" value="5" />
                            <label class = "full" for="star5" title="Awesome - 5 stars" @click="highStarMethod(5)"></label>

                            <input type="radio" id="star4" name="rating" value="4" />
                            <label class = "full" for="star4" @click="highStarMethod(4)"></label>

                            <input type="radio" id="star3" name="rating" value="3" /><label class = "full" for="star3" @click="highStarMethod(3)"></label>

                            <input type="radio" id="star2" name="rating" value="2" />
                            <label class="full" for="star2" @click="lowStarMethod(2)"></label>

                            <input type="radio" id="star1" name="rating" value="1" /><label class = "full" for="star1"  @click="lowStarMethod(1)" ></label>
                        </fieldset>
                    </div>

                    <div class="text-center pt-1" style="display:flex; justify-content:center; flex-direction:column">

                        <center>
                            <div style="width:60%; border-top: 1px solid #FDC23C;"></div>
                            <div style="width:20%; border-top: 1px solid #FDC23C;" class="mt-3"></div>
                        </center>

                    </div>

                    <div class="mt-3 mb-3">
                        <p class="text-center animated fadeIn" v-if="highStar"> <b>Nice!</b> {{tran.nice_do_you}} </p>

                        <p class="text-center animated fadeIn" v-if="lowStar"> <b>Ouch!</b> {{tran.ouch_please}} </p>
                    </div>

                    <div class="mt-1">
                        <textarea name="" id="" cols="30" v-model="testimonialData.testimony" rows="4" style="width:100%; border-radius:5px !important" minlength="3" maxlength="250" placeholder="Type here"></textarea>
                    </div>
                                 <div class="com-pub mt-2">
                                  <div class="custom-control text-center custom-checkbox my-1 mt-4 mr-sm-2">
                                                                    <input type="checkbox" class="custom-control-input"  v-model ="testimonialData.share" id="customControlInline" >
                                                                    <label class="custom-control-label" title="Make my comments public" for="customControlInline"><b>Make my comments public</b></label>

                                                                </div>

                                               <p class="text-center pt-2">Your testimonial helps inform others to invest healthy.</p>
                                     </div>

                    <p class="drift-open-chat text-center pt-3" style="text-decoration:underline; cursor:pointer"> {{ tran.contact_sup }} </p>
                         </div>


  <template v-if="!fillTestimony">
   <img src="../../assets/images/back.png" alt="" loading="lazy" height="30" style="cursor:pointer;cursor: pointer;
    position: absolute;
    top: -38px;" @click="goBackToTestimonial">
        <h6 class="text-center pt-2 pb-2"> <b> Share your experience on: </b> </h6>

                            <center>
                                <div style="width:40%; border-top: 1px solid #FDC23C;" class="mb-3">
                                </div>
                            </center>
                             <div class="position-el" style="font-size:25px">
                                        <social-sharing :url="`https://market.requid.com/signup?ref=${userProfileInfo.ref_code}`" :description="testimonialData.testimony" :title="testimonialData.testimony" :quote="testimonialData.testimony" hashtag="#requid, #invest" twitter-user="requidapp" inline-template>

                                                <div>
                                                    <network network="twitter">
                                                        <img src="../../assets/images/twitter.png" alt="" loading="lazy" title="share via twitter" v-tippy='{ arrow : true, placement:"top",arrowType : "round"}' style="margin-right: 40px !important;" height="38">
                                                        <!-- <i class="fa fa-twitter"  ></i> -->
                                                    </network>

                                                    <!-- <network network="linkedin">
                                                        <i class="fa fa-linkedin" title="share via linkedin" v-tippy='{ arrow : true, placement:"top",arrowType : "round"}' style="margin-right: 20px !important;
                                                            padding-left: 10px;
                                                            padding-right: 10px;
                                                            border-radius: 5px;"></i>
                                                    </network> -->


                                                    <network network="facebook">
                                                            <img src="../../assets/images/face.png" alt="" loading="lazy" title="share via facebook" v-tippy='{ arrow : true, placement:"top",arrowType : "round"}' style="margin-right: 40px !important;" height="38">
                                                    </network>



                                                    <network network="whatsapp">
                                              <img src="../../assets/images/what.png" alt="" loading="lazy" title="share via whatsApp" v-tippy='{ arrow : true, placement:"top",arrowType : "round"}' style="margin-right: 40px !important;" height="38">
                                                    </network>
                                                </div>
                                            </social-sharing>
                                        </div>
                                <center>
                                    <div style="width:60%; border-top: 1px solid #FDC23C;" class="mt-3"></div>
                                    <div style="width:20%; border-top: 1px solid #FDC23C;" class="mt-3"></div>
                                </center>
                          </template>
                </div>
        
                <div class="modal-footer" v-show="shareNext" @click="shareOnSocialMedia" :class="{'fade-elem':testimonialData.rating == '' || testimonialData.testimony == '' }">
                    <p> {{ tran.next }} </p>
                </div>

                <div class="modal-footer" @click="submitTestimonial" v-show="submitTestimony" :class="{'fade-elem':testimonialData.rating == '' || testimonialData.testimony == '' }">
                    <p>{{ loader ? '' : tran.submit}}
                    <span class="loader" v-if="loader" style="top:94%"></span>
                    </p>
                </div>

                <div class="modal-footer" @click="closeTestimonial" v-show="!fillTestimony">
                    <p>{{ tran.done}}</p>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import {
    commonJs
} from '../dashboard/mixins/commonJs.js';
import { setTimeout } from 'timers';

export default {

    mixins: [commonJs],
    data() {

        return {
            filter: true,
            openBtn: true,
            closeBtn: false,
            lowStar: false,
            highStar: false,
            showNotification:true,
               testimonialData:{
      testimony:'',
      rating:'',
      share:true,

    },

    fillTestimony:true,
    shareTestimony:false,
    shareNext:false,
    submitTestimony:true,
showModal:true,
userStillTyping:true

        }
    },

    methods: {


gotoWallet(){

       this.$router.push({ path: '/wallet', query: { activateFundButton: true }})
},


gotoPortfolio(){

    this.$router.push('/feature/view')
},
gotoPortfolioView(){

    this.$router.push('/portfolio')
},
        closeTestimonial(){
          $('.modal-backdrop').removeClass("modal-backdrop")
        this.fillTestimony = false
        this.shareNext = false
         this.showModal = false
            this.showNotification = false
            localStorage.setItem("barNotification", this.showNotification)
 setTimeout(()=>{

    this.showModal = true
 }, 2000)
      
        },
        shareOnSocialMedia(){
         this.$store.dispatch('auth/submitTestimonial', this.testimonialData)

        this.fillTestimony = false
        this.shareNext = false
        },
        
        submitTestimonial() {

            this.$store.dispatch('auth/submitTestimonial', this.testimonialData)
                $('.modal-backdrop').removeClass("modal-backdrop")
                this.showModal = false
                            this.showNotification = false
                            localStorage.setItem("barNotification", this.showNotification)
                setTimeout(()=>{

                    this.showModal = true
                }, 2000)
        },
            goBackToTestimonial(){
                
            this.fillTestimony = true
            this.submitTestimony = true
            },


    removeNotification(){
            this.showNotification = false
                localStorage.setItem("barNotification", this.showNotification)
            },


        lowStarMethod(rating) {
            this.lowStar = true
            this.submitTestimony = true
            this.highStar = false
            this.testimonialData.rating = rating
            this.shareTestimony = false
            this.shareNext = false
        },

        highStarMethod(rating) {
            this.lowStar = false
            this.highStar = true
                this.shareTestimony = true
                this.shareNext = true
                this.submitTestimony = false
            this.testimonialData.rating = rating
        },

        sendToProfile() {
            this.$router.push('/profile')
        },

        sendTocart() {
            this.$router.push('/cart')
        },

        logout() {
            this.$store.dispatch("auth/logoutUser");
            // localStorage.removeItem("userQuery");
        },

        showSideBar() {
            this.$store.commit('domElements/showSideBar')
            this.filter = false;
        },

        hideSideBar() {
            this.$store.commit('domElements/hideSideBar')
            this.filter = true;
        },

        toggleSideBar(event) {
            this.$helpers.log(event)
            return this.filter ? this.showSideBar() : this.hideSideBar()
        },

        getWindowWidth() {
            this.$store.commit('domElements/saveWindowWidth', document.documentElement.clientWidth)
            if (this.windowWidth <= 991) {
                this.$store.commit("domElements/hideSideBar");
            } else {
                this.$store.commit("domElements/showSideBar");
            }
        },

        openSideB() {
            this.openBtn = false
            this.closeBtn = true
        },

        closeSideB() {
            this.openBtn = true;
            this.closeBtn = false;
        },

    },

    computed: {
        ...mapState({
            sideBarStatus: state => state.domElements.sideBarStatus,
            toggleMenu2: state => state.domElements.toggleMenu2,
            cartItems: state => state.market.cartItems,
            heading: state => state.market.heading.mainHeading,
            subheading: state => state.market.heading.subHeading,
            searchObjects: state => state.market.searchObjects,
            loader: state => state.auth.loader,
            userProfileInfo: state => state.auth.userProfileInfo,
            portf: state => state.market.portfolioTransactions,
            productAddedToCart: state => state.domElements.productAddedToCart,
        }),

        returnOpenBtn() {
            return this.openBtn
        },

        returnCloseBtn() {
            return this.closeBtn
        },
    },

    mounted() {
        if (this.token !== null) {
            this.$store.dispatch('market/getCartItems');
        }

            this.$nextTick(function () {
            window.addEventListener('resize', this.getWindowWidth);

            //Init
            this.getWindowWidth()
        })

let notificationStatus  = localStorage.getItem("barNotification")

// if(notificationStatus !== null){

//     this.showNotification = false
// }
    },

    beforeMount() {
        if (this.token !== null) {
     this.$store.dispatch('auth/getUserProfile');

        }
    }
}
</script>

<style lang="scss" scoped>


.feature-div{
    display:flex; 
    justify-content:space-between;
    white-space: normal;
    padding-top: .5rem;
    padding-bottom: .5rem;
    min-height: 40px;
    align-items: center;
    border-left: 1px solid #e3e6f0;
    border-right: 1px solid #e3e6f0;
    line-height: 1.3rem;
    border:1px solid #fff;
    border-bottom: 1px solid #e3e6f0;
    padding: .25rem 1.5rem;
    cursor: pointer;
       
}

.feature-div:hover{
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;

        }


.topbar .dropdown-list .dropdown-header {

background-color:#fff;
color:#828282;
border:1px solid #fff;
}

#userNotyDiv{

    padding: 0rem 0;
    min-width:300px;
}

.badge{
        padding: 0.45em .4em;
}

.pg-title{
padding-left: 29px;
@media (max-width: 767px) {
padding-left: 5px !important;
}
}
</style>
