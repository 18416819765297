import 'babel-polyfill';
import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import SmartTable from 'vuejs-smart-table'
import store from "./store/index";
import 'font-awesome/css/font-awesome.css';
// import 'animate.css/animate.min.css';
import "bootstrap/dist/css/bootstrap.min.css";
import Popper from 'vue-popper-component';
// import 'vue-popperjs/dist/vue-popper.css';
import _ from 'lodash';    
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import 'cxlt-vue2-toastr/dist/css/cxlt-vue2-toastr.css'
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/themes/google.css";
import "tippy.js/themes/translucent.css";

window.$ = window.jQuery = require('jquery')
require('bootstrap/js/dist/modal');
import $ from 'jquery'

import searchBar from '@/components/partials/SearchField'

import searchProduct from '@/components/dashboard/SearchProduct'

import vueNumeralFilterInstaller from 'vue-numeral-filter';
import VueConfetti from 'vue-confetti'
import CxltToastr from 'cxlt-vue2-toastr'
import vueSocialSharing from 'vue-social-sharing'
import VueApexCharts from 'vue-apexcharts'

import VueTippy, { TippyComponent } from "vue-tippy";
import 'vue-popperjs/dist/vue-popper.css';
Vue.component('popper', Popper)
Vue.component('searchBar', searchBar)
Vue.component('searchProduct', searchProduct)
Vue.component("tippy", TippyComponent);
Vue.component("social-sharing", vueSocialSharing);
Vue.use(VueConfetti)
Vue.component('apexchart', VueApexCharts)
Vue.use(vueNumeralFilterInstaller)
Vue.use(SmartTable)
Vue.use(VueTippy);
Vue.use(vueSocialSharing);

Vue.use(CxltToastr, toastrConfigs)

Vue.config.productionTip = false;
Vue.config._debug = true;




const toastrConfigs = {
  position: 'bottom right',

}



const helpers = {
  log: function (text, tag) {
    process.env.NODE_ENV == 'development' && console.log(text, tag);
  }
};

export default helpers;

const plugin = {
  install() {
    Vue.helpers = helpers;
    Vue.prototype.$helpers = helpers;
  } 
};

Vue.use(plugin);

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
      window.event = function (event) {
          if (!(el == event.target || el.contains(event.target))) {
              vnode.context[binding.expression](event);
          }
      };
      document.body.addEventListener('click', window.event)
  },
  unbind: function (el) {
      document.body.removeEventListener('click', window.event)
  },
});
Vue.directive("select", {

  inserted: function(el) {
  
    $(el)
      .select2({
        //matcher: modelMatcher,
        //minimumResultsForSearch: 6,
        dropdownAutoWidth: true,
        width: "auto"
      })
      .on("change", function(e) {
        if (e.detail === "vue-directive") {
          return; // prevent stack overflow (i.e. listening to the event we threw ourselves)
        }
        // throw regular change (non jQuery) event, so vue reacts
        el.dispatchEvent(
          new CustomEvent("change", {
            detail: "vue-directive"
          })
        );
        return false;
      });
  }
});

 Vue.mixin({
    beforeDestroy() {
    // store.commit('market/emptySearchString')
    }
  });

Vue.directive("focus", {
  inserted: function(el) {
    el.focus();
  }
});


new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
