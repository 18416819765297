<template>
<div class="display-div sticky-div mb-4 pb-2">

    <div class="vertical-align-bottom ">

        <h5 v-if="searchString == ''"><b>{{tran.marketPlace}}</b></h5>
        <h5 v-if="searchString !== ''">{{tran.searching_for}} {{searchString}}</h5>
    </div>
    <div class="pr-4 search-input text-right">

        <input type="text" id="search" placeholder="search"  class="form-input" >

        <span class="relat-abs-ele">
                         <i class="fa fa-search"></i>

                        </span>
        <button class="theme-btn ml-4">{{tran.proceed_to_cart}}
                                <i class="fa fa-shopping-cart"></i>

                        </button>
    </div>
</div>
</template>

<script>
import {
    commonJs
} from '../dashboard/mixins/commonJs.js';
export default {
    mixins: [commonJs],

}
</script>

<style  lang="scss">

</style>
