<template>
<div>

    <!-- Product Detail component  -->
    <div class="mobile-product-detail pt-2 animated fadeIn" v-if="windowWidth < 501">
        <div class="container-fluid" style="overflow-y:scroll">
            <div style="margin-bottom:230px;"> 

                <div style="background-color:#fff; border-radius:20px;padding:10px"  >

                <h4 class="mb-2 head-font mt-1" style="color:#828282">

                    <img src="../../assets/images/chev.png" loading="lazy" alt="" height="18" @click="hideProductDetail" class="pr-4 pb-1">
                    {{productObject.name}}</h4>
                <div class="product-img-div" :style="{ backgroundImage: 'url(' + serVerImage+productObject.product_image + ')' }">

                   
                </div>
                <div class="tran-height-mobile"  :class="{'see-more-active':seeMoreData}">

                      
                         <div v-html="sliceText"></div>
                </div>

                        <button style="min-width:100%; background:#E6F4FF;border:1px solid #E6F4FF; border-radius:15px" @click.prevent="seeMoreDetails">
 <span  style="color:#828282"  v-if="!seeMoreData"> see more </span>

                        <span style="color:#828282" v-if="seeMoreData"> hide </span>
                      
<i class="fa fa-chevron-down" :class="{'rotate-icon':seeMoreData}"></i>
                    
                        </button>


                       

                </div>
<div style="background-color:#fff; border-radius:20px;padding:10px" class="mt-2">
          <p style="font-size:15px" class="mb-2">

                        <img src="../../assets/images/ui1.png" loading="lazy" height="20" class="mr-3"> <b> <span class="user-roi" v-if="productObject.units_available < 1 || productObject.resell_units_available < 1"> {{formatNumber(productObject.roi_opened_with)}} % </span> <span class="user-roi" v-else> {{formatNumber(productObject.roi)}}% </span></b> {{tran.interest}}

                        </p>
                  <p style="font-size:15px" class="mb-2">
                            <img src="../../assets/images/ui2.png" loading="lazy" height="20" class="mr-3"> <span v-if="productObject.resell_units_available < 1 || productObject.units_available < 1">{{productObject.product_opened_with}}</span>
                        <span v-else>
                            
                            
                             <span v-if="productObject.numOfDays <=13">
                                                            {{productObject.numOfDays + ' days'}}
                                                        </span>
                                                         <span v-if="productObject.numOfDays >=  14 && productObject.numOfDays <=  29">
                                                            {{floorNumb(productObject.numOfDays) +  ' weeks'}}
                                                        </span>
                                                          <span v-if=" productObject.numOfDays >  29">
                                                            {{productObject.maturityInMonths}}
                                                        </span>
                           
                            </span>
                  </p>
                     <p style="font-size:15px" class="mb-2">
                   
                          <img src="../../assets/images/ui3.png" loading="lazy" height="20" class="mr-3"> <span>&#8358;</span>{{formatNumber(productObject.cost_per_unit)}} {{tran.per_unit}}

                          </p>
                  
                  <p style="font-size:15px" class="mb-2">


                    <img src="../../assets/images/ui3.png" loading="lazy" height="20" class="mr-3"> <span v-if="productObject.units_available !== undefined"> {{formatNumber(productObject.units_available)}} </span><span v-else>   {{ formatNumber(productObject.resell_units_available)}} </span>
                        <span v-if="productObject.units_available > 1 || productObject.resell_units_available > 1">{{tran.units_available}}</span>
                        <span v-else>{{tran.unit_available}}</span>
                  </p>
                  <p style="font-size:15px" class="mb-2" v-if="isComingSoon">


                      <img src="../../assets/images/ui4.png" loading="lazy" height="20" class="mr-3"> <span>Opening : <b>{{productObject.opens_at | formatDate}}</b></span>
                  </p>
                  <p style="font-size:15px" class="mb-2" v-else>


                      <img src="../../assets/images/ui4.png" loading="lazy" height="20" class="mr-3"> <span>Opened : <b>{{productObject.opens_at | formatDate}}</b></span>
                  </p>
                  <p style="font-size:15px" class="mb-2">


                      <img src="../../assets/images/ui4.png" loading="lazy" height="20" class="mr-3"> <span>{{tran.martures_on}}: <b>{{productObject.matures_at | formatDate}}</b></span>
                  </p>

                
                        <template v-if="productObject.settlement_status == 'settled'&&  productObject.name !== 'Rice Farm 02'">
                            <p class="mt-2 mb-2">
                                <span class="paid-out-mob"> PAID OUT </span>
                            </p>

                        </template>
     <template v-if="productObject.units_available < 1 && productObject.name === 'Rice Farm 02'">
                                        <span class="phased-out-mob">PHASED</span>

                                    </template>
                        <template v-if="productObject.units_available < 1  && productObject.settlement_status !== 'settled' &&  productObject.name !== 'Rice Farm 02' || productObject.resell_units_available < 1 && productObject.settlement_status !== 'settled' &&  productObject.name !== 'Rice Farm 02'">
                            <p class="mt-2 mb-2">
                                <span class="sold-out-mob">SOLD OUT</span>
                            </p>
                        </template>

                <div style="display:flex" class="mt-2">

                 <div style="min-height:30px; background:#F5F5F5; border-radius:20px; display:flex; align-items:center;flex-basis:50%; justify-content:space-between; padding:0px 10px" class="mr-2 point-cursor" @click="sendUserToAtpDetailView(productObject.atp_id)">
                                                      <img :src="imgUrlFromServer+productObject.atp_image" loading="lazy" height="20">


                                                        <p style="color:#4F4F4F">{{productObject.atp}}</p>
 <img src="../../assets/images/info.png" loading="lazy" height="20">
                                                    </div>
                                                    <div style="min-height:30px; background:#F5F5F5; border-radius:20px; display:flex; flex-basis:50%; align-items:center;  justify-content:space-between; padding:0px 10px" >
 <!-- <img :src="imgUrlFromServer+prod.insurer_image" loading="lazy" height="20"> -->


                                                        <p style="color:#4F4F4F">{{productObject.insurer_name}}</p>
 <img src="../../assets/images/info.png" loading="lazy" height="20">
                                                    </div>
                </div>

                <hr>
                        <button style="min-width:100%; background:#E6F4FF;border:1px solid #E6F4FF; border-radius:15px">
   <input class="prod-url" type="hidden" :value="productUrl+'/market/'+`${productObject.category}`+'/'+`${productObject.urlName}`+'/'+`${productObject.id}`">
                <div style="display:flex; min-height:40px; justify-content:center; color:#0094FF; align-items:center" @click="copyProductUrl($event)" :content="tran.copied_to_clipboard" v-tippy="{trigger : 'click', hideOnClick : true, sticky : false}">
             
                        <p class="pr-3"> Share product</p>
              <i class="fa fa-share-alt"></i>
                </div>
                        </button>
</div>

                <!-- <p class="mt-2 mb-2" style="color:#4F4F4F;"><b>{{tran.insured_by}} {{productObject.insurer_name}}</b></p>

                <b> <span class="user-roi" v-if="productObject.units_available < 1 || productObject.resell_units_available < 1"> {{formatAmount(productObject.roi_opened_with)}} % </span> <span class="user-roi" v-else> {{formatAmount(productObject.roi)}}% </span> - 

                       <span v-if="productObject.resell_units_available < 1 || productObject.units_available < 1">{{productObject.product_opened_with}}</span>
                                                    <span v-else>{{productObject.matures_at}}</span>

                    </b>
                <h5 class="pt-2" v-if="productObject.category !== 'upcoming'">
                    <p class="pb-2"><b>{{tran.open}} </b>
                        {{productObject.opens_at | formatDate}}
                    </p>
                    <span><b> {{tran.martures_on}} : </b>
                    {{productObject.marturity_date | formatDate}} </span>
                </h5>

                <p class="pt-2"><b>{{tran.cost_per_unit}}</b> : <span>&#8358;</span>{{formatNumber(productObject.cost_per_unit)}}</p>
                <template v-if="productObject.settlement_status == 'settled'">
                    <p class="mt-2 mb-2">
                        <span class="paid-out-mob"> PAID OUT </span>
                    </p>

                </template>

                <template v-if="productObject.units_available < 1 && productObject.settlement_status !== 'settled' || productObject.resell_units_available < 1 && productObject.settlement_status !== 'settled'">
                    <p class="mt-2 mb-2">
                        <span class="sold-out-mob">SOLD OUT</span>
                    </p>
                </template>

                <h5 class="theme-text-color mt-2" v-else>

                    <span v-if="productObject.units_available !== undefined"> {{formatNumber(productObject.units_available)}} </span><span v-else>   {{ formatNumber(productObject.resell_units_available)}} </span>

                    <span v-if="productObject.units_available > 1 || productObject.resell_units_available > 1">{{tran.units_available}}</span>
                    <span v-else>{{tran.unit_available}}</span>
                </h5> -->

                <!-- <input class="prod-url" type="hidden" :value="productUrl+'/market/'+`${productObject.category}`+'/'+`${productObject.urlName}`+'/'+`${productObject.id}`">
                <div style="display:flex;" class="mb-2" @click="copyProductUrl($event)" :content="tran.copied_to_clipboard" v-tippy="{trigger : 'click', hideOnClick : true, sticky : false}">
                    <div class="mt-1 pr-2 pl-2" style="background: #E4FAE1 !important; height:50px; color:#000; border-radius:3px; display:flex; justify-content:center;cursor: pointer; align-items:center; max-width:155px">
                        <p> {{tran.copy_prod_url}}</p>
                    </div>
                </div>
                <p class="mt-2 mb-2"> {{tran.share_product_link}}</p>

                <social-sharing :url="productUrl+'/market/'+`${productObject.category}`+'/'+`${productObject.urlName}`+'/'+`${productObject.id}`" :description="`Invest In ${productObject.atp}'s ${productObject.name} on reQuid with a ROI of ${productObject.roi} in ${productObject.matures_at}.`" twitter-user="requid" :title="`Invest In ${productObject.atp}'s ${productObject.name} on reQuid with a ROI of ${productObject.roi} in ${productObject.matures_at}.`" :quote="`Invest In ${productObject.atp}'s ${productObject.name} on reQuid with a ROI of ${productObject.roi} in ${productObject.matures_at}.`" inline-template>

                   <div>
                                                    <network network="twitter">
                                                    

                                                          <img src="../../assets/images/twit.png" height="30" loading="lazy" title="share via twitter" class="pr-3" v-tippy='{ arrow : true, placement:"top",arrowType : "round"}' alt="">
                                                    </network>


                                                    <network network="facebook">
                                                    <img src="../../assets/images/face2.png" loading="lazy" class="pr-3" height="30" title="share via facebook" v-tippy='{ arrow : true, placement:"top",arrowType : "round"}' alt="">
                                                      
                                                    </network>

                                                    <network network="whatsapp">
                                                          <img src="../../assets/images/whats.png" loading="lazy" height="30" title="share via WhatsApp" v-tippy='{ arrow : true, placement:"top",arrowType : "round"}' alt="">
                                                    </network>

                                                </div>
                </social-sharing>
            </div> -->
        </div>
        </div>

        <!-- Bottom Bar for adding units to the cart -->
        <nav class="navbar fixed-bottom " :class="{'hide-elem': isComingSoon || productObject.units_available < 1 ||  productObject.resell_units_available < 1 }" style="padding-right:0.9rem !important">


   



            <div class="add-units">
                   <div class="display-div pb-2 pt-1" style=" border-bottom: 1px solid #F2F2F2;">
                                                                <div class="text-left" >
                                                            <p class="pb-1" style="color:#828282
">Cost (Multiples of ₦5,000):</p>
                                                                   <span style="color:#0094FF">&#8358;</span>
                                                                    <span class="user-cost text-center" style="color:#0094FF">   {{formatAmount(productObject.user_cost)}}</span>

                                                                </div>
                                                                <div class="text-left" style="color:#58B46F">
                                                                        <p class="pb-1" style="color:#828282
">ROI (Return on Investment):</p>
                                                                    <span>&#8358;</span>
                                                                    <span class="user-amount text-center">{{formatAmount(productObject.user_roi)}}</span>

                                                                </div>

                                                            </div>
                      <!-- <div class="text-left pt-2" style="flex-basis:60%">
                        <p>
                            {{tran.cost}} :
                            <span>&#8358;</span>
                            <span class="user-cost">
                                {{formatAmount(productObject.user_cost)}}    
                                </span>
                        </p>
                        <p> <span>
                            {{tran.roi }}:
                            </span>

                            <span>&#8358;</span>
                            <span class="user-amount text-center">
                                {{formatNumber(productObject.user_roi)}}
                            </span>
                        </p>
                        <i class="fa fa-calculator" @click="focusOnCalcInput" data-toggle="modal" data-target="#myCalc" style="position: absolute; right: 10px; bottom: 115px;color:#0094FF"></i>

                    </div> -->
            
                        <h5 class="pt-2 text-left"><b>Add Units</b> <span style="color:#828282
">(One unit =  ₦5,000)</span></h5>
                
                <div class="input-roi-div" style="align-items:center">

                    <div style="position:relative; justify-content:flex-end; font-size:18px; flex-basis:60%" class="pr-3 input-cont">
                          
                        <div style="position:relative; background:#F5F5F5; height:40px; border-radius:5px;;border: 0.5px solid #33A9FF;min-width: 100%; display:flex; align-items:center;justify-content:center">
                           
 <i class="fa fa-minus text-center" @click="getUserRoiMobile($event, productObject, 'minus')" style=" color:#828282;flex-basis:20%"></i>

                        
                            <input type="text" class="cart-input text-center" @keyup="checkUnitValueMobile($event, productObject)" id="inputForMobile" :value="productObject.defaultUnitSelected" style="border:1px solid transparent; background:#F5F5F5; flex-basis:60%">
                            <i class="fa fa-plus text-center"  :class="{'fade-elem':addMobileStatus}" @click="getUserRoiMobile($event, productObject, 'add')"
                                style=" color:#0094FF;flex-basis:20%"></i>
                        </div>

                        <!-- <div class="text-center" style="opacity:0">
                            <p v-if="productObject.defaultUnitSelected > 1" class="pt-1 unit-string">
                                {{tran.units}}
                            </p>

                            <p v-else class="unit-string pt-1">
                                {{tran.unit}}
                            </p>
                        </div> -->
                    </div>
                  
<div style="flex-basis:40%">

                <button @click.prevent="addToCart(productObject,$event)" class="cart-btn text-center" :class="{'fade-elem': loader}" v-if="token !== null" style="background:#0094FF; height:40px; border:1px solid #0094FF">   
                    
                    
                    <img loading="lazy" src="../../assets/images/cart4.png"  class="mr-2" alt="" height="18" style="position:absolute; left: 10px" v-if="!loader">
                                           
                                            <span class="loader ml-2" v-if="loader"></span>
                                            <span  style="position:absolute"  @click.prevent="addToCart(productObject,$event)"> {{loader ? '' :  tran.add_tocart}}</span>
                                            <span style="opacity:0;" > loader</span>
                                        </button>
                <button @click="sendGuestTosignUp" class="cart-btn"  style="background:#0094FF;height:40px; border:1px solid #0094FF" v-else>
                                            {{tran.sign_up_to_purchase}}
                                        </button>
</div>
                </div>


            </div>
        </nav>
    </div>
    <div class="modal fade bd-example-modal-lg" style="max-width: 100vw;" id="myCalc" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-full" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"></h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body" style="padding:50px;" id="result">
                    <div class=" mb-4">

                        <h3><b>Do the math</b></h3>
                        <p>See how much units you can purchase on reQuid by entering an amount into the calculator.</p>
                    </div>

                    <p class="pb-1 pt-2" style="font-size:11px">IF YOU INVEST </p>
                    <div style="background:#58B46F; min-height:60px;color:#fff;display:flex;align-items:center; padding:10px; font-size:18px">
                        <span class="pr-2">NGN</span>
                        <input type="text" id="calcInput"  @keypress="onlyNumber"  @keyup="getFundValue($event)" maxlength="9" style="background:transparent;border:transparent; color:#fff" autofocus>
                   </div>
                        <div style="display:flex; justify-content:space-between">

                            <div class="mt-4" style="flex-basis:50%">

                                <p class="pb-1 pt-2" style="font-size:11px">YOU CAN PURCHASE </p>
                                <h4><b><span>{{unitsToBuy}}</span> UNIT(S)</b></h4>

                            </div>
                            <div></div>
                            <div class="mt-4 pl-2" style="flex-basis:50%">

                                <p class="pb-1 pt-2" style="font-size:11px">FOR </p>
                                <h4><b><span class="pr-1">NGN</span><span>{{formatNumber(amountCalc)}}</span></b></h4>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    mapState
} from "vuex";
import http from "../../http/index";
import api from "../../apis/index";
import {
    commonJs
} from '../dashboard/mixins/commonJs.js';

export default {
    mixins: [commonJs],

    data() {
        return {
            cartInput: 0,
            seeMore: false,
            unitsToBuy: 0,
            amountCalc: 0
        }
    },
    methods: {
        hideProductDetail() {
            this.$store.commit("market/hideMobileProductDetailComponent")
            this.$router.push('/market')
        },

        checkUnitValueMobile(event, prod) {

            let unitAdded = parseInt(event.target.value, 10);

            unitAdded = isNaN(unitAdded) ? 0 : unitAdded;
            // if (unitAdded == 1) {
            //     event.target.parentNode.parentNode.querySelector(
            //         ".unit-string"
            //     ).textContent = this.tran.unit;
            // }

            // if (unitAdded > 1) {
            //     event.target.parentNode.parentNode.querySelector(
            //         ".unit-string"
            //     ).textContent = this.tran.units;
            // }

            let totalAmountUserIsBuyingFor = unitAdded * prod.cost_per_unit;

            let atpRate = this.getAtpRate(prod, totalAmountUserIsBuyingFor);

            if (prod.category !== "resell") {
                let rateOfUser = atpRate * 0.01;
                let finalAmount = rateOfUser * totalAmountUserIsBuyingFor;

                event.target.parentNode.parentNode.parentNode.parentNode.querySelector(
                        ".user-amount"
                    ).textContent =
                    (parseFloat(totalAmountUserIsBuyingFor) + parseFloat(finalAmount))
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                event.target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.querySelector(
                    ".user-roi"
                ).textContent = this.formatAmount(atpRate) + "%"

                event.target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.querySelector(
                        ".user-cost"
                    ).textContent =

                    (parseFloat(prod.cost_per_unit) * parseFloat(unitAdded))
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                // if (totalAmountUserIsBuyingFor < prod.vendor_cost_per_unit && this.informUserAboutRateChange) {

                //     this.$toast.error({
                //         title: this.tran.error,
                //         timeOut: 5000,
                //         message: this.tran.you_are_buying_less_than + ' ' + prod.atp + this.tran.current_rate + atpRate + '%',
                //         position: "top right"
                //     });

                //     this.informUserAboutRateChange = false
                // }

                // if (totalAmountUserIsBuyingFor >= prod.vendor_cost_per_unit && this.informUserAboutRateChange == false) {

                //     this.$toast.success({
                //         title: this.tran.success,
                //         timeOut: 5000,
                //         message: this.tran.your_roi_is_now_on_par + ' ' + prod.atp + this.tran.current_rate + atpRate + '%',
                //         position: "top right"
                //     });
                //     this.informUserAboutRateChange = true
                // }

            }

            if (prod.category == "resell") {
                let rateOfUser = prod.roi * 0.01;
                let finalAmount = rateOfUser * totalAmountUserIsBuyingFor;
                event.target.parentNode.parentNode.parentNode.parentNode.querySelector(
                        ".user-amount"
                    ).textContent =

                    (parseFloat(totalAmountUserIsBuyingFor) + parseFloat(finalAmount))
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.$helpers.log("resale1", finalAmount);

                event.target.parentNode.parentNode.parentNode.parentNode.querySelector(
                        ".user-cost"
                    ).textContent =

                    (parseFloat(totalAmountUserIsBuyingFor))
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }

            if (event.target.value > 0) {
                event.target.parentNode.parentNode.parentNode.parentNode
                    .parentNode.querySelector(".fa-minus")
                    .classList.remove("fade-elem");
                event.target.parentNode.parentNode.parentNode.parentNode
                    .parentNode.querySelector(".cart-btn")
                    .classList.remove("fade-elem");
            } else {
                event.target.parentNode.parentNode.parentNode.parentNode
                    .parentNode.querySelector(".cart-btn")
                    .classList.add("fade-elem");
                event.target.parentNode.parentNode.parentNode.parentNode
                    .parentNode.querySelector(".fa-minus")
                    .classList.remove("fade-elem");
                event.target.value = "";
            }

            if (prod.units_available < unitAdded || prod.resell_units_available < unitAdded) {
                event.target.parentNode.parentNode.parentNode.parentNode.querySelector(
                    ".user-amount"
                ).textContent = "";
                event.target.parentNode.parentNode.parentNode.parentNode.querySelector(
                    ".user-cost"
                ).textContent = "";

                event.target.parentNode.parentNode.parentNode.parentNode
                    .querySelector(".fa-minus")
                    .classList.add("fade-elem");

                event.target.parentNode.parentNode.parentNode.parentNode
                    .querySelector(".cart-btn").classList.add("fade-elem");

                event.target.value = event.target.value.substring(
                    0,
                    unitAdded.length - 1
                );

                this.$toast.warn({
                    title: this.tran.sorry,
                    timeOut: 5000,
                    icon: require("../../assets/images/cautionicon.png"),
                    message: this.tran.please_put_a_val + (prod.units_available ? prod.units_available : prod.resell_units_available) + " " + this.tran.unit_s.toLowerCase(),
                    position: "top right"
                });
            }
            if (unitAdded == prod.units_available || unitAdded == prod.resell_units_available) {

                event.target.parentNode.parentNode.parentNode.parentNode.querySelector(".fa-plus")
                    .classList.add("fade-elem");

            } else {
                event.target.parentNode.parentNode.parentNode.parentNode.querySelector(".fa-plus")
                    .classList.remove("fade-elem");

            }
        },

        getAtpRate(prod, totalAmountUserIsBuyingFor) {
            let atpRate = prod.roi;
            let unitOfAtp = prod.vendor_cost_per_unit;
            if (prod.category == "primary") {
                if (totalAmountUserIsBuyingFor < unitOfAtp && prod.unitIsLessThanAtp1Unit == false) {
                    if (prod.roi > 24) {
                        return (atpRate = prod.roi - this.roiSetting.less_atp_unit_gt24);
                    } else if (prod.roi >= 15) {
                        return (atpRate = prod.roi - this.roiSetting.less_atp_unit_gt15);
                    } else if (prod.roi < 15) {
                        return (atpRate = prod.roi - this.roiSetting.less_atp_unit_lt15);
                    }
                }
            }

            return atpRate;
        },

        getUserRoiMobile(event, prod, actionType) {

            this.$helpers.log(event.target.parentNode.querySelector(".cart-input"), 'flag')

            let unitAdded = parseInt(
                event.target.parentNode.querySelector(".cart-input").value,
                10
            );

            unitAdded = isNaN(unitAdded) ? 0 : unitAdded;
            if (actionType == "add") {
                unitAdded++;
            } else if (actionType == "minus") {
                unitAdded--;
            }

            if (prod.units_available <= unitAdded || prod.resell_units_available <= unitAdded) {
                event.target.parentNode
                    .querySelector(".fa-plus")
                    .classList.add("fade-elem");
            }

            let totalAmountUserIsBuyingFor = unitAdded * prod.cost_per_unit;

            let atpRate = this.getAtpRate(prod, totalAmountUserIsBuyingFor);

            if (prod.category !== "resell") {
                let rateOfUser = atpRate * 0.01;
                let finalAmount = rateOfUser * totalAmountUserIsBuyingFor;

                event.target.parentNode.parentNode.parentNode.parentNode.querySelector(
                        ".user-amount"
                    ).textContent =

                    (totalAmountUserIsBuyingFor + finalAmount)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                event.target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.querySelector(
                    ".user-roi"
                ).textContent = this.formatAmount(atpRate) + "%"
                let minUnit = Math.ceil(prod.vendor_cost_per_unit / prod.cost_per_unit);
                event.target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.querySelector(
                        ".user-cost"
                    ).textContent =

                    (parseFloat(prod.cost_per_unit) * parseFloat(unitAdded))
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                let totalUnitAvailable = (prod.units_available * prod.cost_per_unit)
                if (totalAmountUserIsBuyingFor < prod.vendor_cost_per_unit && this.informUserAboutRateChange && totalUnitAvailable > prod.vendor_cost_per_unit && prod.category == "primary") {

                    this.$toast.warn({
                        title: this.tran.new_rate + "(" + atpRate + "%" + ")",
                        timeOut: 6000,
                        icon: require("../../assets/images/cautionicon.png"),
                        message: this.tran.you_are_buying_less_than +
                            //" " +
                            //prod.atp +
                            //this.tran.current_rate +
                            //atpRate +
                            //"%"
                            minUnit +
                            " " +
                            this.tran.unit_s.toLowerCase(),
                        position: "top right"
                    });

                    this.informUserAboutRateChange = false
                }

                if (totalAmountUserIsBuyingFor >= prod.vendor_cost_per_unit && this.informUserAboutRateChange == false && totalUnitAvailable > prod.vendor_cost_per_unit && prod.category == "primary") {

                    this.$toast.info({
                        title: this.tran.new_rate + '(' + atpRate + '%' + ')',
                        timeOut: 6000,
                        icon: require("../../assets/images/successicon.png"),
                        message: this.tran.your_roi_is_now_on_par,
                        position: "top right"
                    });
                    this.informUserAboutRateChange = true
                }
            }

            if (prod.category == "resell") {
                let rateOfUser = prod.roi * 0.01;
                let finalAmount = rateOfUser * totalAmountUserIsBuyingFor;
                event.target.parentNode.parentNode.parentNode.parentNode.querySelector(
                        ".user-amount"
                    ).textContent =

                    (totalAmountUserIsBuyingFor + finalAmount)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.$helpers.log("resale1", finalAmount);

                event.target.parentNode.parentNode.parentNode.parentNode.querySelector(
                        ".user-cost"
                    ).textContent =

                    (parseFloat(totalAmountUserIsBuyingFor))
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }

            if (actionType == "add") {

                event.target.parentNode.parentNode.parentNode.parentNode.parentNode.querySelector(
                        ".cart-btn"
                    )
                    .classList.remove("fade-elem");
                event.target.parentNode.parentNode.parentNode.parentNode.parentNode.querySelector(
                        ".fa-minus"
                    )
                    .classList.remove("fade-elem");
                var value = parseInt(
                    event.target.parentNode.querySelector(".cart-input").value,
                    10
                );
                value = isNaN(value) ? 0 : value;
                value++;
                event.target.parentNode.querySelector(".cart-input").value = value;

                if (unitAdded == prod.units_available || unitAdded == prod.resell_units_available) {

                    event.target.parentNode.parentNode.parentNode.parentNode
                        .querySelector(".fa-plus")
                        .classList.add("fade-elem");

                }
                // event.target.parentNode.parentNode.querySelector(
                //     ".unit-string"
                // ).textContent = this.tran.units;

                // if (event.target.parentNode.querySelector(".cart-input").value == 1) {
                //     event.target.parentNode.parentNode.querySelector(
                //         ".unit-string"
                //     ).textContent = this.tran.unit;

                // }

            } else if (actionType == "minus") {
                let inputValue = event.target.parentNode.querySelector(".cart-input")
                    .value;
                if (inputValue !== 0 && inputValue !== "") {
                    var value = parseInt(
                        event.target.parentNode.querySelector(".cart-input").value,
                        10
                    );
                    value = isNaN(value) ? 0 : value;
                    value--;
                    event.target.parentNode.querySelector(".cart-input").value = value;
                    event.target.parentNode.parentNode.parentNode.parentNode
                        .querySelector(".fa-plus")
                        .classList.remove("fade-elem");
                }

                if (event.target.parentNode.querySelector(".cart-input").value == 0) {
                    event.target.parentNode.parentNode.parentNode.parentNode.parentNode.querySelector(
                            ".cart-btn"
                        )
                        .classList.add("fade-elem");
                    event.target.parentNode.parentNode.parentNode.parentNode.parentNode.querySelector(
                            ".fa-minus"
                        )
                        .classList.add("fade-elem");
                }

                // if (event.target.parentNode.querySelector(".cart-input").value == 1) {
                //     event.target.parentNode.parentNode.querySelector(
                //         ".unit-string"
                //     ).textContent = this.tran.unit;

                // }
            }
        },

        addToCart(prod, event) {

            let cartObject = {
                product_id: prod.id,
                units: document.querySelector("#inputForMobile").value,
                market: prod.category
            };


            this.$store.commit("market/startLoader");

            http.put(api.addGetDeleteUpdateCart, cartObject)

                .then(response => {

                    this.$store.commit("market/stopLoader");
                    this.$store.commit("domElements/startAddedToCartAnimation");
                    this.$helpers.log(response);

                    this.$router.push('/cart')
                    this.$store.dispatch("market/getCartItems");

                    this.$toast.success({
                        title: this.tran.success,
                        timeOut: 5000,
                        icon: require("../../assets/images/successicon.png"),
                        message: prod.name + " " + "by" + " " + prod.atp + this.tran.added_tocart,
                        position: "top left",
                    });

                    this.$store.commit("market/stopLoader");
                    this.$store.commit("market/hideMobileProductDetailComponent");

                    setTimeout(() => {
                        this.$store.commit("domElements/stopAddedToCartAnimation");
                    }, 2000);
                })

                .catch(error => {
                    this.$store.commit("market/stopLoader");
                    if (error.response) {
                        this.$store.commit("domElements/openNotificationMessage")

                        this.$store.commit("domElements/saveNotificationMessage", error.response.data.message)

                    }
                });
        },

        seeMoreDetails() {

            this.seeMore = !this.seeMore
        },
        seeLessDetails() {

            this.seeMore = false
        },

        focusOnCalcInput() {
            setTimeout(() => {
                $('#calcInput').focus();

            }, 500);
        },

        getFundValue(e) {

            this.unitsToBuy = Math.floor(e.target.value.toString().replace(/,/g, "") / 5000)
            this.amountCalc = this.unitsToBuy * 5000
            e.target.value = parseInt(
                e.target.value.toString().replace(/,/g, ""),
                10
            ).toLocaleString();

            if (isNaN(e.target.value.toString().replace(/,/g, ""))) {

                e.target.value = ''

            }

        },
    },
    computed: {
        ...mapState({
            products: state => state.market.products,
            productObject: state => state.market.productObject,
            productsForSale: state => state.market.productsForSale,
            cartItems: state => state.market.cartItems,
            isComingSoon: state => state.market.isComingSoon,
            productsForMobile: state => state.market.productsForMobile,
            addMobileStatus: state => state.market.addMobileStatus,
        }),
        cartInputValue() {
            return this.cartInput;
        },

        sliceText() {
            if (!this.seeMoreData) {

                return this.productObject.details.substring(0, 50)

            } else {

                return this.productObject.details

            }
        },

        seeMoreData() {

            return this.seeMore

        }
    },

    mounted() {

    }
}
</script>

<style lang="scss" scoped>
.tran-height-mobile{

        min-height:36px;
    transition: min-height 0.5s ease;
    overflow: hidden;

    .fa{
         transition: transform .5s ;
 
    }
}

.see-more-active{
   min-height: 186px;

}
.modal-full {
    min-width: 100%;
    margin: 0;
}

.modal-full .modal-content {
    min-height: 100vh;
}

.modal-header {
    border-top-left-radius: 0rem !important;
    border-top-right-radius: 0rem !important;
}

.modal-content {
    border-radius: 0rem !important;
    padding: 15px;
}
</style>
