<template>
<div >
    <Wrapper>
        <Sidebar slot="sidebar" />

        <Navbar slot="navbar" />
 <Footer slot="footer" />
        <div slot="content" style="text-align:left" >
            <div class="modal  fade bd-example-modal-lg" style="max-width : 100vw;" id="myModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div class="modal-dialog modal-dialog-centered modal-full" role="document">
                    <div class="modal-content" style="max-width: 358px;">

                        <div class="modal-body sign-up-bonus" style="padding:50px; min-height:100px" id="result">
                            <h3 class="text-center pt-3"><b>{{tran.congrat}}</b></h3>

                            <div style="        
display: flex;
flex-direction: column;
justify-content: center; min-height:180px;">

                                <div class="bonus-desc mt-40">
                                    <div style="display:flex">
                                        <h6 style="flex:1">{{tran.signup_bonus}}</h6>
                                        <p>
                                            <img  loading="lazy" src="../../assets/images/gift.png">
                                         </p>
                                    </div>

                                    <h2 class="text-center pt-4" v-if="userDetails.bonus !== null">

                                        NGN {{formatAmount(userDetails.bonus)}}
                                    </h2>
                                </div>
                            </div>

                            <div style="display: flex;flex-direction: column;justify-content: flex-end; min-height:136px;">

                            <div  style="background:#fff; min-height:100px; padding:10px; background: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8))">
                                <h4>{{userDetails.userName}}</h4>
                                <p>{{tran.has_gifted}}</p>
                            </div>
                            </div>

                        </div>

                        <div class="modal-footer text-center" style="cursor:pointer">
                            <h6 class="underline" style="color:#58B46F;">{{tran.start_your}}</h6>

                        </div>
                    </div>
                </div>
            </div>
            <!-- <Tips /> -->
            <div class="container-fluid" style="margin-top:10px; position:relative; max-width:100%">

                    <div style="max-width:800px; margin:0 auto; ">
<div class="mt-4 mb-4" style="display:flex; align-items:center; min-height:100px;background:#fff; padding:20px 0px; border-radius:10px" v-if="secondaryMarket.length < 1 && primaryMarket.length < 1 && !preLoader">


                    <div class="row" style="align-items:center">
                        <div class="col-md-5">
                              <div class="row">
                        
                                  <div class="col-md-12" >

                        <h6 class="pb-2"><b> Hi there! </b></h6>
                        <p v-if="token == null" style="color:#828282">We see that there are no products on the marketplace. Not to worry, we are working on <b style="color:#000"> exciting new products coming soon. Drop us a line and we will tell you first.</b></p>
                        <p v-else style="color:#828282">You don't have to leave your Funds idle in your wallet, <b style="color:#000">you can now book your Funds at 5% per annum on reQuid.</b> </p>
                                  </div>
                            
                        </div>
                        </div>
                        <div class="col-md-7">
                            <div class="row">
<template v-if="token !== null">
                                
                      <div class="col-md-8 offset-md-4 col-12 col-sm-12" style="display: flex;
    align-items: center;" :class="{'mt-3':windowWidth < 768}" >
                                

                                  <button style="background:#0094FF; color:#fff; border:1px solid #0094FF;min-height: 45px; min-width: 100%; border-radius: 5px; "  @click="bookFund">BOOK NOW</button>
                            </div>
</template>
                            <!-- <div class="col-md-12">
                                 <input  v-model="noProductOnthePlatform.email" type="text" autocomplete="off" class="enable-input" placeholder="Email" required>

                            </div> -->
<template v-if="token == null">


                            <div class="col-md-9">
   <input  v-model="noProductOnthePlatform.email" type="text" autocomplete="off" class="enable-input " :class="{'mt-2':windowWidth < 768}" placeholder="Email" required>
                            </div>
                            <div class="col-md-3">
                                    <button style="background:#0094FF; color:#fff; border:1px solid #0094FF;min-height: 45px;border-radius: 5px; min-width:100%" @click="sendNoProductFeedback">Submit</button>
                            </div>
</template>

                        </div>
                    </div>
                            </div>
</div>

                    </div>




                <div class="pre-loader text-center" v-if="preLoader">
                    <span class="loader"></span>
                </div>

                <div class="mt-100" v-if="secondaryMarket.length < 1 && primaryMarket.length < 1 && preLoader == false && soldPaidOut.length < 1" style="padding-top:100px">

                    <h5 class="text-center" v-if="preLoader">{{tran.empty_market}}</h5>

                </div>

                <!-- Slick carousel  -->

<div style="display:flex" class=" mt-4 mb-3"> 


 <div style="min-height:30px; background:#fff; border-radius:20px; display:flex; align-items:center; max-width:50px; justify-content:center; padding:0px 10px" class="mr-4 point-cursor hide-elem" @click="saveActiveMarket('all')">
 <!-- <img :src="imgUrlFromServer+prod.insurer_image" loading="lazy" height="20"> -->


                                                        <p :class="{'active-market-color': activeMarketValue == 'all' }" >All </p>

                                                    </div>

 <div style="min-height:30px; background:#fff; border-radius:20px; display:flex; align-items:center; max-width:200px; justify-content:center; padding:0px 10px" class="mr-4 point-cursor" @click="saveActiveMarket('agriculture')">
 <!-- <img :src="imgUrlFromServer+prod.insurer_image" loading="lazy" height="20"> -->
   <p :class="{'active-market-color': activeMarketValue == 'agriculture' }" >Agriculture </p>

                                                    </div>

                                                       
 <div style="min-height:30px; background:#fff; border-radius:20px; display:flex; align-items:center; max-width:200px; justify-content:center; padding:0px 10px" class="mr-4 point-cursor hide-elem"  @click="saveActiveMarket('euro_bond')">
 <!-- <img :src="imgUrlFromServer+prod.insurer_image" loading="lazy" height="20"> -->


                                                        <p :class="{'active-market-color': activeMarketValue == 'euro_bond' }" >Fixed Income </p>

                                                    </div>
</div>
                <template v-if="searchString == ''">
                    <!-- coming soon market mobile -->

                    <!--slick  resell market Mobile -->

                    <!--slick secondary market mobile -->
                    <template >
                        <div style="background:#fff; padding:10px; border-radius:20px" class="mb-3" v-if="primaryMarket.length > 0 && activeMarketValue == 'all' && windowWidth < 501 || activeMarketValue == 'euro_bond' && windowWidth < 501 " >

                        <div class="display-div">
                            <div>
                                <span class="fade-elem-3">Fixed Income </span>
                            </div>

                            <div class="text-right" style="cursor:pointer">
                                <span class="fw-600" >
                                     <!-- {{tran.see_all}} -->
                                </span>
                                <!-- <span class="pl-2" v-if="secondaryMarket.length > 3">

                                    <i class="fa fa-angle-double-right theme-color"></i>

                                </span> -->
                            </div>
                        </div>

                      
<slick :options="slickOptions" v-if="primaryMarket.length > 0">
                            <div v-for="(item, index) in primaryMarket" :key="index" class="slick-product mt-2 mr-2" @click.stop="sendToDollarView(item)">
                                <div class="mobile-product-card" :style="{ backgroundImage: 'url(' +imgUrlFromServer+item.product_image + ')' }" style="overflow:hidden"  v-if="item.asset_tag == 'euro_bond'">
                                </div>

                                <div  v-if="item.asset_tag == 'euro_bond'">
                                    <h5 class="pt-2">{{item.name}}</h5>

                                    
                                    <span><b> {{item.roi}}</b>%</span> - <span>{{item.maturityInMonths}}</span>
                                </div>
                            </div>

                          

                        </slick>
                        </div>

                    </template>

                    <template >
                        <div style="background:#fff; padding:10px; border-radius:20px" v-if="activeMarketValue ==  'all' && windowWidth < 501">
                        <div class="display-div">
                            <div>
                                <span class="fade-elem-3" @click="saveActiveMarket('agriculture')">Agriculture </span>
                            </div>

                            <div class="text-right" style="cursor:pointer">
                                <span class="fw-600" >
                                     <!-- {{tran.see_all}} -->
                                </span>
                                <!-- <span class="pl-2" v-if="secondaryMarket.length > 3">

                                    <i class="fa fa-angle-double-right theme-color"></i>

                                </span> -->
                            </div>
                        </div>

                      
<slick :options="slickOptions" v-if="allProductsFromApi.length > 0">
                            <div v-for="(item, index) in allProductsFromApi" :key="index" class="slick-product mt-2 mr-2">
                                <div class="mobile-product-card" :style="{ backgroundImage: 'url(' +imgUrlFromServer+item.product_image + ')' }" style="overflow:hidden"  v-if="item.asset_tag == 'agriculture'" @click.prevent="showMobileDetailComponent(item, item.category)">
                                </div>

                                <div  v-if="item.asset_tag == 'agriculture'">
                                    <h5 class="pt-2">{{item.name}}</h5>
                                    <span><b> {{item.roi}}</b>%</span> - <span>{{item.maturityInMonths}}</span>
                                </div>
                            </div>

                          

                        </slick>
</div>
                    </template>


<!-- slick primary market -->
<div v-if="activeMarketValue ==  'agriculture'">
  <div style="background:#fff; padding:10px; border-radius:20px" class="mb-3" v-if="windowWidth < 501 && primaryMarket.length > 0">
                    <!--Slick primary market mobile-->
                
                        <div class="display-div">
                            <div>
                                <span class="fade-elem-3">{{tran.primary}} </span>
                            </div>

                            <div class="text-right" style="cursor:pointer" @click="getCategoryArray('primary', primaryMarket)">
                                <span class="fw-600" @click="getCategoryArray('primary', primaryMarket)" v-if="primaryMarket.length > 3">
                                     {{tran.see_all}}
                                </span>
                                <span class="pl-2" v-if="primaryMarket.length > 3">
                                    <i class="fa fa-angle-double-right theme-color"></i>
                                </span>
                            </div>
                        </div>
                        <slick :options="slickOptions" v-if="primaryMarket.length > 0">
                           

                                   <div v-for="(item, index) in primaryMarket" :key="index" class="slick-product mt-2 mr-2">
                                <div class="mobile-product-card" :style="{ backgroundImage: 'url('+imgUrlFromServer+ item.product_image + ')' }" style="overflow:hidden" @click.prevent="showMobileDetailComponent(item, 'upcoming')" v-if="item.category == 'upcoming'">

                                </div>
                               
                                  <div class="mobile-product-card" :style="{ backgroundImage: 'url('+imgUrlFromServer+ item.product_image + ')' }" style="overflow:hidden" @click.prevent="sendToDollarView (item)" v-if="item.asset_tag == 'euro_bond'">

                                </div>
                                <div class="mobile-product-card" :style="{ backgroundImage: 'url('+imgUrlFromServer+ item.product_image + ')' }" style="overflow:hidden" @click.prevent="showMobileDetailComponent(item, 'primary')" v-if="item.category !== 'upcoming' && item.asset_tag !== 'euro_bond'">

                                </div>

                                <div @click.prevent="showMobileDetailComponent(item, 'upcoming')" v-if="item.category == 'upcoming'">
                                    <h5 class="pt-2">{{item.name}}</h5>

                                    <span class="paid-out-mob">COMING SOON</span>
                                </div>
                                <div @click.prevent="showMobileDetailComponent(item, 'primary')" v-if="item.category !== 'upcoming'">
                                    <h5 class="pt-2">{{item.name}}</h5>

                                    <template v-if="item.units_available > 0 && item.settlement_status !== 'settled'">

                                        <span>{{tran.up_to }}</span> <span><b> {{item.roi}}</b>%</span> - 
                                          <span v-if="item.numOfDays <=13">
                                                            {{item.numOfDays + ' days'}}
                                                        </span>
                                                         <span v-if="item.numOfDays >=  14 && item.numOfDays <=  29">
                                                            {{floorNumb(item.numOfDays) +  ' weeks'}}
                                                        </span>
                                                          <span v-if=" item.numOfDays >  29">
                                                            {{item.maturityInMonths}}
                                                        </span>
                                        
                                        
                                    </template>

                                </div>
                            </div>

                            <div class="mt-2 see-all" @click="getCategoryArray('primary', primaryMarket)" v-if="primaryMarket.length > 3 ">
                                <p class="text-center mt-4 fw-600">{{tran.see_all}}</p>
                                <i class="fa fa-share-square-o"></i>
                            </div>
                            <div class="mt-2" style="background:#fff !important">

                            </div>
                        </slick>
                   </div>





  <!-- Secondary market Mobile-->
  <div style="background:#fff; padding:10px; border-radius:20px" class="mb-3" v-if="windowWidth < 501  && secondaryMarket.length > 0">
                  
                        <div class="display-div">
                            <div>
                                <span class="fade-elem-3">{{ tran.secondary }} </span>
                            </div>

                            <div class="text-right" style="cursor:pointer" @click="getCategoryArray('secondary', secondaryMarket)">
                                <span class="fw-600" @click="getCategoryArray('secondary', secondaryMarket)" v-if="secondaryMarket.length > 3">
                                     {{tran.see_all}}
                                </span>
                                <span class="pl-2" v-if="secondaryMarket.length > 3">

                                    <i class="fa fa-angle-double-right theme-color"></i>

                                </span>
                            </div>
                        </div>

                        <slick :options="slickOptions" v-if="secondaryMarket.length > 0">
                            <div v-for="(item, index) in secondaryMarket" :key="index" class="slick-product mt-2 mr-2" >
                                <div class="mobile-product-card" :style="{ backgroundImage: 'url(' +imgUrlFromServer+item.product_image + ')' }" style="overflow:hidden" @click.prevent="showMobileDetailComponent(item, 'secondary')">
                                </div>

                                <div @click.prevent="showMobileDetailComponent(item, 'secondary')" v-if="item.units_available > 0 && item.settlement_status !== 'settled' || item.resell_units_available > 0 && item.settlement_status !== 'settled'">
                                    <h5 class="pt-2">{{item.name}}</h5>
                                    <span><b> {{item.roi}}</b>%</span> -
                                    
                                    
                                       <span v-if="item.numOfDays <=13">
                                                            {{item.numOfDays + ' days'}}
                                                        </span>
                                                         <span v-if="item.numOfDays >=  14 && item.numOfDays <=  29">
                                                            {{floorNumb(item.numOfDays) +  ' weeks'}}
                                                        </span>
                                                          <span v-if=" item.numOfDays >  29">
                                                            {{item.maturityInMonths}}
                                                        </span>
                                </div>
                            </div>

                            <div class="see-all mt-2" @click="getCategoryArray('secondary', secondaryMarket)" v-if="secondaryMarket.length > 3">
                                <p class="text-center fw-600 mt-4">{{tran.see_all}}</p>
                                <i class="fa fa-share-square-o"></i>
                            </div>

                            <div class="mt-2" style="background:#fff !important">

                            </div>
                        </slick>
  </div>

                 
                  <div style="background:#fff; padding:10px; border-radius:20px" class="mb-3" v-if="windowWidth < 501 && soldPaidOut.length > 0">
                        <div class="display-div">
                            <div>
                                <span class="fade-elem-3">{{tran.sold_out_paid}} </span>
                            </div>
                            <div class="text-right" style="cursor:pointer" @click="getCategoryArray('soldOut', soldPaidOut)">
                                <span class="fw-600" @click="getCategoryArray('soldOut', soldPaidOut)" v-if="soldPaidOut.length > 3">
                                     {{tran.see_all}}
                                </span>
                                <span class="pl-2" v-if="primaryMarket.length > 3">
                                    <i class="fa fa-angle-double-right theme-color"></i>
                                </span>
                            </div>
                        </div>
                        <slick :options="slickOptions" v-if="soldPaidOut.length > 0">
                           
                                  <div v-for="(item, index) in soldPaidOut" :key="index" class="slick-product mt-2 mr-2">
                                <div class="mobile-product-card" :style="{ backgroundImage: 'url('+imgUrlFromServer+ item.product_image + ')' }" style="overflow:hidden" @click.prevent="showMobileDetailComponent(item, 'soldOut')">

                                </div>

                                <div @click.prevent="showMobileDetailComponent(item, 'soldOut')">
                                    <h5 class="pt-2">{{item.name}}</h5>

                                    <template v-if="item.units_available < 1 && item.settlement_status !== 'settled' || item.resell_units_available < 1 && item.settlement_status !== 'settled' ">
                                        <span class="sold-out-mob">SOLD OUT</span>

                                    </template>
                        <template v-if="item.units_available < 1 && item.settlement_status === 'phased'">
                                        <span class="phased-out-mob">PHASED</span>

                                    </template>
                                    <template v-if="item.settlement_status == 'settled'">
                                        <span class="paid-out-mob">PAID OUT</span>

                                    </template>
                                    <template v-if="item.units_available > 0 && item.settlement_status !== 'settled'">

                                        <span>{{tran.up_to }}</span> <span><b> {{item.roi}}</b>%</span> - <span>{{item.maturityInMonths}}</span>
                                    </template>

                                </div>
                            </div>

                            <div class="mt-2 see-all" @click="getCategoryArray('soldOut', primaryMarket)" v-if="primaryMarket.length > 3">
                                <p class="text-center mt-4 fw-600">{{tran.see_all}}</p>
                                <i class="fa fa-share-square-o"></i>
                            </div>
                            <div class="mt-2" style="background:#fff !important">

                            </div>
                        </slick>
                    </div>
</div>

                    <!-- <searchBar /> -->
                    <template v-if="productDetailComponent && searchString == ''">
                        <productDetail />
                    </template>


             <div v-show="windowWidth > 500" class="product-cover animated slideInUp" v-if="primaryMarket.length > 0 && activeMarketValue == 'all' || activeMarketValue == 'euro_bond' " >
                                <div class="display-div pb-2">
                                    <div>
                                        <span  @click="saveActiveMarket('euro_bond')" style="cursor:pointer"><b>Fixed Income </b></span> <span><i class="fa fa-angle-double-right"></i> </span>
                                    </div>
                                    <!-- <div class="text-right" style="cursor:pointer" v-if="primaryMarket.length > 3">
                                        <span @click="getCategoryArray('primary', primaryMarket)">
                                                              {{tran.see_all}}
                                                         </span>
                             <span class="pl-4 mr-4" v-if="primaryMarket.length> 3">
                            <i class="fa fa-angle-double-right"></i>
                                                        </span>
                                    </div> -->
                                </div>
                              
                                <div v-for="(prod) in sliceData(primaryMarket)" :key="prod.id" style="display:inline; border-radius:10px" class="prod-cards">

                                    <!-- EuroBond div -->
                <div class="item" @click.stop="sendToDollarView(prod)" v-if="prod.asset_tag == 'euro_bond'">

<div class="img-div-1" :class="{'showHideDiv': productSelectedByUser == prod.id}" style="position: absolute; z-index: 90;" >
                                        </div>
                                        <div class="img-div" :style="{ backgroundImage: 'url(' +imgUrlFromServer+prod.product_image + ')' }">
                                            <p class="scrollDiv"></p>

                                            <template v-if="prod.units_available > 0 && prod.settlement_status !== 'settled' && prod.category !== 'upcoming'">

                                                <p class="text-right" style="text-decoration:underline">
                                                    {{formatNumber(prod.units_available)}} <span v-if="prod.units_available < 2"> {{tran.unit_available}} </span> <span v-else>{{tran.units_available}}</span></p>
                                            </template>

                                         

                                            <div class="atp-preview">
                                                <div>
                                                    <h6> <b> {{prod.name}} </b> </h6>

                                                    <!-- <p>{{prod.atp}}</p> -->
                                                </div>
                                                <div class="atp-roi">
                                                    <p>{{tran.up_to }} <span v-if="prod.units_available < 1"> {{prod.roi_opened_with}} % </span> <span v-else> {{prod.roi}}% </span></p>
                                                    <p v-if="prod.units_available < 1">{{prod.product_opened_with}}</p>
                                                    <p v-else>{{prod.maturityInMonths}}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div style="position:relative;text-align:center; right:12px">

                                            <img src="../../assets/images/arrow.png" loading="lazy"  class="fa-angle-down animated slideInDown mb-4"  :class="{'show-elem-inline' : prod.id == productSelectedByUser && selectedCategory == 'primary'}" style="margin-top:0.8rem!important" height="20">
                                                  </div>
                                        </div>
                                  

                                        </div>
                                        <div style="clear: left;border-top-left-radius: 20px;
  border-top-right-radius: 20px;"></div>
                                    </div>


<div style="clear:both; min-height:20px" v-if="windowWidth > 501  && activeMarketValue ==  'all'">

</div>


                    <!-- All Market-->
                    <!-- Desktop View -->


       <div v-show="windowWidth > 500" class="product-cover  animated slideInUp" v-if="activeMarketValue ==  'all'">
                                <div class="display-div pb-2">
                                    <div>
                                        <span @click="saveActiveMarket('agriculture')" style="cursor:pointer"><b>Agriculture </b></span> <span><i class="fa fa-angle-double-right"></i> </span>
                                    </div>
                                    <div class="text-right" style="cursor:pointer" v-if="secondaryMarket.length > 3">
                                        <span @click="saveActiveMarket('agriculture')">
                                                              {{tran.see_all}}
                                                         </span>
                             <span class="pl-4 mr-4" v-if="secondaryMarket.length> 3">
                            <i class="fa fa-angle-double-right"></i>
                                                        </span>
                                    </div>
                                </div>
                              
                                <div v-for="(prod) in sliceData(allProductsFromApi)" :key="prod.id" style="display:inline; border-radius:10px" class="prod-cards">

    <div class="item" @click="getProductObject(prod, $event); scrollToProduct (prod)" v-if="prod.asset_tag == 'agriculture'">
                                <div class="img-div-1" :class="{'showHideDiv': productSelected == prod.id}" style="position: absolute; z-index: 90;" @click.prevent="closeAllDivs($event)">
                                </div>
                            <div class="img-div" :style="{ backgroundImage: 'url(' +serVerImage+prod.product_image + ')' }">
                                    <p class="scrollDiv"></p>

                                
                                    <template v-if="prod.units_available > 0 && prod.settlement_status !== 'settled' || prod.resell_units_available > 0 && prod.settlement_status !== 'settled'">

                                        <p class="text-right" style="text-decoration:underline" >{{formatNumber(prod.units_available  || prod.resell_units_available )}} {{tran.units_available}}</p>
                                   
                                    </template>

                                      <template v-if="prod.units_available < 1 && prod.settlement_status !== 'settled' || prod.resell_units_available < 1 && prod.settlement_status !== 'settled'">
                                                                <div class="sold-out">
                                                                    <p>SOLD OUT</p>
                                                                </div>
                                                            </template>
                                    <template v-if="prod.settlement_status == 'settled'">
                                                                <div class="paid-out"  >
                                                                    <p>PAID OUT</p>
                                                                </div>
                                                            </template>
                                    <div class="atp-preview">
                                        <div>
                                            <h6>
                                                <b>{{prod.name}}</b>
                                            </h6>
                                            <p>{{prod.atp}}</p>
                                        </div>
                                        <!-- <div class="atp-roi">
                                            <p v-if="currentCategory == 'primary' || currentCategory == 'upcoming'">{{tran.up_to}} {{prod.roi}}%</p>
                                            <p v-else-if="currentCategory == 'secondary'"> {{prod.roi}}%</p>
                                            <p v-else>{{tran.up_to}} {{prod.resell_roi}}%</p>
                                            <p>{{prod.matures_at}}</p>
                                        </div> -->

                                            <div class="atp-roi">
                                                    <p>  <span v-if="prod.category == 'primary' || prod.category == 'upcoming'">{{tran.up_to}}</span><span v-if="prod.resell_units_available < 1 || prod.units_available < 1"> {{prod.roi_opened_with}} % </span> <span v-else> {{prod.roi}}% </span></p>
                                                    
                                                  
                                                <p v-if="prod.resell_units_available < 1  || prod.units_available < 1">{{prod.product_opened_with}}</p>
                                                    <p v-else>{{prod.maturityInMonths}}</p>
                                                </div>
                                          </div>
                                     </div>

                                <div style="position:relative;text-align:center; right:12px">

                                    <img src="../../assets/images/chev-up.png"  class="fa-angle-down animated slideInDown mb-4"  :class="{'show-elem-inline' : prod.id == productSelectedByUser}" style="margin-top:0.8rem!important" height="30">
                                        </div>
                                </div>






                                <div class="info mb-3" :class="{'show-elem' : prod.id == productSelectedByUser && prod.category == selectedCategory}">
                                  
                                    
                                            <div class="investment-details">
                                     

                                                <div class="product-detail mr-4">
                                                    <div>
                                                        <h3>{{prod.name}} <span v-if="prod.category == 'upcoming'">(Coming Soon)</span></h3>

                                                       <div v-html="prod.details"></div>

                                                    </div>
                                                   
                                                </div>

                                                <div class="cart-point mt-4">
                                                    <div style="" class="close-btn">
                                                    <div style="min-height:30px; background:#fff; border-radius:20px; display:flex; align-items:center; max-width:250px; justify-content:space-between; padding:0px 10px" class="mr-4 point-cursor" @click="sendUserToAtpDetailView(prod.atp_id)">
                                                      <img :src="imgUrlFromServer+prod.atp_image" loading="lazy" height="20">


                                                        <p style="color:#4F4F4F">{{prod.atp}}</p>
 <img src="../../assets/images/info.png" loading="lazy" height="20">
                                                    </div>
                                                    <div style="min-height:30px; background:#fff; border-radius:20px; display:flex; align-items:center; max-width:250px; justify-content:space-between; padding:0px 10px" class="mr-4">
 <!-- <img :src="imgUrlFromServer+prod.insurer_image" loading="lazy" height="20"> -->


                                                        <p style="color:#4F4F4F">{{prod.insurer_name}}</p>
 <img src="../../assets/images/info.png" loading="lazy" height="20">
                                                    </div>
                                                        <img src="../../assets/images/close-exit.png" loading="lazy" height="25" style="cursor:pointer" class="close-info-div" @click.prevent="closeInfoDiv($event)">
                                                   </div>
                                         <div class="crt-div mt-4 mb-4">
                                                     <div style="display:flex;" >
                                                         <img src="../../assets/images/ui1.png" loading="lazy" height="15" class="mr-2">
                                                         <p v-if="prod.units_available < 1" class="user-roi  fw-600"> <i class="fa fa-percent add-curved-radius mr-4"></i> {{formatAmount(prod.roi_opened_with)}} % Interest</p>
                                          <p class="user-roi fw-600" v-else> {{formatAmount(prod.roi)}}% Interest</p>

                                               <div style="display: flex;
    justify-content: flex-end;">


       <input class="prod-url" type="hidden" :value="productUrl+'/market/'+`${prod.category}`+'/'+`${prod.urlName}`+'/'+`${prod.id}`" style="position:absolute">
                                                      
                                                        <img src="../../assets/images/share-new.png" loading="lazy" height="30" style="cursor:pointer" @click.prevent="copyProductUrl($event)" :content="tran.copied_to_clipboard" v-tippy="{trigger : 'click', hideOnClick : true, sticky : false}">
                                                                </div>
                                         
                                                    </div>

                                             <div style="display:flex;">
                                               <p class="text-left fw-600" v-if="prod.units_available < 1">
                                                    <img src="../../assets/images/ui2.png" loading="lazy" height="15" class="mr-2">{{prod.product_opened_with}}</p>
                                                                <p class="text-left fw-600" v-else>    <img src="../../assets/images/ui2.png" loading="lazy" height="15" class="mr-2">{{prod.maturityInMonths}}</p>
                                                 
<!-- 
                                              <p class="text-right fw-600"> <span>&#8358;</span>{{formatAmount(prod.cost_per_unit)}}</p> -->
                                                            </div>

                                          
                                                <div style="display:flex;">
                                                     <img src="../../assets/images/ui3.png" loading="lazy" height="15" class="mr-2">
                                                <p class="text-left fw-600"> <span>&#8358;</span>{{formatAmount(prod.cost_per_unit)}} per unit</p> 

                                            <!-- <p class="text-right fw-600" v-if="prod.units_available < 1">{{prod.product_opened_with}}</p>
                                                                <p class="text-right fw-600" v-else>{{prod.matures_at}}</p> -->

                                                            </div>
                                                            <div style="display:flex;" >

                                                                  <img src="../../assets/images/ui4.png" loading="lazy" height="15" class="mr-2">
                                                            
                                                                <p><span style="color:#828282">Opens On:</span>
                                                                <b> {{prod.opens_at | formatDate }}</b>
                                                                </p>

                                                            </div>
                                                            <div style="display:flex;" >

                                                                  <img src="../../assets/images/ui4.png" loading="lazy" height="15" class="mr-2">
                                                            
                                                                <p><span style="color:#828282">Matures On:</span>
                                                                <b> {{prod.matures_at | formatDate }}</b>
                                                                </p>
                                                              

                                                            </div>
                                                        </div>

                                                        <div class="add-units" :class="{'fade-elem': prod.units_available == 0, 'hide-elem':prod.category == 'upcoming' ||prod.units_available < 1 }">
                                                                 <div class="display-div pt-3" style=" padding:6px 15px; border-bottom: 1px solid #F2F2F2;">
                                                                <div class="text-left" >
                                                            <p class="pb-1" style="color:#828282
">Cost (Multiples of ₦5,000):</p>
                                                                   <span style="color:#0094FF">&#8358;</span>
                                                                    <span class="cost-unit text-center" style="color:#0094FF">   {{formatAmount(prod.user_cost)}}</span>

                                                                </div>
                                                                <div class="text-left" style="color:#58B46F">
                                                                        <p class="pb-1" style="color:#828282
">ROI (Return on Investment):</p>
                                                                    <span>&#8358;</span>
                                                                    <span class="user-amount text-center">{{formatAmount(prod.user_roi)}}</span>

                                                                </div>

                                                            </div>
                                                            <h6 class="pt-4 pb-2 text-left"><b>Add Units</b> <span style="color:#828282
">(One unit =  ₦5,000)</span></h6>

    <div style="padding:10px; display:flex; justify-content:space-between">

                                                            <div style="padding:0px 15px;display:flex; align-items:center; border: 0.5px solid #33A9FF; border-radius:5px; background:#F5F5F5; flex-basis:60%" class="mr-2" >
                                                        
                                                                <i class="fa fa-minus text-left" @click="getUserRoi($event, prod, 'minus')"  :class="{'fade-elem':prod.defaultUnitSelected < 2 }" style="flex-basis:10%"></i>

                                                                <input type="text" class="cart-input text-center" @keyup="checkUnitValue( $event, prod)" onfocus="this.setSelectionRange(this.value.length,this.value.length);" :value="prod.defaultUnitSelected" style="flex-basis:80%; background:transparent">

                                                                <i class="fa fa-plus text-right" @click="getUserRoi($event, prod, 'add')" :class="{'fade-elem': prod.defaultUnitSelected == prod.units_available }" style="flex-basis:10%"></i>

                                                            </div>
                                                       <button @click.prevent="addToCart(prod,$event)" class="cart-btn" :class="{'fade-elem': loader }" v-if="token !== null" style="flex-basis:30%">
                                            {{loader ? '' :  tran.add_tocart}}
                                            <span class="loader" v-if="loader"></span>
                                          </button>
                                                            <button class="cart-btn"  @click="sendGuestTosignUp" style="flex-basis:30%" v-else>
                                            {{  tran.sign_up_to_purchase}}
                                            <span class="loader" v-if="loader"></span>
                                          </button>    
    </div>
                                                       
                                                         
                                                        </div>
                                                    </div>
                                                </div>

                                    </div>
                                        </div>
                                        <div style="clear: left;border-top-left-radius: 20px;
  border-top-right-radius: 20px;"></div>
                                    </div>
<!-- 

<div style="clear:both; min-height:20px" v-if="windowWidth > 501 && activeMarketValue ==  'all'">

</div> -->

       









<!-- 
<div style="clear:both; min-height:20px" v-if="windowWidth > 501 && secondaryMarket.length > 0 && activeMarketValue !==  'all'" >

</div> -->

                            <!-- Desktop View -->
                            <!-- Primary Market -->
                            <div v-show="windowWidth > 500" class="product-cover"  v-if="primaryMarket.length > 0 && activeMarketValue ==  'agriculture'" >
                                <div class="display-div pb-2">
                                    <div>
                                        <span @click="getCategoryArray('primary', primaryMarket)" style="cursor:pointer"><b>{{tran.primary}} </b></span> <span><i class="fa fa-angle-double-right"></i> </span>
                                    </div>
                                    <div class="text-right" style="cursor:pointer" v-if="primaryMarket.length > 3">
                                        <span @click="getCategoryArray('primary', primaryMarket)">
                                                              {{tran.see_all}}
                                                         </span>
                             <span class="pl-4 mr-4" v-if="primaryMarket.length> 3">
                            <i class="fa fa-angle-double-right"></i>
                                                        </span>
                                    </div>
                                </div>
                              
                                <div v-for="(prod) in sliceData(primaryMarket)" :key="prod.id" style="display:inline; border-radius:10px" class="prod-cards">

                                    <!-- EuroBond div -->
                <div class="item" @click.stop="sendToDollarView(prod)" v-if="prod.asset_tag == 'euro_bond'">

<div class="img-div-1" :class="{'showHideDiv': productSelectedByUser == prod.id}" style="position: absolute; z-index: 90;" >
                                        </div>
                                        <div class="img-div" :style="{ backgroundImage: 'url(' +imgUrlFromServer+prod.product_image + ')' }">
                                            <p class="scrollDiv"></p>

                                            <template v-if="prod.units_available > 0 && prod.settlement_status !== 'settled' && prod.category !== 'upcoming'">

                                                <p class="text-right" style="text-decoration:underline">
                                                    {{formatNumber(prod.units_available)}} <span v-if="prod.units_available < 2"> {{tran.unit_available}} </span> <span v-else>{{tran.units_available}}</span></p>
                                            </template>

                                         

                                            <div class="atp-preview">
                                                <div>
                                                    <h6> <b> {{prod.name}} </b> </h6>

                                                    <!-- <p>{{prod.atp}}</p> -->
                                                </div>
                                                <div class="atp-roi">
                                                    <p>{{tran.up_to }} <span v-if="prod.units_available < 1"> {{prod.roi_opened_with}} % </span> <span v-else> {{prod.roi}}% </span></p>
                                                    <p v-if="prod.units_available < 1">{{prod.product_opened_with}}</p>
                                                    <p v-else>{{prod.maturityInMonths}}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div style="position:relative;text-align:center; right:12px">

                                            <!-- <img src="../../assets/images/arrow.png" loading="lazy"  class="fa-angle-down animated slideInDown mb-4"  :class="{'show-elem-inline' : prod.id == productSelectedByUser && selectedCategory == 'primary'}" style="margin-top:0.8rem!important" height="20"> -->
                                                  </div>
                                        </div>
                                          <!-- <div v-if="prod.asset_tag == 'agriculture'" class="item" @click="getProductObject(prod, $event);  scrollToProduct (prod)"> updated code -->
                                          <div class="item" @click="getProductObject(prod, $event);  scrollToProduct (prod)">
                                        <div class="img-div-1" :class="{'showHideDiv': productSelectedByUser == prod.id}" style="position: absolute; z-index: 90;" @click.prevent="closeAllDivs($event)">
                                        </div>
                                        <div class="img-div" :style="{ backgroundImage: 'url(' +imgUrlFromServer+prod.product_image + ')' }">
                                            <p class="scrollDiv"></p>

                                            <template v-if="prod.units_available > 0 && prod.settlement_status !== 'settled' && prod.category !== 'upcoming'">

                                                <p class="text-right" style="text-decoration:underline">
                                                    {{formatNumber(prod.units_available)}} <span v-if="prod.units_available < 2"> {{tran.unit_available}} </span> <span v-else>{{tran.units_available}}</span></p>
                                            </template>

                                            <template v-if="prod.category == 'upcoming'">
                                                <div class="paid-out">
                                                    <p style="background:#0094FF !important"> COMING SOON </p>
                                                </div>
                                            </template>

                                            <div class="atp-preview">
                                                <div>
                                                    <h6> <b> {{prod.name}} </b> </h6>

                                                    <p>{{prod.atp}}</p>
                                                </div>
                                                <div class="atp-roi">
                                                    <p>{{tran.up_to }} <span v-if="prod.units_available < 1"> {{prod.roi_opened_with}} % </span> <span v-else> {{prod.roi}}% </span></p>
                                                    <p v-if="prod.units_available < 1">{{prod.product_opened_with}}</p>
                                                    <p v-else>{{prod.maturityInMonths}}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div style="position:relative;text-align:center; right:12px">

                                            <img src="../../assets/images/chev-up.png" loading="lazy"  class="fa-angle-down animated slideInDown mb-4"  :class="{'show-elem-inline' : prod.id == productSelectedByUser && selectedCategory == 'primary'}" style="margin-top:0.8rem!important" height="30">
                                                  </div>
                                        </div>

                                        <div class="info mb-3" :id="'product' + prod.id" :class="{'show-elem' : prod.id == productSelectedByUser && selectedCategory == 'primary'}" :ref="'product' + prod.id" v-if="prod.units_available > 0 && prod.settlement_status !== 'settled' || prod.resell_units_available > 0 && prod.settlement_status !== 'settled'">







                                            <div class="investment-details">
                                                <div class="product-detail mr-4">
                                                    <div>
                                                        <h3>{{prod.name}} <span v-if="prod.category == 'upcoming'">(Coming Soon)</span></h3>
                                                      
                                                       <div v-html="prod.details"></div>

                                                    </div>
                                                   
                                                </div>

                                                <div class="cart-point mt-4">
                                                    <div style="" class="close-btn">
                                                    <div style="min-height:30px; background:#fff; border-radius:20px; display:flex; align-items:center; max-width:250px; justify-content:space-between; padding:0px 10px"  @click="sendUserToAtpDetailView(prod.atp_id)" class="mr-4 point-cursor">
                                                      <img :src="imgUrlFromServer+prod.atp_image" loading="lazy" height="20">


                                                        <p style="color:#4F4F4F">{{prod.atp}}</p>
 <img src="../../assets/images/info.png" loading="lazy" height="20">
                                                    </div>
                                                    <div style="min-height:30px; background:#fff; border-radius:20px; display:flex; align-items:center; max-width:250px; justify-content:space-between; padding:0px 10px" class="mr-4">
 <!-- <img :src="imgUrlFromServer+prod.insurer_image" loading="lazy" height="20"> -->


                                                        <p style="color:#4F4F4F">{{prod.insurer_name}}</p>
 <img src="../../assets/images/info.png" loading="lazy" height="20">
                                                    </div>
                                                        <img src="../../assets/images/close-exit.png" loading="lazy" height="25" style="cursor:pointer" class="close-info-div" @click.prevent="closeInfoDiv($event)">
                                                   </div>
                                         <div class="crt-div mt-4 mb-4">
                                                     <div style="display:flex;" >
                                                         <img src="../../assets/images/ui1.png" loading="lazy" height="15" class="mr-2">
                                                         <p v-if="prod.units_available < 1" class="user-roi  fw-600"> <i class="fa fa-percent add-curved-radius mr-4"></i> {{formatAmount(prod.roi_opened_with)}} % Interest</p>
                                          <p class="user-roi fw-600" v-else> {{formatAmount(prod.roi)}}% Interest</p>

                                               <div style="display: flex;
    justify-content: flex-end;">


       <input class="prod-url" type="hidden" :value="productUrl+'/market/'+`${prod.category}`+'/'+`${prod.urlName}`+'/'+`${prod.id}`" style="position:absolute">
                                                      
                                                        <img src="../../assets/images/share-new.png" loading="lazy" height="30" style="cursor:pointer" @click.prevent="copyProductUrl($event)" :content="tran.copied_to_clipboard" v-tippy="{trigger : 'click', hideOnClick : true, sticky : false}">
                                                                </div>
                                         
                                                    </div>

                                             <div style="display:flex;">
                                               <p class="text-left fw-600" v-if="prod.units_available < 1">
                                                    <img src="../../assets/images/ui2.png" loading="lazy" height="15" class="mr-2">{{prod.product_opened_with}}</p>
                                                                <p class="text-left fw-600" v-else>    <img src="../../assets/images/ui2.png" loading="lazy" height="15" class="mr-2">{{prod.maturityInMonths}}</p>
                                                 
<!-- 
                                              <p class="text-right fw-600"> <span>&#8358;</span>{{formatAmount(prod.cost_per_unit)}}</p> -->
                                                            </div>

                                          
                                                <div style="display:flex;">
                                                     <img src="../../assets/images/ui3.png" loading="lazy" height="15" class="mr-2">
                                                <p class="text-left fw-600"> <span>&#8358;</span>{{formatAmount(prod.cost_per_unit)}} per unit</p> 

                                            <!-- <p class="text-right fw-600" v-if="prod.units_available < 1">{{prod.product_opened_with}}</p>
                                                                <p class="text-right fw-600" v-else>{{prod.matures_at}}</p> -->

                                                            </div>
                                                            <div style="display:flex;" >

                                                                  <img src="../../assets/images/ui4.png" loading="lazy" height="15" class="mr-2">
                                                            
                                                                <p><span style="color:#828282">Opens On:</span>
                                                                <b> {{prod.opens_at | formatDate }}</b>
                                                                </p>

                                                            </div>
                                                            <div style="display:flex;" >

                                                                  <img src="../../assets/images/ui4.png" loading="lazy" height="15" class="mr-2">
                                                            
                                                                <p><span style="color:#828282">Matures On:</span>
                                                                <b> {{prod.matures_at | formatDate }}</b>
                                                                </p>
                                                              

                                                            </div>
                                                        </div>

                                                        <div class="add-units" :class="{'fade-elem': prod.units_available == 0, 'hide-elem':prod.category == 'upcoming' }">
                                                                 <div class="display-div pt-3" style=" padding:6px 15px; border-bottom: 1px solid #F2F2F2;">
                                                                <div class="text-left" >
                                                            <p class="pb-1" style="color:#828282
">Cost (Multiples of ₦5,000):</p>
                                                                   <span style="color:#0094FF">&#8358;</span>
                                                                    <span class="cost-unit text-center" style="color:#0094FF">   {{formatAmount(prod.user_cost)}}</span>

                                                                </div>
                                                                <div class="text-left" style="color:#58B46F">
                                                                        <p class="pb-1" style="color:#828282
">ROI (Return on Investment):</p>
                                                                    <span>&#8358;</span>
                                                                    <span class="user-amount text-center">{{formatAmount(prod.user_roi)}}</span>

                                                                </div>

                                                            </div>
                                                            <h6 class="pt-4 pb-2 text-left"><b>Add Units</b> <span style="color:#828282
">(One unit =  ₦5,000)</span></h6>

    <div style="padding:10px; display:flex; justify-content:space-between">

                                                            <div style="padding:0px 15px;display:flex; align-items:center; border: 0.5px solid #33A9FF; border-radius:5px; background:#F5F5F5; flex-basis:60%" class="mr-2" >
                                                        
                                                                <i class="fa fa-minus text-left" @click="getUserRoi($event, prod, 'minus')"  :class="{'fade-elem':prod.defaultUnitSelected < 2 }" style="flex-basis:10%"></i>

                                                                <input type="text" class="cart-input text-center" @keyup="checkUnitValue( $event, prod)" onfocus="this.setSelectionRange(this.value.length,this.value.length);" :value="prod.defaultUnitSelected" style="flex-basis:80%; background:transparent">

                                                                <i class="fa fa-plus text-right" @click="getUserRoi($event, prod, 'add')" :class="{'fade-elem': prod.defaultUnitSelected == prod.units_available }" style="flex-basis:10%"></i>

                                                            </div>
                                                       <button @click.prevent="addToCart(prod,$event)" class="cart-btn" :class="{'fade-elem': loader }" v-if="token !== null" style="flex-basis:30%">
                                            {{loader ? '' :  tran.add_tocart}}
                                            <span class="loader" v-if="loader"></span>
                                          </button>
                                                            <button class="cart-btn"  @click="sendGuestTosignUp" style="flex-basis:30%" v-else>
                                            {{  tran.sign_up_to_purchase}}
                                            <span class="loader" v-if="loader"></span>
                                          </button>    
    </div>
                                                       
                                                         
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="clear: left;border-top-left-radius: 20px;
  border-top-right-radius: 20px;"></div>
                                    </div>

                                 


<div style="clear:both; min-height:20px"  v-if="windowWidth > 501 && primaryMarket.length > 0 && activeMarketValue !==  'all'">

</div> 


             <!-- Secondary Market-->
                    <!-- Desktop View -->


       <div v-show="windowWidth > 500" class="product-cover" v-if="secondaryMarket.length > 0 && activeMarketValue ==  'agriculture'" >
                                <div class="display-div pb-2">
                                    <div>
                                        <span @click="getCategoryArray('secondary', secondaryMarket)" style="cursor:pointer"><b>{{tran.secondary}}  </b></span> <span><i class="fa fa-angle-double-right"></i> </span>
                                    </div>
                                    <div class="text-right" style="cursor:pointer" v-if="secondaryMarket.length > 3">
                                        <span @click="getCategoryArray('secondary', secondaryMarket)">
                                                              {{tran.see_all}}
                                                         </span>
                             <span class="pl-4 mr-4" v-if="secondaryMarket.length> 3">
                            <i class="fa fa-angle-double-right"></i>
                                                        </span>
                                    </div>
                                </div>
                              
                                <div v-for="(prod) in sliceData(secondaryMarket)" :key="prod.id" style="display:inline; border-radius:10px" class="prod-cards">

  
                                          <div  class="item" @click="getProductObject(prod, $event);  scrollToProduct (prod)">
                                        <div class="img-div-1" :class="{'showHideDiv': productSelectedByUser == prod.id}" style="position: absolute; z-index: 90;" @click.prevent="closeAllDivs($event)">
                                        </div>
                                        <div class="img-div" :style="{ backgroundImage: 'url(' +imgUrlFromServer+prod.product_image + ')' }">
                                            <p class="scrollDiv"></p>

                                            <template >

                                                <p class="text-right" style="text-decoration:underline">
                                                    {{prod.units_available || prod.resell_units_available }} <span v-if="prod.units_available < 2"> {{tran.unit_available}} </span> <span v-else>{{tran.units_available}}</span></p>
                                            </template>

                                            
                                            <div class="atp-preview">
                                                <div>
                                                    <h6> <b> {{prod.name}} </b> </h6>

                                                    <p>{{prod.atp}}</p>
                                                </div>
                                                <div class="atp-roi">
                                                    <p>{{tran.up_to }} <span v-if="prod.units_available < 1"> {{prod.roi_opened_with}} % </span> <span v-else> {{prod.roi}}% </span></p>
                                                    <p v-if="prod.units_available < 1">{{prod.product_opened_with}}</p>
                                                    <p v-else>
                                                        
                                                        <span v-if="prod.numOfDays <=13">
                                                            {{prod.numOfDays + ' days'}}
                                                        </span>
                                                         <span v-if="prod.numOfDays >=  14 && prod.numOfDays <=  29">
                                                            {{floorNumb(prod.numOfDays) +  ' weeks'}}
                                                        </span>
                                                          <span v-if=" prod.numOfDays >  29">
                                                            {{prod.maturityInMonths}}
                                                        </span>
                                                        </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div style="position:relative;text-align:center; right:12px">

                                            <img src="../../assets/images/chev-up.png" loading="lazy"  class="fa-angle-down animated slideInDown mb-4"  :class="{'show-elem-inline' : prod.id == productSelectedByUser && selectedCategory == 'primary'}" style="margin-top:0.8rem!important" height="30">
                                                  </div>
                                        </div>

                                        <div class="info mb-3" :id="'product' + prod.id" :class="{'show-elem' : prod.id == productSelectedByUser && selectedCategory == 'primary'}" :ref="'product' + prod.id" v-if="prod.units_available > 0 && prod.settlement_status !== 'settled' || prod.resell_units_available > 0 && prod.settlement_status !== 'settled'">

                                            <div class="investment-details">
                                     

                                                <div class="product-detail mr-4">
                                                    <div>
                                                        <h3>{{prod.name}} <span v-if="prod.category == 'upcoming'">(Coming Soon)</span></h3>
                                                   
  <div v-html="prod.details"></div>
                                                    </div>
                                                   
                                                </div>

                                                <div class="cart-point mt-4">
                                                    <div style="" class="close-btn">
                                                    <div style="min-height:30px; background:#fff; border-radius:20px; display:flex; align-items:center; max-width:250px; justify-content:space-between; padding:0px 10px"  @click="sendUserToAtpDetailView(prod.atp_id)" class="mr-4 point-cursor">
                                                      <img :src="imgUrlFromServer+prod.atp_image" loading="lazy" height="20">


                                                        <p style="color:#4F4F4F">{{prod.atp}}</p>
 <img src="../../assets/images/info.png" loading="lazy" height="20">
                                                    </div>
                                                    <div style="min-height:30px; background:#fff; border-radius:20px; display:flex; align-items:center; max-width:250px; justify-content:space-between; padding:0px 10px" class="mr-4">
 <!-- <img :src="imgUrlFromServer+prod.insurer_image" loading="lazy" height="20"> -->


                                                        <p style="color:#4F4F4F">{{prod.insurer_name}}</p>
 <img src="../../assets/images/info.png" loading="lazy" height="20">
                                                    </div>
                                                        <img src="../../assets/images/close-exit.png" loading="lazy" height="25" style="cursor:pointer" class="close-info-div" @click.prevent="closeInfoDiv($event)">
                                                   </div>
                                         <div class="crt-div mt-4 mb-4">
                                                     <div style="display:flex;" >
                                                         <img src="../../assets/images/ui1.png" loading="lazy" height="15" class="mr-2">
                                                         <p v-if="prod.units_available < 1" class="user-roi  fw-600"> <i class="fa fa-percent add-curved-radius mr-4"></i> {{formatAmount(prod.roi_opened_with)}} % Interest</p>
                                          <p class="user-roi fw-600" v-else> {{formatAmount(prod.roi)}}% Interest</p>

                                               <div style="display: flex;
    justify-content: flex-end;">


       <input class="prod-url" type="hidden" :value="productUrl+'/market/'+`${prod.category}`+'/'+`${prod.urlName}`+'/'+`${prod.id}`" style="position:absolute">
                                                      
                                                        <img src="../../assets/images/share-new.png" loading="lazy" height="30" style="cursor:pointer" @click.prevent="copyProductUrl($event)" :content="tran.copied_to_clipboard" v-tippy="{trigger : 'click', hideOnClick : true, sticky : false}">
                                                                </div>
                                         
                                                    </div>

                                             <div style="display:flex;">
                                               <p class="text-left fw-600" v-if="prod.units_available < 1">
                                                    <img src="../../assets/images/ui2.png" loading="lazy" height="15" class="mr-2">{{prod.product_opened_with}}</p>
                                                                <p class="text-left fw-600" v-else>    <img src="../../assets/images/ui2.png" loading="lazy" height="15" class="mr-2">
                                                                   <span v-if="prod.numOfDays <=13">
                                                            {{prod.numOfDays + ' days'}}
                                                        </span>
                                                         <span v-if="prod.numOfDays >=  14 && prod.numOfDays <=  29">
                                                            {{floorNumb(prod.numOfDays) +  ' weeks'}}
                                                        </span>
                                                          <span v-if=" prod.numOfDays >  29">
                                                            {{prod.maturityInMonths}}
                                                        </span>
                                                                
                                                                
                                                              </p>
                                                 
<!-- 
                                              <p class="text-right fw-600"> <span>&#8358;</span>{{formatAmount(prod.cost_per_unit)}}</p> -->
                                                            </div>

                                          
                                                <div style="display:flex;">
                                                     <img src="../../assets/images/ui3.png" loading="lazy" height="15" class="mr-2">
                                                <p class="text-left fw-600"> <span>&#8358;</span>{{formatAmount(prod.cost_per_unit)}} per unit</p> 

                                            <!-- <p class="text-right fw-600" v-if="prod.units_available < 1">{{prod.product_opened_with}}</p>
                                                                <p class="text-right fw-600" v-else>{{prod.matures_at}}</p> -->

                                                            </div>
                                                            <div style="display:flex;" >

                                                                  <img src="../../assets/images/ui4.png" loading="lazy" height="15" class="mr-2">
                                                            
                                                                <p><span style="color:#828282">Opens On:</span>
                                                                <b> {{prod.opens_at | formatDate }}</b>
                                                                </p>

                                                            </div>
                                                            <div style="display:flex;" >

                                                                  <img src="../../assets/images/ui4.png" loading="lazy" height="15" class="mr-2">
                                                            
                                                                <p><span style="color:#828282">Matures On:</span>
                                                                <b> {{prod.matures_at | formatDate }}</b>
                                                                </p>
                                                              

                                                            </div>
                                                        </div>

                                                        <div class="add-units" :class="{'fade-elem': prod.units_available == 0, 'hide-elem':prod.category == 'upcoming' }">
                                                                 <div class="display-div pt-3" style=" padding:6px 15px; border-bottom: 1px solid #F2F2F2;">
                                                                <div class="text-left" >
                                                            <p class="pb-1" style="color:#828282
">Cost (Multiples of ₦5,000):</p>
                                                                   <span style="color:#0094FF">&#8358;</span>
                                                                    <span class="cost-unit text-center" style="color:#0094FF">   {{formatAmount(prod.user_cost)}}</span>

                                                                </div>
                                                                <div class="text-left" style="color:#58B46F">
                                                                        <p class="pb-1" style="color:#828282
">ROI (Return on Investment):</p>
                                                                    <span>&#8358;</span>
                                                                    <span class="user-amount text-center">{{formatAmount(prod.user_roi)}}</span>

                                                                </div>

                                                            </div>
                                                            <h6 class="pt-4 pb-2 text-left"><b>Add Units</b> <span style="color:#828282
">(One unit =  ₦5,000)</span></h6>

    <div style="padding:10px; display:flex; justify-content:space-between">

                                                            <div style="padding:0px 15px;display:flex; align-items:center; border: 0.5px solid #33A9FF; border-radius:5px; background:#F5F5F5; flex-basis:60%" class="mr-2" >
                                                        
                                                                <i class="fa fa-minus text-left" @click="getUserRoi($event, prod, 'minus')"  :class="{'fade-elem':prod.defaultUnitSelected < 2 }" style="flex-basis:10%"></i>

                                                                <input type="text" class="cart-input text-center" @keyup="checkUnitValue( $event, prod)" onfocus="this.setSelectionRange(this.value.length,this.value.length);" :value="prod.defaultUnitSelected" style="flex-basis:80%; background:transparent">

                                                                <i class="fa fa-plus text-right" @click="getUserRoi($event, prod, 'add')" :class="{'fade-elem': prod.defaultUnitSelected == prod.units_available }" style="flex-basis:10%"></i>

                                                            </div>
                                                       <button @click.prevent="addToCart(prod,$event)" class="cart-btn" :class="{'fade-elem': loader }" v-if="token !== null" style="flex-basis:30%">
                                            {{loader ? '' :  tran.add_tocart}}
                                            <span class="loader" v-if="loader"></span>
                                          </button>
                                                            <button class="cart-btn"  @click="sendGuestTosignUp" style="flex-basis:30%" v-else>
                                            {{  tran.sign_up_to_purchase}}
                                            <span class="loader" v-if="loader"></span>
                                          </button>    
    </div>
                                                       
                                                         
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="clear: left;border-top-left-radius: 20px;
  border-top-right-radius: 20px;"></div>
                                    </div>



<div style="clear:both; min-height:20px" v-if="windowWidth > 501 && secondaryMarket.length > 0 && activeMarketValue !==  'all'">

</div>



                                    <!-- Desktop View -->
                                    <!-- Sold Out and Paid Out Market -->
                                    <div v-show="windowWidth > 500" class="product-cover"  v-if="soldPaidOut.length > 0 && activeMarketValue ==  'agriculture'" >
                                        <div class="display-div pb-2">
                                            <div>
                                                <span @click="getCategoryArray('soldOut', soldPaidOut)" style="cursor:pointer"><b>{{tran.sold_out_paid}} </b></span> <span><i class="fa fa-angle-double-right"></i> </span>
                                            </div>
                                            <div class="text-right" style="cursor:pointer" v-if="soldPaidOut.length> 3">
                                                <span @click="getCategoryArray('soldOut', soldPaidOut)">
                                                         {{tran.see_all}}
                                                         </span>
                                                     <span class="pl-4 mr-4" v-if="soldPaidOut.length> 3">
                                                     <i class="fa fa-angle-double-right"></i>
                                               </span>
                                            </div>
                                        </div>

                                        <div v-for="(prod,index) in sliceData(soldPaidOut)" :key="prod.id+index+prod.name" style="display:inline; border-radius:10px">
                                            <div class="item" @click="getProductObject(prod, $event);  scrollToProduct (prod)">
                                                <div class="img-div-1" :class="{'showHideDiv': productSelectedByUser == prod.id}" style="position: absolute; z-index: 90;" @click.prevent="closeAllDivs($event)">
                                                </div>
                                                <div class="img-div" :style="{ backgroundImage: 'url(' +imgUrlFromServer+prod.product_image + ')' }">
                                                    <p class="scrollDiv"></p>

                                                    <template v-if="prod.units_available > 0 && prod.settlement_status !== 'settled'">

                                                        <p class="text-right" style="text-decoration:underline">
                                                            {{formatAmount(prod.units_available)}} <span v-if="prod.units_available < 2"> {{tran.unit_available}} </span> <span v-else>{{tran.units_available}}</span></p>

                                                    </template>

                                                    <template v-if="prod.units_available < 1 && prod.settlement_status !== 'settled'  || prod.resell_units_available < 1 && prod.settlement_status !== 'settled'">
                                                        <div class="sold-out">
                                                            <p>SOLD OUT</p>
                                                        </div>
                                                    </template>
                                                    <template v-if="prod.settlement_status == 'settled'">
                                                        <div class="paid-out">
                                                            <p>PAID OUT</p>
                                                        </div>
                                                    </template>
                                                    <div class="atp-preview">
                                                        <div>
                                                            <h6>
                                                                <b>{{prod.name}}</b>
                                                            </h6>
                                                            <p>{{prod.atp}}</p>
                                                        </div>
                                                        <div class="atp-roi">
                                                            <p><span v-if="prod.units_available < 1"> {{prod.roi_opened_with}} % </span> <span v-else> {{prod.roi}}% </span></p>
                                                            <p v-if="prod.units_available < 1">{{prod.product_opened_with}}</p>
                                                            <p v-else>
                                                                <span v-if="prod.numOfDays <=13">
                                                            {{prod.numOfDays + ' days'}}
                                                        </span>
                                                         <span v-if="prod.numOfDays >=  14 && prod.numOfDays <=  29">
                                                            {{floorNumb(prod.numOfDays) +  ' weeks'}}
                                                        </span>
                                                          <span v-if=" prod.numOfDays >  29">
                                                            {{prod.maturityInMonths}}
                                                        </span>
                                                                
                                                             </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style="position:relative;text-align:center; right:12px">

                                                    <img src="../../assets/images/chev-up.png" loading="lazy" class="fa-angle-down animated slideInDown mb-4"  :class="{'show-elem-inline' : prod.id == productSelectedByUser && selectedCategory == 'primary'}" style="margin-top:0.8rem!important" height="30">
                                                  </div>
                                                </div>

                                                <div class="info mb-3" :id="'product' + prod.id" :class="{'show-elem' : prod.id == productSelectedByUser && selectedCategory == 'primary'}" :ref="'product' + prod.id">




                                                    <div class="investment-details">
                                                       

                                                        <div class="product-detail mr-4">
                                                            <div>
                                                                <h3>{{prod.name}} </h3>
                                                             
                                                                <div v-html="prod.details"></div>

                                                            </div>
                                                        

                                                    
                                                        </div>

                                                        <div class="cart-point mt-4">
                                                               <div style="" class="close-btn">
                                                    <div style="min-height:30px; background:#fff; border-radius:20px; display:flex; align-items:center; max-width:250px; justify-content:space-between; padding:0px 10px"  @click="sendUserToAtpDetailView(prod.atp_id)" class="mr-4 point-cursor">
                                                      <img :src="imgUrlFromServer+prod.atp_image" loading="lazy" height="20">


                                                        <p style="color:#4F4F4F">{{prod.atp}}</p>
 <img src="../../assets/images/info.png" loading="lazy" height="20">
                                                    </div>
                                                    <div style="min-height:30px; background:#fff; border-radius:20px; display:flex; align-items:center; max-width:250px; justify-content:space-between; padding:0px 10px" class="mr-4">
 <!-- <img :src="imgUrlFromServer+prod.insurer_image" loading="lazy" height="20"> -->


                                                        <p style="color:#4F4F4F">{{prod.insurer_name}}</p>
 <img src="../../assets/images/info.png" loading="lazy" height="20">
                                                    </div>
                                                        <img src="../../assets/images/close-exit.png" loading="lazy" height="25" style="cursor:pointer" class="close-info-div" @click.prevent="closeInfoDiv($event)">
                                                   </div>
                                                                  <div class="crt-div mt-4 mb-4">
                                                     <div style="display:flex;" >
                                                         <img src="../../assets/images/ui1.png" loading="lazy" height="15" class="mr-2">
                                                         <p v-if="prod.units_available < 1" class="user-roi  fw-600">  {{formatAmount(prod.roi_opened_with)}} % Interest</p>
                                          <p class="user-roi fw-600" v-else> {{formatAmount(prod.roi)}}% Interest</p>

                                               <div style="display: flex;
    justify-content: flex-end;">


       <input class="prod-url" type="hidden" :value="productUrl+'/market/'+`${prod.category}`+'/'+`${prod.urlName}`+'/'+`${prod.id}`" style="position:absolute">
                                                      
                                                        <img src="../../assets/images/share-new.png" loading="lazy" height="30" style="cursor:pointer" @click.prevent="copyProductUrl($event)" :content="tran.copied_to_clipboard" v-tippy="{trigger : 'click', hideOnClick : true, sticky : false}">
                                                                </div>
                                         
                                                    </div>

                                             <div style="display:flex;">
                                               <p class="text-left fw-600" v-if="prod.units_available < 1">
                                                    <img src="../../assets/images/ui2.png" loading="lazy" height="15" class="mr-2">{{prod.product_opened_with}}</p>
                                                                <p class="text-left fw-600" v-else>    <img src="../../assets/images/ui2.png" loading="lazy" height="15" class="mr-2">
                                                                <span v-if="prod.numOfDays <=13">
                                                            {{prod.numOfDays + ' days'}}
                                                        </span>
                                                         <span v-if="prod.numOfDays >=  14 && prod.numOfDays <=  29">
                                                            {{floorNumb(prod.numOfDays) +  ' weeks'}}
                                                        </span>
                                                          <span v-if=" prod.numOfDays >  29">
                                                            {{prod.maturityInMonths}}
                                                        </span>
                                                                
                                                                </p>
                                                 
<!-- 
                                              <p class="text-right fw-600"> <span>&#8358;</span>{{formatAmount(prod.cost_per_unit)}}</p> -->
                                                            </div>

                                          
                                                <div style="display:flex;">
                                                     <img src="../../assets/images/ui3.png" loading="lazy" height="15" class="mr-2">
                                                <p class="text-left fw-600"> <span>&#8358;</span>{{formatAmount(prod.cost_per_unit)}} per unit</p> 

                                            <!-- <p class="text-right fw-600" v-if="prod.units_available < 1">{{prod.product_opened_with}}</p>
                                                                <p class="text-right fw-600" v-else>{{prod.matures_at}}</p> -->

                                                            </div>
                                                            <div style="display:flex;" >

                                                                  <img src="../../assets/images/ui4.png" loading="lazy" height="15" class="mr-2">
                                                            
                                                                <p><span style="color:#828282">Opens On:</span>
                                                                <b> {{prod.opens_at | formatDate }}</b>
                                                                </p>

                                                            </div>
                                                            <div style="display:flex;" >

                                                                  <img src="../../assets/images/ui4.png" loading="lazy" height="15" class="mr-2">
                                                            
                                                                <p><span style="color:#828282">Matures On:</span>
                                                                <b> {{prod.matures_at | formatDate }}</b>
                                                                </p>
                                                              

                                                            </div>
                                                        </div>
<!-- 
                                                                <div class="add-units" :class="{'fade-elem': prod.units_available == 0, 'hide-elem':prod.category == 'upcoming' }">
                                                                    <h6 class="pt-2 text-center"><b>{{tran.purchase}}</b></h6>
                                                                    <div style="padding:6px 15px; align-items:center; justify-content:center" class="display-div text-center">

                                                                        <i class="fa fa-minus text-left" @click="getUserRoi($event, prod, 'minus')"  :class="{'fade-elem':prod.defaultUnitSelected < 2 }"></i>

                                                                        <input type="text" class="cart-input text-center" @keyup="checkUnitValue( $event, prod)" onfocus="this.setSelectionRange(this.value.length,this.value.length);" :value="prod.defaultUnitSelected">

                                                                        <i class="fa fa-plus" @click="getUserRoi($event, prod, 'add')" :class="{'fade-elem': prod.defaultUnitSelected == prod.units_available }"></i>

                                                                    </div>
                                                                    <p class="unit-string fw-600 text-center" v-if="prod.defaultUnitSelected < 2"><b>{{tran.unit}}</b></p>
                                                                    <p class="unit-string fw-600  text-center" v-else><b>{{tran.units}}</b></p>
                                                                    <div class="display-div mt-2" style="border-top:0.5px solid #ccc; padding:6px 15px">
                                                                        <div class="text-center" style="border-right:0.5px solid #ccc;">
                                                                            <span>{{tran.cost}} : </span>
                                                                            <span>&#8358;</span>
                                                                            <span class="cost-unit text-center">{{formatAmount(prod.user_cost)}}</span>

                                                                        </div>
                                                                        <div class="text-center" style="color:#58B46F">
                                                                            <span>{{tran.roi}}: </span>
                                                                            <span>&#8358;</span>
                                                                            <span class="user-amount text-center">{{formatAmount(prod.user_roi)}}</span>

                                                                        </div>

                                                                    </div>
                                                                    <button @click.prevent="addToCart(prod,$event)" class="cart-btn" :class="{'fade-elem': loader }" v-if="token !== null">
                                            {{loader ? '' :  tran.add_tocart}}
                                            <span class="loader" v-if="loader"></span>
                                          </button>
                                                                    <button class="cart-btn"  @click="sendGuestTosignUp" v-else>
                                            {{  tran.sign_up_to_purchase}}
                                            <span class="loader" v-if="loader"></span>
                                          </button>
                                                                </div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style="clear: left;border-top-left-radius: 20px;
  border-top-right-radius: 20px;"></div>
                                            </div>

                </template>

                <template v-if="searchString !== ''">
                    <searchProduct />
                </template>

            </div>

        </div>

    </Wrapper>
    
</div>
</template>

<script>
import Navbar from "../partials/Navbar";
import Footer from "../partials/Footer";
import Sidebar from "../partials/Sidebar";
import Wrapper from "../partials/Wrapper";
import productDetail from "../partials/MobileProductDetail";
import searchProduct from "./SearchProduct";
import Slick from 'vue-slick';
import Tips from "../partials/Tips";
import http from "../../http/index";
import api from "../../apis/index";
import moment,{ locale } from "moment";
import {
    mapState
} from "vuex";

import {
    commonJs
} from './mixins/commonJs.js';
export default {
    mixins: [commonJs],
    data() {
        return {
            slickOptions: {
                slidesToShow: 3,
                slidesToScroll: 1,
                // autoplay: true,
                // autoplaySpeed: 2000,
                //dots: true,
                infinite: false,
                variableWidth: true,
              prevArrow: false,
    nextArrow: false

            },

            activeMarket:'agriculture',

            primaryMarketSlick: [],
            productSelected: this.$route.params.id,
            units: "",

            cartInput: 0,
            prodAdded: false,
            currentProductObjectId: null,
            selectedCategory: null,
            noProductOnthePlatform:{
                email:'',
            
            }
        };
    },
    methods: {

        sendToProfile() {
            this.$router.push('/profile')
        },

        saveActiveMarket(marketType){


this.activeMarket = marketType
        },

        getCategoryArray(categoryType, categoryData) {
    localStorage.removeItem('categoryData')


            localStorage.setItem('categoryType', categoryType)
            localStorage.setItem('categoryData', JSON.stringify(categoryData))


            this.$store.commit("market/saveCategoryType", categoryType)
           // this.$store.dispatch("market/getCategoryData")
            this.$helpers.log(categoryData, "category Data")
        },
 prepare_datetime (datetime, offset = "+01:00") {
            if (datetime.search(' ') >= 0) datetime = datetime.replace(' ', 'T');
            if (!datetime.substr(-1, 1) == 'Z' && datetime.search('+') >= 0) datetime += offset;
        
            return new Date(datetime);
        },
        scrollToProduct(prod) {
            this.$helpers.log(prod.id, 'id');
            this.selectedCategory = prod.category
            this.$router.push({
                params: {
                    id: prod.id
                },

                path: `/market/${ prod.category }/${prod.name.toLowerCase().split(' ').join('')}/${prod.id}`,
            })
            this.$store.commit('auth/saveRouteVisited', `/market/${ prod.category }/${prod.name.toLowerCase().split(' ').join('')}/${prod.id}`)
        },

        scrollToDiv() {
            let first = this.$route.fullPath.split("/");
            if (first[4] && this.windowWidth > 500) {

                try{

                    document.getElementById('product' + first[4].split("#")[0]).scrollIntoView();
                }

                catch{
                    
                }
                // let el = document.getElementById('product' + first[4].split("#")[0]);
            }
        },

        sliceAtpDetails(data) {

            data.slice(0, 40)
        },

        removeAddTocartActionForUser(categoryType) {

            this.$store.commit("market/saveCategoryArray", categoryType)

        },

        staySafe(){

let userHasSeenPrompt  = localStorage.getItem("staySafe")

if(userHasSeenPrompt !== 'true'){

    this.$toast.success({   
                title: '#StayHome, #StaySafe',
                timeOut: 125000,
                icon: require("../../assets/images/wash.png"),
                message: 'Wash your hands regularly. Practice social distancing.',
                position: "top center",
            
            });
}

 localStorage.setItem("staySafe", true)

        },
        sendToDollarView(prod){

  this.$store.commit("market/saveDollarBondProductId", prod.id);
            this.$router.push("/dollar/home")
            let dollarProduct = JSON.stringify(prod)
            localStorage.setItem('dollarProduct', dollarProduct)
        },

          sendNoProductFeedback() {
            let vm = this
              this.$store.commit("market/startPreloader");

            let apiData = {

                email: this.noProductOnthePlatform.email,
              
            }
            http.put(api.mailingList, apiData)
                .then(response => {
  this.$store.commit("market/stopPreloader");
                    this.$helpers.log(response);
                 
                  

                    this.$toast.success({
                        title: this.tran.success,
                        icon: require("../../assets/images/successicon.png"),
                        timeOut: 8000,
                        message: "Email Sent",
                        position: "top center"
                    });
                    this.noProductOnthePlatform.email = ''
                    this.noProductOnthePlatform.phone = ''

                })

                .catch(error => {

                  this.$store.commit("market/stopPreloader");
                    this.$helpers.log(error.response)

                    this.$toast.warn({
                        timeOut: 5000,
                        icon: require("../../assets/images/cautionicon.png"),
                        title: this.tran.sorry,
                        message: error.response.data.message,
                        position: "top center"
                    });
                })

        },
bookFund(){

    this.$router.push('/book')
},

    },

    computed: {
        ...mapState({

            primaryMarket: state => state.market.primaryMarket,
            secondaryMarket: state => state.market.secondaryMarket,

            soldPaidOut: state => state.market.soldPaidOut,
            resellMarket: state => state.market.resellMarket,
            resellProductData: state => state.market.resellProductData,
            productObject: state => state.market.productObject,
            userDetails: state => state.auth.userDetails,
            cartItems: state => state.market.cartItems,
            allProductsFromApi: state => state.market.allProductsFromApi,
            scrollStatus: state => state.market.scrollStatus,
            productsForMobile: state => state.market.productsForMobile,
            productDetailComponent: state => state.market.productDetailComponent,

        }),

        productSelectedByUser() {

            return this.$route.params.id
        },
        activeMarketValue() {

            return this.activeMarket
        },

        sliceResellMarket() {

            if (this.windowWidth > 1350) {

                return this.resellMarket.slice(0, 6);

            } else if (this.windowWidth < 1350) {

                return this.resellMarket.slice(0, 4);

            }

        },
       
    },
    components: {
        Navbar,
        productDetail,
        Sidebar,
        Wrapper,
        searchProduct,
        Slick,
        Tips,
        Footer
    },

    watch: {
        scrollStatus() {

            if (this.scrollStatus == true) {
                this.scrollToDiv();
            }
        }
    },

    deactivated(){
        this.$destroy()

    },

    mounted() {

        let heading = {
            main: this.tran.market,
            sub: this.tran.all_market
        }
            this.$store.dispatch('market/getAllProducts');

     this.staySafe()

        this.$store.commit('market/savePageHeading', heading)

    this.$store.dispatch("market/getEuroBondHistory");

        // get the url then save the category
        let first = this.$route.fullPath.split("/");

        // save the category into selectedCategory state
        this.selectedCategory = first[2];

        //get the product from the url and pass it to the mobile component
        if (first[2] && this.windowWidth < 501) {

            //    let categoryData = 
            let categoryData = null;
            http.get(api.getAllProducts)
                .then(response => {
                    categoryData = response.data.data.products.concat(response.data.data.resell_products)

                    let prodId = ''
                    if (first[4].indexOf('?')) {

                        prodId = first[4].split('?')[0];
                    } else {

                        prodId = first[4]

                    }

                    let prodArray = categoryData.filter((prod) => {

                        if (prod.id == prodId && prod.category == first[2]) {
                            let date1 = moment(prod.matures_at);
                            let date2 = "";
                            this.$helpers.log(prod, 'product stage')

                            if (prod.primary_closes_at !== null && prod.primary_closes_at > this.prepare_datetime(response.data.server_time)) {
                                date2 = moment(prod.primary_closes_at);
                            } else {
                                date2 = moment(this.prepare_datetime(response.data.server_time));
                            }

                         

                            prod.maturityInMonths = date1.diff(date2, "days");
 prod.numOfDays = date1.diff(date2, "days")
                            prod.unitIsLessThanAtp1Unit = false
                            prod.product_opened_with = moment(prod.maturityInMonths).diff(moment(prod.opens_at), "days")
                            prod.product_opened_with = Math.floor(prod.product_opened_with / 30) + " Month(s)"

                            // let divideDate = prod.matures_at / 30;

                            // prod.matures_at = divideDate.toFixed(1) + " Months";

                            // let divideDate = Math.round(prod.matures_at / 30);

                            //    prod.matures_at = divideDate + " Months";

                            let divideDate = prod.maturityInMonths / 30.4375;
                            if (prod.fixed_tenor !== '0.00' && divideDate > prod.fixed_tenor) {

                                 if(prod.fixed_tenor % 1 == 0.00){
                  prod.maturityInMonths = Math.round(prod.fixed_tenor) + " Month(s)";
                }

                else{
                  prod.maturityInMonths = prod.fixed_tenor + " Month(s)";
                }

                            } else {

                                if (divideDate % 1 > 0.30 && divideDate % 1 < 0.75) {

                                    divideDate = Math.floor(prod.maturityInMonths / 30.4375);

                                    if (divideDate < 2) {
                                        prod.maturityInMonths = divideDate + " Month";
                                    } else {

                                        prod.maturityInMonths = divideDate + " Month(s)";
                                    }
                                } else if ((divideDate % 1) >= 0.75) {
                                    divideDate = Math.round(prod.maturityInMonths / 30.4375);

                                    if (divideDate < 2) {

                                        prod.maturityInMonths = divideDate + '.5' + " Month(s)";
                                    } else {

                                        prod.maturityInMonths = divideDate + '.5' + " Month(s)";
                                    }

                                } else if ((divideDate % 1) <= 0.3) {

                                    divideDate = Math.floor(prod.maturityInMonths / 30.4375);
                                    if (divideDate < 2) {

                                        prod.maturityInMonths = divideDate + " Month";
                                    } else {
                                        prod.maturityInMonths = divideDate + " Month(s)";
                                    }
                                }
                            }
                            prod.defaultUnitSelected = Math.ceil(
                                prod.vendor_cost_per_unit / prod.cost_per_unit
                            );

                            if (prod.units_available <= prod.defaultUnitSelected || prod.resell_units_available <= prod.defaultUnitSelected) {
                                prod.defaultUnitSelected = prod.units_available ?
                                    prod.units_available :
                                    prod.resell_units_available;
                            }

                            let atpRate = prod.roi;

                            let totalAmountUserIsBuyingFor =
                                prod.defaultUnitSelected * prod.cost_per_unit;

                            let rateOfUser = atpRate * 0.01;
                            let finalAmount = rateOfUser * totalAmountUserIsBuyingFor;

                            prod.user_roi =
                                parseFloat(finalAmount) +
                                parseFloat(totalAmountUserIsBuyingFor);
                            prod.user_cost = parseFloat(totalAmountUserIsBuyingFor);

                            let unitBought = prod.defaultUnitSelected * prod.cost_per_unit;
                            if (prod.category == 'secondary' || unitBought < prod.vendor_cost_per_unit && prod.units_available > 0 && prod.category == 'primary') {

                                if (prod.roi > 24) {
                                    prod.roi = (prod.roi - response.data.data.roi_setting.less_atp_unit_gt24).toFixed(1);
                                } else if (prod.roi >= 15) {
                                    prod.roi = (prod.roi - response.data.data.roi_setting.less_atp_unit_gt15).toFixed(1);

                                } else if (prod.roi < 15) {
                                    prod.roi = (prod.roi - response.data.data.roi_setting.less_atp_unit_lt15).toFixed(1);
                                }
                                prod.unitIsLessThanAtp1Unit = true
                            }
                            return prod
                        }
                    })

                   try{

                       if (first[2] == 'upcoming') {
                           this.showMobileDetailComponent(prodArray[0], 'upcoming')
   
                       } else {
                           this.showMobileDetailComponent(prodArray[0], 'notUpcoming')
                       }
                   }
catch{
    
}
                })

        }

        if(this.userDetails.bonus !== null){

        $('#myModal').modal('show');

        }

    },

    beforeDestroy() {

        this.$store.commit("market/startIsFetching");
        this.$store.commit("market/startPreloader");
    },

};
</script>

<style scoped>
 .modal-footer {
     background: #FFFAF0 !important;
 }
 .modal-body {
    padding: 0rem 0em !important;
}

 .fa-angle-down {
     position: absolute;
     display: none;
     color: #000;
     top: 2px;
     font-size: 38px !important;
     z-index: -5;
     text-align: center;

 }

 .custom-modal {

     position: fixed;
     width: 100%;
     height: 100%;
     z-index: 1199;
     background: #f9f9f9;
     display: flex;
     align-items: center;
     justify-content: center;
 }


</style>
