import Vue from "vue";
import Router from "vue-router";
import store from "../store/index";
// import store from "../store/index";
const home = resolve => require(["@/views/HomePage"], resolve);
const cart = resolve => require(["@/views/CartPage"], resolve);
const wallet = resolve => require(["@/views/WalletPage"], resolve);

const searchProduct = resolve => require(["@/views/SearchProductPage"], resolve);
const generalMarket = resolve => require(["@/views/GenMarket"], resolve);
const categoryMarket = resolve => require(["@/views/CategoryMarketPage"], resolve);
const atpDetail = resolve => require(["@/views/AtpDetailPage"], resolve);
const portfolio = resolve => require(["@/views/PortfolioPage"], resolve);
const profile = resolve => require(["@/views/UserProfile"], resolve);
const profilePersonal = resolve => require(["@/views/UserProfilePersonal"], resolve);
const profileSecurity = resolve => require(["@/views/UserProfileSecurity"], resolve);

//Auth Views--------------------------------------------------------------------------------
const SignUp = resolve => require(["@/views/authentication/RegisterSignUp"], resolve);
const Login = resolve => require(["@/views/authentication/LoginView"], resolve);
const BeforeSetPassword = resolve => require(["@/views/authentication/BeforeSettingPasswordView"], resolve);
const BeforeUpdatingPin = resolve => require(["@/views/authentication/BeforeUpdatingPinView"], resolve);
const ForgotPassword = resolve => require(["@/views/authentication/ForgotPasswordView"], resolve);
const CreatePin = resolve => require(["@/views/authentication/CreatePinForUser"], resolve);
const SaveBvn = resolve => require(["@/views/authentication/SaveBvnView"], resolve);
const ChangePin = resolve => require(["@/views/authentication/ChangeUserPin"], resolve);
const ChangePassword = resolve => require(["@/views/authentication/ChangeUserPassword"], resolve);
const ResetPin = resolve => require(["@/views/authentication/ResetPinForUser"], resolve);
const SetPassword = resolve => require(["@/views/authentication/SetPasswordView"], resolve);
const ResetPassword = resolve => require(["@/views/authentication/ResetPasswordView"], resolve);
const verifySignUp = resolve => require(["@/views/authentication/VerifySignUpMail"], resolve);
const Faq = resolve => require(["@/views/help/FaqView"], resolve);
const DriftHelp = resolve => require(["@/views/help/DriftApp"], resolve);
const Terms = resolve => require(["@/views/help/TermsView"], resolve);
const Privacy = resolve => require(["@/views/help/PrivacyView"], resolve);
const Contact = resolve => require(["@/views/help/ContactView"], resolve);
const About = resolve => require(["@/views/help/AboutView"], resolve);
const VestHome = resolve => require(["@/views/vestLockViews/VestHome"], resolve);
const VestAmount = resolve => require(["@/views/vestLockViews/VestAmountView"], resolve);
const VestInitSum = resolve => require(["@/views/vestLockViews/VestInitSum"], resolve);
const VestFullSum = resolve => require(["@/views/vestLockViews/VestFullSum"], resolve);
const VestFinal = resolve => require(["@/views/vestLockViews/VestFinalView"], resolve);
const VestPort = resolve => require(["@/views/vestLockViews/VestPort"], resolve);
const VestExplainer = resolve => require(["@/views/help/ExplainerVestLock"], resolve);
const BookHistory = resolve => require(["@/views/bookingViews/BookingHistoryView"], resolve);
const BookActivity = resolve => require(["@/views/bookingViews/BookingActivityView"], resolve);
const BookingSuccess = resolve => require(["@/views/bookingViews/BookingSuccessView"], resolve);
const unBookSuccess = resolve => require(["@/views/bookingViews/UnBookSuccessView"], resolve);
const BookHome = resolve => require(["@/views/bookingViews/BookingHomeView"], resolve);
const BookHomeExplainer = resolve => require(["@/views/bookingViews/BookingExplainerView"], resolve);
const DollarHome = resolve => require(["@/views/dollarAssetView/DollarHomeView"], resolve);
const DollarInvest = resolve => require(["@/views/dollarAssetView/DollarInvestView"], resolve);
const DollarTenor = resolve => require(["@/views/dollarAssetView/DollarTenorView"], resolve);
const DollarInvestmentSummary = resolve => require(["@/views/dollarAssetView/DollarInvestmentSummaryView"], resolve);
const DollarSuccess = resolve => require(["@/views/dollarAssetView/DollarSuccessView"], resolve);

const Transfer = resolve => require(["@/views/authentication/TransferFunds"], resolve);


const Adtool = resolve => require(["@/views/AdToolPage"], resolve);
const ActivatorRegis = resolve => require(["@/views/authentication/ActivatorReg"], resolve);
const ActivStand = resolve => require(["@/views/authentication/ActivatorStandView"], resolve);
const WinSpin = resolve => require(["@/views/WinAndSpin"], resolve);
const FeatureView = resolve => require(["@/views/FeatureNotification"], resolve);
//----------------------------------------------------------------------------------------------------

Vue.use(Router);

const router = new Router({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },

  routes: [
    {
      path: "/",
      component: home,
      name:"home"
    },
    {
      path: "/profile",
      component: profile,
      name:"profile"
    },
    {
      path: "/profile/personal",
      component: profilePersonal,
    },
    {
      path: "/profile/security",
      component: profileSecurity,
    },
    {
      path: "/atp/:id/detail",
      component: atpDetail,
      name:"atpDetail"
    },
    {
      path: "/search",
      component: searchProduct,
      name:"searchProduct"
    },
    {
      path: "/wallet",
      component: wallet
    },
    {
      path: "/portfolio",
      component: portfolio
    },
    {
      path: "/tool",
      component: Adtool
    },
    {
      path: "/transfer/claim",
      component: Transfer,
      name:"transfer"
    },
    {
      path: "/cart",
      component: cart
    },
    {
      path: "/win",
      component: WinSpin
    },
    // {
    //   path: "/market",
    //   component: market
    // },
    {
      path: "/feature/view",
      component: FeatureView,
     
    },
    {
      path: "/:category/market",
      name:"categoryMarket",
      component: categoryMarket
    },

    {
      path: "/market/:category/:id",
      component: categoryMarket
    },

    {
      path: "/market",
      component: generalMarket,
    },
    {
      path: "/savebvn",
      component: SaveBvn,
    },
    {
      path: "/book",
      component: BookHome,
      name:'bookHomeTwo'
    },
    {
      path: "/book/explainer",
      component: BookHomeExplainer,
      name:'bookExplainer'
    },
    {
      path: "/book/amount",
      component: BookActivity,
      name:'bookFunds'
    },
    {
      path: "/book/success",
      component: BookingSuccess,
      name:'bookSuccess'
    },
    {
      path: "/unbook/success",
      component: unBookSuccess,
      name:'bookUnSuccess'
    },
    {
      path: "/book/history",
      component: BookHistory,
      name:'bookHistory'
    },
    {
      path: "/dollar/home",
      component: DollarHome,
      name:'dollarHome'
    },
    {
      path: "/dollar/invest",
      component: DollarInvest,
      name:'DollarInvest'
    },
    {
      path: "/dollar/tenor",
      component: DollarTenor,
      name:'DollarTenor'
    },
    {
      path: "/dollar/summary",
      component: DollarInvestmentSummary,
      name:'DollarInvestmentSummary'
    },
    {
      path: "/dollar/success",
      component: DollarSuccess,
      name:'DollarSuccess'
    },

    {
      path: "/vest/home",
      component: VestHome,
      name:'vestHome'
    },

    {
      path: "/vest/amount",
      component: VestAmount,
      name:'vestAmount'
    },

    {
      path: "/vest/init/sum",
      component: VestInitSum,
      name:'initSum'
    },
    {
      path: "/vest/summary",
      component: VestFullSum,
      name:'fullSum'
    },
    {
      path: "/vest/final",
      component: VestFinal,
      name:'finalView'
    },
    {
      path: "/vest/portfolio",
      component: VestPort,
      name:'vestPort'
    },
    {
      path: "/vest/explainer",
      component: VestExplainer,
      name:'VestExplainer'
    },

    {
      path: "/market/:category/:productName/:id",
      component: generalMarket
    },

    {
      path: "/signup",
      component: SignUp
    },

    {
      path: "/verify/signup",
      component: verifySignUp
    },

    {
      path: "/login",
      component: Login
    },

    {
      path: "/forgot/password",
      component: ForgotPassword
    },
    
    {
      path: "/reset/pin",
      component: ResetPin
    },
    {
      path: "/create/pin",
      component: CreatePin
    },
    {
      path: "/change/pin",
      component: ChangePin
    },
    {
      path: "/change/password",
      component: ChangePassword
    },
    {
      path: "/reset/pin",
      component: ResetPin
    },

    {
      path: "/set/password",
      component: SetPassword
    },
    {
      path: "/reset/password",
      component: ResetPassword
    },
    
    {
      path: "/before/set",
      component: BeforeSetPassword
    },
    {
      path: "/before/pinupdate",
      component: BeforeUpdatingPin
    },
    {
      path: "/activator",
      component: ActivatorRegis
    },
    {
      path: "/rank",
      component: ActivStand
    },
    {
      path: "/faq",
      component: Faq
    },
    {
      path: "/help/app",
      component: DriftHelp
    },
    {
      path: "/terms",
      component: Terms
    },
    {
      path: "/privacy",
      component: Privacy
    },
    {
      path: "/contact",
      component: Contact
    },
    {
      path: "/about",
      component: About
    },
    // {
    //   path: "/login",
    //   component: Login,
    //   beforeEnter: (to, from, next) => {
    //     let category = JSON.parse(localStorage.getItem("category"));
    //     if (category == undefined) {
    //       next();
    //     }  else if (categoryMap.hasOwnProperty(category)) {
    //       router.push({
    //         path: categoryMap[category]
    //       });
    //     } else {
    //       next();
    //     }
    //   }
    // },
    //Retail
    // {
    //   path: "/",
    //   component: Home
    // },
   
]
});
// router.beforeEach((to, from, next) => {
//   // If this isn't an initial page load.

  
//   // store.commit("market/startPreloader");
//   if (to.query.category !== undefined) {
//     // Start the route progress bar.
//     // alert('We Up')

//     next(`/market/${to.query.category}/${to.query.prodName}/${to.query.prodId}`)


//     localStorage.setItem("userQuery", JSON.stringify(to.query));

//   }
//  next()
// });

export default router;
