import Vue from "vue";
import Vuex from "vuex";
import api from "../../apis/index";
import http from "../../http/index";
import router from "../../router";
import helpers from "../../main";

Vue.use(Vuex);

const auth = {
  namespaced: true,

  state: {
    token: null,
    email: "",
    loader: false,
    transferActive:false,
    loginDetails: {
      token: null,
      username: null
    },

    signUp: {
      email: null,
      first_name: null,
      last_name: null,
      phone: null,
      password: null,
      token: null,
      ref:null
    },

    loginData: {
      email: null,
      password: null
    },

    updatePasswordData: {
      password: null,
      token: null
    },

    forgotPassword: {
      email: null
    },

    userProfileInfo: {
      balance: "",
      bank_account_name: '',
      bank_account_no: "",
      bank_name: "",
      virtual_account_name:'',
      virtual_account_num:'',
      virtual_bank_name:'',
      referral:[]
    },

    referraLength:'',
    referralTotalAmount:'',
    logOutStatus: false,

    bankNames: {},

    editProfileLoader: false,

    editBankDetailLoader: false,

    editProfile: false,

    editBankDetail: false,
    authenticateError: false,
    openNotification: false,
    notifyMessage: "",
    routeVisited: "/market",
    vestRouteVisited:"/vest/home",
    bookRouteVisited:"/book",
    vestRouteVisitedBeforePortfolio:"/vest/home",
    testimonialDataFromStore:{
      
      rating:'',
      testimony:''
    },
    userDetails:{

      bonus:null,
      userName:'',
    },

    transferDetails:{
      type: '',
      ul:'',
      ua:''
    },
    transferNotification:false,
    notificationMessageFromAuth:'',
    userIsNotAuth:false,
    currentRoute:'',
    transferProductId:'',
    bvnRedirectRoute:'',
  },

  mutations: {
    saveBvnRedirectRoute(state, payload){

state.bvnRedirectRoute = payload
    },
    saveTransferProductId(state, payload){

      state.transferProductId = payload
          },
    changeUserIsNotAuth(state){

state.userIsNotAuth = false
state.currentRoute = ''
    },
saveCurrentRoute(state, payload){

state.currentRoute = payload
},
    clearLoginData(state){
state.loginData = {
email:' ',
  password: ''

}
    },
    clearSignUpData(state){
state.signUp = {
  password: ''
}
    },
    saveReferraLength(state, payload){

    state.referraLength = payload.length

    state.referralTotalAmount = payload
    .map(item => item.referral_amount == null ? 0 : parseFloat(item.referral_amount))
    .reduce((prev, curr) => prev + curr, 0);
    },

    saveTransferDetails(state, payload){
state.transferActive = true
      state.transferDetails = payload
      },
      hideTransferActiveStatus(state){
        state.transferActive = true
            
              },
      emptyTransferDetails(state, payload){

        state.transferDetails = {

          type: '',
          ul:'',
          ua:''
        }
        },
  
    saveUserDetails(state, payload){

      state.userDetails.bonus = payload.bonus
      state.userDetails.userName = payload.username
    },
    saveSignUpQuery(state, payload) {
   
      state.signUp.ref = payload.ref;
      
     localStorage.setItem('refCode', payload.ref)
    },
    saveRefCode(state, payload) {
      localStorage.setItem('refCode', payload)
      state.signUp.ref = payload;
    },

    saveRouteVisited(state, payload) {
      state.routeVisited = payload;
    },
    saveVestRouteVisited(state, payload) {
      state.vestRouteVisited = payload;
    },
    saveBookRouteVisited(state, payload) {
      state.bookRouteVisited = payload;
    },
    resetBookRouteVisited(state) {
      state.bookRouteVisited = "/book";
    },
    saveVestRouteVisitedAfterClickingPortfolio(state, payload) {
      state.vestRouteVisitedBeforePortfolio = payload;
    },

    saveAccountName(state, payload) {
      state.userProfileInfo.bank_account_name = payload;
    },
    startEditProfile(state) {
      state.editProfile = true;
    },

    stopEditProfile(state) {
      state.editProfile = false;
    },
    startEditBankDetail(state) {
      state.editBankDetail = true;
    },
    stopEditBankDetail(state) {
      state.editBankDetail = false;
    },
    startEditProfileLoader(state) {
      state.editProfileLoader = true;
    },
    stopEditProfileLoader(state) {
      state.editProfileLoader = false;
    },
    stopEditBankDetailLoader(state) {
      state.editBankDetailLoader = false;
    },
    startEditBankDetailLoader(state) {
      state.editBankDetailLoader = true;
    },
    saveBankNames(state, payload) {
      state.bankNames = payload.data.data.banks;
    },
    saveUserProfile(state, payload) {
      state.userProfileInfo = payload;
    },
    saveVirtualAccount(state, payload) {
      state.userProfileInfo.virtual_account_name = payload.account_name;
      state.userProfileInfo.virtual_account_num  = payload.account_number;
      state.userProfileInfo.virtual_bank_name  = payload.bank_name;
    },

    saveUserToken(state, payload) {
      state.token = payload;
    },
    removeUserToken(state,payload) {
      state.token = null;
      localStorage.removeItem("token");
      state.userIsNotAuth = payload
      router.push(state.routeVisited);
    },

    startLoader(state) {
      state.loader = true;
    },
    opentransferNotification(state) {
      state.transferNotification = true;
    },

    saveNotificationMessage(state, payload) {
      state.notificationMessageFromAuth = payload;
    },
    closetransferNotification(state) {
      state.transferNotification = false;

    },
    startNotification(state) {
      state.openNotification = true;
    },

    stoptNotification(state) {
      state.openNotification = false;
    },

    saveNotifyMessage(state, payload) {
      state.notifyMesage = payload;
    },

    stopLoader(state) {
      state.loader = false;
    },

    showAuthenticatedError(state) {
      state.authenticateError = true;
    },

    hideAuthenticatedError(state) {
      state.authenticateError = false;
    },
    saveTestimonialData(state, payload) {
      state.testimonialDataFromStore = payload;
    }
  },

  actions: {
    registerUser({ state, commit },payload) {

      state.signUp.phone = payload
      localStorage.setItem("userEmail", state.signUp.email);
      commit("startLoader");

      try{
        if(state.signUp.ref == null || state.signUp.ref.length < 4){
          // delete the ref key if a user fails to input anything
          delete state.signUp.ref
        }
      }

      catch{

      }


      http
        .post(api.signup, state.signUp)
        .then(response => {
          commit("stopLoader");
          helpers.log(response);



          if (response.status == 200) {
            router.push("/verify/signup");
          }
        })
        .catch(error => {
          if (error.response) commit("stopLoader");
          helpers.log(error.response);
          if (error.response.data.message.toLowerCase() == "account is new") {
            router.push("/verify/signup");
          }
          if (error.response.data.message.toLowerCase() !== "account is new") {
            commit("domElements/openNotificationMessage", null, { root: true });

            commit(
              "domElements/saveNotificationMessage",
              error.response.data.message,
              { root: true }
            );
          }
        });
    },

    verifySignUpToken({ state, commit,dispatch }) {
      let apiData = {
        token: state.signUp.token
      };
      localStorage.setItem('redirectTomarket', true)
      commit("saveBvnRedirectRoute", "market");
      commit("startLoader");
      http
        .post(api.verifyToken, apiData)
        .then(response => {
          commit("saveUserToken", response.data.data.token);
          commit("saveUserDetails", response.data.data);
          localStorage.setItem("token", response.data.data.token);
           commit("hideTransferActiveStatus");
          let driftStatus = true;

          localStorage.setItem("driftStatus", driftStatus);

          helpers.log(response.data.data.token, "user token");
          helpers.log(response);
          commit("saveUserProfile", response.data.data);
      

       
          if(state.transferDetails.type == ''){
            commit("stopLoader");
           
            router.push("/savebvn");
            
          }
          
          
          else if(state.transferDetails.type !== ''){
            dispatch("sendTransferDetails", state.transferDetails);
          
          }
          
          else{
            commit("stopLoader");
            router.push("/savebvn");
          
          }


          try{
                  //facebook pixel
                  fbq("track", "CompleteRegistration");

                  // Google Ads tracker
                  gtag("event", "conversion", {
                    send_to: "AW-703278616/uLvZCNOV9rMBEJjcrM8C"
                  });
          }

          catch{

          }
          
        })

        .catch(error => {
          if (error.response) commit("stopLoader");
          commit("domElements/openNotificationMessage", null, { root: true });

          commit("domElements/saveNotificationMessage",error.response.data.message,
            { root: true });
          helpers.log(error.response);
        });
    },

    resendVerificationCode({ commit }) {
      let userEmail = localStorage.getItem("userEmail");

      let apiData = {
        email: userEmail
      };

      commit("startLoader");
      http
        .put(api.resendToken, apiData)

        .then(response => {
          commit("stopLoader");
          commit("startNotification");
          helpers.log(response);
        })

        .catch(error => {
          if (error.response) commit("stopLoader");
          commit("domElements/openNotificationMessage", null, { root: true });

          commit(
            "domElements/saveNotificationMessage",
            error.response.data.message,
            { root: true }
          );
          helpers.log(error.message);
        });
    },

    sendForgotPassword({ state, commit }) {
      localStorage.setItem("forgotEmail", state.forgotPassword.email);
      let apiData = {
        email: state.forgotPassword.email
      };

      commit("startLoader");
      http
        .post(api.forgotPassword, apiData)

        .then(response => {
          commit("stopLoader");

          if (response.status == 200) {
            router.push("/reset/password");
          }
        })

        .catch(error => {
          if (error.response) commit("stopLoader");
          commit("domElements/openNotificationMessage", null, { root: true });

          commit(
            "domElements/saveNotificationMessage",
            error.response.data.message,  
            { root: true }
          );
          helpers.log(error.response);
        });
    },

    updatePassword({ state, commit }) {
      let userEmail = localStorage.getItem("forgotEmail");
      state.updatePasswordData.email = userEmail;

      commit("startLoader");
      http
        .post(api.updatePasssword, state.updatePasswordData)

        .then(response => {
          commit("stopLoader");

          if (response.status == 200) {
            router.push("/login");
          }
        })

        .catch(error => {
          if (error.response) commit("stopLoader");
          commit("domElements/openNotificationMessage", null, { root: true });

          commit(
            "domElements/saveNotificationMessage",
            error.response.data.message,
            { root: true }
          );
          helpers.log(error.response);
        });
    },
    sendTransferDetails({ state, commit, dispatch }, payload) {
    
      http
        .post(api.transferFundsToRecipient, payload)

        .then(response => {
         if(state.transferDetails.type == 'fund'){
          let message  = 'N'+response.data.data.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+' has been transfered to you'
          commit("saveNotificationMessage", message);
          commit("opentransferNotification")
            router.push('/wallet')
            commit("stopLoader"); 
          }
          
          else if(state.transferDetails.type == 'trf'){
        let message  = 'A new investment has been transfered to you'
  commit("saveNotificationMessage", message);
  commit("opentransferNotification")
  commit("saveTransferProductId", response.data.data.transaction_id);
            router.push('/portfolio')
            commit("stopLoader");
          }
          helpers.log(response, 'transferDetails');
        })

        .catch(error => {
        
      
try{
              commit("domElements/openNotificationMessage", null, { root: true });

          commit("domElements/saveNotificationMessage",
                error.response.data.message,
                { root: true }
            );
            if(state.transferDetails.type == 'fund'){
              commit("stopLoader");
                router.push('/wallet')
              }
              
              else if(state.transferDetails.type == 'trf'){
                commit("stopLoader");
                router.push('/portfolio')
          }
}

catch{

}
              
              
        });
    },
    loginUser({ state, commit, dispatch }) {
      commit("startLoader");
      http
        .post(api.login, state.loginData)

        .then(response => {
          helpers.log(response);
          commit("saveUserToken", response.data.data.token);
          localStorage.setItem("token", response.data.data.token);
         

          state.transferActive = true
          commit("hideAuthenticatedError");
          commit("hideTransferActiveStatus");
         // commit("clearLoginData");

          let driftStatus = true;

          dispatch("getUserProfile");
          localStorage.setItem("driftStatus", driftStatus);

if(state.transferDetails.type == ''){
  commit("stopLoader");

if(state.userIsNotAuth !== false && state.currentRoute !== ''){
  commit("hideTransferActiveStatus");
  router.push(state.currentRoute);
  commit("changeUserIsNotAuth")
}
else{
  router.push("/market");
  commit("hideTransferActiveStatus");
}

}



else if(state.transferDetails.type !== ''){

  dispatch("sendTransferDetails", state.transferDetails);

}



else{
  router.push("/market");

}
     
        })

        .catch(error => {
          if (error.response) {
            if (error.response.data.message.toLowerCase() == "account is new") {
              router.push("/verify/signup");
            }
            if (
              error.response.data.message.toLowerCase() !== "account is new"
            ) {
              commit("domElements/openNotificationMessage", null, {
                root: true
              });

              commit(
                "domElements/saveNotificationMessage",
                error.response.data.message,
                { root: true }
              );
            }
            helpers.log(error.response);
          }

          commit("stopLoader");
        });
    },

    logoutUser({ state, commit,dispatch }) {
      commit("startLoader");
      localStorage.removeItem("token");

      http.get(api.logout)

        .then(response => {
          helpers.log(response);
          commit("stopLoader");
          commit("emptyTransferDetails");
          commit("changeUserIsNotAuth")
          commit("hideTransferActiveStatus");
          commit("resetBookRouteVisited");
          dispatch("getUserProfile")
          state.userProfileInfo = [];
          commit("market/emptyCartItems", null, { root: true });
          localStorage.removeItem("userQuery");
          let driftStatus = false;
          localStorage.setItem("driftStatus", driftStatus);
        })

        .catch(error => { 
          dispatch("getUserProfile")
          if (error.response) commit("stopLoader");
          commit("market/emptyCartItems", null, { root: true });
          if (state.logOutStatus == false) {
            state.logOutStatus == true;
         //   commit("domElements/openNotificationMessage", null, { root: true });

            // commit(
            //   "domElements/saveNotificationMessage",
            //   error.response.data.message,
            //   { root: true }
            // );
            helpers.log(error.response);
          }
          let driftStatus = false;
          localStorage.setItem("driftStatus", driftStatus);
        });
    },


    getUserProfile({ commit }) {
      commit("startLoader");
      http.get(api.getUserProfile)

        .then(response => {
           commit("saveUserProfile", response.data.data);
          helpers.log(response, "userprofile");

          let driftUserDetails = {
            email: response.data.data.email,
            nickName: response.data.data.email.first_name
          };

          localStorage.setItem(
            "driftUserDetails",
            JSON.stringify(driftUserDetails)
          );


          commit("stopLoader");


          commit("saveReferraLength", response.data.data.referral);
        })

        .catch(error => {
          try{
            if (error.response) {
              if (
               
                 error.response.status !== 401
               ) {
                helpers.log(error.response);
            commit("stopLoader");
            commit("domElements/openNotificationMessage", null, { root: true });

            commit(
              "domElements/saveNotificationMessage",
              error.response.data.message,
              { root: true }
            );
          }
        }
        }
catch{

}
        });

    },
    getBankList({ commit }) {
      http
        .get(api.getAllBanks)

        .then(response => {
          commit("saveBankNames", response);
          helpers.log(response, 'bank Names');
        })

        .catch(error => {
          if (
           
            error.response.status !== 401
          ) {


            commit("domElements/openNotificationMessage", null, { root: true });

            commit(
              "domElements/saveNotificationMessage",
              "please login or signup to continue",
              { root: true }
            );
          }
        });
    },

    updateUserProfile({ commit, dispatch }, payload) {
      commit("startEditProfileLoader");
      http
        .patch(api.getUserProfile, payload)
        .then(response => {
          commit("stopEditProfileLoader");
          commit("stopEditProfile");
          dispatch("getUserProfile");
          commit("market/openNotification", null, { root: true });
          commit("market/saveNotifyMessage", "User Profile updated", {
            root: true
          });
          helpers.log(response);
        })

        .catch(error => {
          if (
   
            error.response.status !== 401
          ) {
            commit("stopEditProfileLoader");
            commit("domElements/openNotificationMessage", null, { root: true });

            commit("domElements/saveNotificationMessage",
              error.response.data.message,
              { root: true }
            );
            commit("stopEditProfile");
          }
        });
    },
    updateUserBankDetail({ commit, dispatch }, payload) {
      commit("startEditBankDetailLoader");
      http
        .patch(api.getAllBanks, payload)

        .then(response => {
          commit("stopEditBankDetailLoader");

          helpers.log(response, 'update bank details')
          //facebook pixel
          fbq("track", "AddPaymentInfo");

          commit("stopEditProfileLoader");
          commit("stopEditBankDetail");
          dispatch("getUserProfile");
          commit("market/openNotification", null, { root: true });
          commit("market/saveNotifyMessage", "Bank Details updated", {
            root: true
          });
          helpers.log(response);
        })

        .catch(error => {
          if (
          
            error.response.status !== 401
          ) {

            helpers.log(error.response, 'update bank details')
            commit("domElements/openNotificationMessage", null, { root: true });

            commit(
              "domElements/saveNotificationMessage",
              error.response.data.message,
              { root: true }
            );
            commit("stopEditBankDetailLoader");
            // commit("stopEditBankDetail");
          }
        });
    },

    
    validateRegToken({ state, commit, rootState }) {
      http
        .get(api.getTokenValidity + state.registerToken)
        .then(response => {
          helpers.log(response);
          helpers.log(response.status);
          commit("saveRegisterMail", response); //save user registration e-mail

          if (response.status == 200) {
            router.push("/set/password");
          }
        })
        .catch(error => {
          commit(
            "setRegisterBPassError",
            "sorry this link is invalid or has expired"
          );
        });
    },
    submitTestimonial({ state, commit, rootState }, payload) {
      commit("startLoader");
      http
        .post(api.testimonial , payload)
        .then(response => {
          helpers.log(response);
         
          commit("stopLoader");
          commit("market/openNotification", null, { root: true });

          commit(
            "market/saveNotifyMessage",
            "Thanks for sharing. You’re helping grow a healthy investment culture.",
            { root: true }
          );
        
        })
        .catch(error => {
          helpers.log(error.response);
          commit("stopLoader");
          commit("domElements/openNotificationMessage", null, { root: true });

          commit(
            "domElements/saveNotificationMessage",
            error.response.data.message,
            { root: true }
          );
        });
    },
    getTestimonial({ state, commit, rootState }, payload) {

      http
        .get(api.testimonial , payload)
        .then(response => {
          helpers.log(response, 'testimonial');
    if(response.data.data !== undefined){

      commit(
        "saveTestimonialData", response.data.data)

    }

          //  helpers.log(response.data.data, 'testimonia2');
        })
        .catch(error => {
          helpers.log(error.response);

        })
    },
    submitPin({ state, commit, rootState }, payload) {
      commit("startLoader");
      http
        .post(api.pin , payload)
        .then(response => {
          helpers.log(response);
         
          commit("stopLoader");
          commit("market/openNotification", null, { root: true });

          commit(
            "market/saveNotifyMessage",
            "Pin created successfully",
            { root: true }
          );
          router.push('/profile')
        })
        .catch(error => {
          commit("stopLoader");
          helpers.log(error.response);
          commit("domElements/openNotificationMessage", null, { root: true });

          commit(
            "domElements/saveNotificationMessage",
            error.response.data.message,
            { root: true }
          );
        });
    },
    // updatePin({ state, commit, rootState }, payload) {
    //   commit("startLoader");
    //   http
    //     .patch(api.pin , payload)
    //     .then(response => {
    //       helpers.log(response);
         
    //       commit("stopLoader");
    //       commit("market/openNotification", null, { root: true });

    //       commit(
    //         "market/saveNotifyMessage",
    //         "Pin changed successfully",
    //         { root: true }
    //       );

    //     router.push('/profile')
    //     })
    //     .catch(error => {
    //       commit("stopLoader");
    //       helpers.log(error.response);
    //       commit("domElements/openNotificationMessage", null, { root: true });

    //       commit(
    //         "domElements/saveNotificationMessage",
    //         error.response.data.message,
    //         { root: true }
    //       );
    //     });
    // },
  }
};

export default auth;
