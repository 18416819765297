<template>
<div id="wrapper" >
    <slot name="sidebar">
    </slot>
    <div id="content-wrapper" class="d-flex flex-column">
    <div id="content">
        <slot name="navbar">
        </slot>
        <!-- Begin Page Content -->
        <div class="content-passed">
            <slot name="content">
            </slot>
        </div>
    </div>
        <!-- /.container-fluid -->
        <slot name="footer">
        </slot>
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>
