import axios from "axios";
import store from "../store/index";
import router from "../router/index";

const httpClient = axios.create({

  baseURL: "https://market-api.requid.com/",
  
  headers: {
    Accept: "application/json",
    "X-Requested-With": "XMLHttpRequest",
    callType: "web",
  },

});

let token = localStorage.getItem("token");

store.commit("auth/saveUserToken", token);

httpClient.interceptors.request.use(
  (config) => {
    config.headers.authorization =
      store.state.auth.token !== null
        ? "Bearer " + store.state.auth.token
        : "Bearer " + token;

    let baseUrl = config.baseURL;

    localStorage.setItem("baseUrl", baseUrl);
    return config;
  },

  (error) => {}
);

httpClient.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },

  function(error) {
    if (
      error.response.data.msg &&  error.response.data.msg.toLowerCase()  == "unauthenticated" ||
      error.response.status == 401
    ) {
      if (router.currentRoute.fullPath !== "/login") {
        store.commit("auth/removeUserToken", router.currentRoute.fullPath);
      }
    }
    // swal("error",error.response.data.message, "error");
    return Promise.reject(error);
  }
);

export default httpClient;
