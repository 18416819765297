const trans = {
  abt_req:"About Requid",
  and:"and",
  action:"Actions",
  answer:"Answer",
  agro_insurer:"AGRO INSURANCE VERIFIER",
  added_tocart:" added to cart",
  add_tocart:"Add to cart",
  alert_noti:"Alerts, Notifications and Service Communications",
  alert_noti_details:"By creating a User Account, you automatically sign up for various types of alerts via email and mobile notification. We never include your password in these communications, but we may include your name, phone number or email address and information about your portfolio(s) if you are a Client. Anyone with access to your email or mobile device will be able to view these alerts. You may unsubscribe from marketing oriented emails at any time.",
  all_items_in_cart:"Items in your cart",
  all_invest_bought:"investments bought",
  all_market:"Products & Categories",
  acc_invest_cost:"Accumulated Investment Cost",
  acc_name:"Account Name : ",
  acc_number:"Account Number : ",
  accrued_interest:"Accrued Interest",
  accept:"Accept ",
  avail_unit:"Available Unit(s)",
  approx_ret:"Approximate Return",
  amount:"Amount",
  amount_to_fund:"Amount to fund wallet with",
  amount_to_trans:"Amount to transfer",
  balance:"Balance",
  background:"Background",
  bvn:"BVN : ",
  basic_info:"Basic info",
  background_detail:"In providing our array of services to you our esteemed client, Requid Technologies Limited (“Requid”) may obtain your personal information which enables us deliver our products to you through Service Excellence, one of the main canons of our business values. As our esteemed client, we are committed to protecting the privacy of your personal data as confidential information at all times to the best of our abilities as much as we protect our own corporate information. We therefore set out our Privacy Policy (“Policy”) which should be read at all times in conjunction with the Terms and Conditions of use of our online platforms (includes our website accessible at requid.com and its subdomains, as well as our mobile apps). This Policy establishes the guidelines for accessing and usage of the Requid platforms. It also details our mode of handling your personal information received through our platforms, in terms of the permitted purposes, and circumstances under which they may be shared with third parties. Please note that Requid, in this Privacy Policy refers to the Requid Technologies Ltd and its products.",
  bank:"Bank : ",
  booking:"Booking ",
  
  bank_transfer:"Bank Transfer Details",
  bank_details:"Payout Details",
  buy_products:"Go to Marketplace",
  buy_more:"Buy More",
  change_prod:"Change Product",
  change_pass:"Change Password",
  cart: "Cart",
  continue: "Continue",
  copy_ref: "Copy Referral link",
  contact_support: "Contact Support",
  congrat: "Congratulations",
  capital: "Capital",
  capital_will_be: "Capital will be paid after sale, with a 3% penalty deduction. Interest will be paid at end of tenor.",
  capital_will_be_paid_euro: "Capital will be paid after liquidation, with a 1.5% penalty deduction on your interest.",
  chat_us: "Chat Us",
  cancel:"Cancel",
  collecting: "COLLECTING AND USING INFORMATION",
  copyright: "Copyright",
  copyright_a: "a. All content on this platform, and all content of any documents provided to visitors or clients (in, for instance, newsletters) is the property of the Organization, unless stated otherwise.",
  copyright_b: "b. No user may copy, modify, publish, transmit, transfer or sell, reproduce, create derivative works from, distribute, display, or in any way exploit any of the content, in whole or in part, except with the express written agreement of the Organization.",
  copy_id_making_payment:"Comment this Reference while making payment.", 
  credit_debit: "Credit & debit wallet",
  code: "Code",
  contact_sup: "Contact Support",
  copy_prod_url: "Copy & Share link",
  cost: "Cost",
  coming_soon: "Coming Soon",
  complete_transfer: "Complete transfer",
  confirm_payment: "Confirm Payment",
  confirm_sale: "Confirm Sale",
  cost_of_item: "Cost of item",
  cost_of_item_in_cart: "Cost of items in cart",
  cost_per_unit: "Cost per unit",
  copy_to_clipboard:"Copy to clipboard",
  copied_to_clipboard:"Link copied to clipboard. You can share this link with your friends.",
ref_to_clipboard:"Referral Link copied to clipboard. You can share this link with your friends.",
  clickHere: "Click here",
  customer_service: "Customer Service",
  customer_service_number: "+234 1 453 7990",
  createAcc: "Create Account",
  createPin: "Create Pin",
  changePin: "Change Pin",
  current_value: "Current Value",
  current_rate: "New Rate: ",
  checkTheEmail: "Check the email address below for a reset password link",
  change_quant: "Change quantity",
  date: "Date",
  dob:"Date of birth",
  docu_upload:"Document Uploads",
  downloading: "Downloading",
  downloading_detail: "Please note that any software downloaded from this platform is at your own risk and the organization neither assumes nor accepts liability for any loss, damage (whether direct or indirect) howsoever caused, as a result of any computer viruses, ‘Trojan Horses’, ‘Worms’, software ‘bombs’ or similar items or processes.",
  done: "Done",
  disclaimer: "Disclaimer of Liability",
  disclaimer_detail_a: "a. Every effort is made to provide useful, accurate and complete information. However, we cannot guarantee that there are no errors. The Organization makes no claims, promises or guarantees about the accuracy, completeness, usefulness or adequacy of the contents of this platform and expressly disclaims liability of any sort for errors and omissions in the contents of this platform.",
  disclaimer_detail_b: "b. Neither the Organization, nor its employees, associates or contractors make any warranty expressed, implied or statutory, including, but not limited to, any warranty that third party rights or title have not been infringed, or any warranty of merchantability or fitness for any particular purpose.",
  disclaimer_detail_c: " c. No warranty of any sort is made with respect to the content of third party platform that have links from this platform and all liability of every sort is expressly disclaimed.",
  disclaimer_detail_d: " d. The Organization is not liable in any circumstances for special, indirect or consequential damages or any damages whatsoever resulting from loss of use, loss of data or loss of revenues or profits, whether in an action of contract, negligence or otherwise, arising out of or in connection with any use of the information or services available on this platform.",
  date_of_purchase: "Date of Purchase",
  desclaimer_endorse:"Disclaimer of Endorsement",
  desclaimer_endorse_a:"a. This site, and documents posted on it, may contain hypertext links or pointers to information created and maintained by other public and private organizations. The Organization does not guarantee the accuracy, relevance, usefulness, timeliness, or completeness of any linked information.",
  desclaimer_endorse_b:"b. The inclusion of links or pointers to other platform is not intended to assign importance to those platforms and the information contained in them, nor is it intended to endorse, recommend, or favor any views expressed, or commercial products or services offered on those platforms, or the organizations sponsoring the platforms, by trade name, trademark, manufacture, or otherwise.",
  desclaimer_endorse_c:"c. Reference on this platform to any specific commercial products, processes, or services, or the use of any trade, firm or corporation name is for the information and convenience of the platform’s visitors, and does not constitute an endorsement, recommendation, or favoring by the Organization. ",
  desclaimer_endorse_d:"d. The views expressed by any individual within the platform forum or blog are the views of that individual only and do not reflect or represent in any way the views of the Organization.",
  due_to_monify:"There is a payment processing fee of N50 for instant transfers. So you'll be making a payment of:",
  detail: "Details",
  email: "Email",
  euro_bond: "Euro Bond",
  explainer_vest: "Vestlock",
  enter_recepient: "Enter recipient’s email ",
  explainer: "explainer",
  expect_payment:"Expect payment within 1 to 2 working days",
  edit: "Edit",
  edit_det: "Edit details",
  end_vestLock: "Are you sure you want to cancel this transaction",
  error: "Error",
  expect_roi:"Expected ROI",
  emailVeri: "Email Verification",
  empty_cart: "You have not added any investments to your cart",
  empty_market: "Sorry, there are no available investments now. We will notify you via email when there are.",
  enterEmail: "Please provide the email you signed up with",
  enterCode: "Enter the code sent to your email or phone",
  faq: "FAQs",
  forgotPassword: "Forgot Password? ",
  firstName: "First Name",
  fullName: "Full Name",
  fund_wallet: "Fund Wallet",
  fund_wallet_to_purchase: "Fund Wallet to purchase",
  fund_with:"Fund with",
  fund_with_card:"Fund with Card",
  fund_with_bank:"Fund with Bank Transfer",
  fund_your_wallet_with:"Fund your wallet with a minimum of",
  go_back:"Go back",
  gender:"Gender",
  governing:"Governing Law",
  governing_details:"These terms shall be governed by and construed in accordance with the laws of the Federal Republic of Nigeria and the user explicitly accepts that only the law courts of the Federal Republic of Nigeria have jurisdiction to deal with any matter arising from or in any way, whether directly or indirectly, related to the use of this platform and, accordingly, the user explicitly waives all and any rights to bring any action of any sort in relation to this platform in any court anywhere else in the world.",
  go_back_to:"Go back to edit amount",

  // get_1:"Get 1% of the first investment your referred person makes",
  get_1:"",
  has_gifted:"has gifted you with ₦1,000.00 . Start investing and bring others onboard to get something special.",
  history_of:"History of debit, credit and withdrawn monies",
  help_supp:"Help & Support",
  how_personal:"How Personal Information Is Shared/Disclosed",
  how_personal_detail:"Requid will not disclose your Personal Information to anyone outside Requid Technologies Ltd without your permission unless:",
  how_personal_detail_a:"a. We are compelled by law, regulatory authority or a court order to do so;",
  how_personal_detail_b:"b. We are satisfied that it is in the interest of the public to do so; ",
  how_personal_detail_c:"c. We are convinced that it is necessary to protect our rights and interests.",
  how_we_use:"How We Use Personal Information ",
  how_we_use_detail:"Requid may use your Personal Information in any of the following ways:",
  how_we_use_detail_a:"a. Inform you about important information regarding our platforms, our products and services which you may be interested in, or in which you are already enrolled, changes to the terms, conditions, and policies and/or other administrative information; as well as to respond to your inquiries and fulfil your requests;",
  how_we_use_detail_b:"b. To allow you make use of some other platform or tools such as financial planning tools. Information that you enter into one of these tools may be stored for future access and use. You have the option not to save the information;",
  how_we_use_detail_c:"c. To consolidate your financial account information onto a single platform; understand what product or service may be of interest to you; and present you with offers;",
  how_we_use_detail_d:"d. For business purposes, including data analysis, audits, developing and improving products and services, enhancing the platforms, identifying usage trends and determining the effectiveness of promotional campaigns;",
  how_we_use_detail_e:" e. For risk control, fraud detection and prevention, to comply with laws and regulations, and to comply with other legal process and law enforcement requirements;",
  how_we_use_detail_f:"f. To allow you to utilize features within our platforms by granting us access to information from your device.",
  id: "ID", 
  i_agree: "I agree to the ", 
  info_cont: "Information Contained in These Pages ", 
  info_contain_detail:"The information contained in these pages is not intended to provide professional advice and should not be relied upon in that regard. Persons accessing these pages are advised to obtain appropriate professional advice when necessary at all times in their respective jurisdiction.",
  insured_by:"Insured By",
  id_card:"Identity Card",
  info_req:"support@reQuid.com",
  inteference:"Interference",
  inteference_detail:"Requid grants you a license to use our platform and mobile applications so long as you comply with all our rules. You agree not to engage in any of the following",
  inteference_a:" a. Use any robot, spider, scraper, deep link or other similar automated data gathering or extraction tools, program, algorithm or methodology to access, acquire, copy or monitor the Platform or any portion of the Platform, without Requid’s express written consent, which may be withheld in Requid’s sole discretion.",
  inteference_b:"b. Use or attempt to use any engine, software, tool, agent, or other device or mechanism (including without limitation browsers, spiders, robots, avatars or intelligent agents) to navigate or search the Platform.",
  inteference_c:"c. Post or transmit any file which contains viruses, worms, Trojan horses or any other contaminating or destructive features, or that otherwise interfere with the proper working of the Platform.",
  inteference_d:"d. Attempt to decipher, decompile, disassemble, or reverse-engineer any of the software comprising or in any way making up a part of the Platform.",
  insurer:"Insurer:",
  investing:"Investing ",
  insufficient_fund: "Insufficient Funds",
  interest: "Interest",
  login: "Login",
  let_us_know: "Let us know what you like and where we can get better. Rate us and type your responses below.",
  lastName: "Last Name",
  logout: "Logout",
  linkExpired: "Sorry, this link has expired.",
  market: "Market",
  marketPlace:"Marketplace",
  months:"Months",
  make_req:"Make reQuid payments to the account details below:",
  martures_at:"Matures in",
  martures_on:"Matures on",
  matures:"Matures",
  martured:"Matured",
  matured:"Matured",
  min_withdraw:"Minimum withdrawal amount is 5,000.00",
  making_sure:"Making sure information is accurate",
  making_sure_detail:"Keeping your account information accurate and up to date is very important. If your account information is incomplete, inaccurate or not current, please use the Contact Us option on our website or other platform, call or write to us at the telephone numbers or appropriate address for changes listed on your account statements, records, online or other account materials. You can also speak to a customer representative, or relationship manager.",
  no_result:"Sorry, we could not find what you're searching for",
  no_cancel:"No, Cancel",
  name:"Name",
  nice_do_you:"Do you mind telling us about your experience? ",
  no_waiver:"No Waiver",
  next_of_kin:"Next of kin's details",
  no_waiver_details:"No waiver by the Organization, in exercising any right, power or provision hereunder shall operate as a waiver of any other right or of that same right at a future time; nor shall any delay in the exercise of any power or right be interpreted as a waiver.",
  notification:"Notifications",
  now_open:"Now Open",
  new_rate:"New Rate",
  new_pin:"New Pin",
  new_pass:"New Password",
  next:"Next",
  newPassword: "New Password",
  number_of_units:"Number of units",
  number_of_units_cart:"Number of units in cart",
  open:"Opened :",
  old_pin:"Old Pin",
  old_pass:"Old Password",
  ouch_please:"Please tell us what we could improve on.",
  once_started:"Once started, this action cannot be revoked",
  origin:"Origin",
  once_Sale_has:"Once sale has begun, it cannot be cancelled",
  origin_detail:"Designed and Developed by reQuid Technologies Ltd. in Lagos, Nigeria",
  other_info:"OTHER INFORMATION WE COLLECT ONLINE",
  other_info_detail:"Other Information is any information other than Personal Information that does not reveal your specific identity or does not directly relate to an individual, such as browser information, information collected through cookies, pixel tags and other technologies, demographic information, other information provided by you such as your date of birth or household income, and aggregated and de-identified data.",
  paid:"Paid",
  platform:"Platform",
  platform_detail:"This platform is operated by Requid Technologies Ltd (the 'Organization').",
  prod_name:"Product Name:",
  please_type_your:"Please type your questions and equiries in the form below and we’ll get back to you soon.",
  protecting_children:"Protecting children’s privacy online",
  protecting_children_detail:"Requid is not directed to individuals under the age of eighteen (18), and we request that these individuals do not provide Personal Information through the Site. We do not knowingly collect information from children under 18 without parental consent.",
  protecting_individual:"Protecting individual health information",
  protecting_individual_detail:"To the extent that we receive, maintain or process an individual’s protected health information, Requid may disclose that information as authorized by and in accordance with applicable federal and/or state law.",
  pay_a_min:"Pay a minimum of",
  payment_history:"Payment History",
  pay_with_card:"Pay with Card",
  pay_by_bank:"Pay with Bank Transfer",
  profile: "Profile",
  please_set_pass: "Please set Your password",
  product_insured_by: "Product Insured by",
  primary: "Primary Market",
  prev: "Previous",
  per_unit: "per unit",
  penalty: "Penalty",
  pin: "Pin",
  personal_info: "Personal Information We Collect Online",
  personal_info_detail: "During your visit to and use of our platforms, we obtain your Personal Information, which means personally identifiable information such as information you provide by completing our online forms, surveys, applications or other online fields including name, residential, postal or email addresses, telephone or mobile numbers, or account numbers.",
  product: "Product",
  product_purchased: "Products Purchased",
  product_in_cart: "Product in Cart",
  proceed_to_pay:"Proceed to payment",
  proceed:"Proceed",
  proceed_to_cart:"Cart",
  provider:"Provider",
  privacy_policy:"Privacy Policy",
  provided_by:"Provided By ",
  portfolio: "Portfolio",
  password: "Password",
  plea_up:"Please upload the following documents to gain more access",
  passwordReset: "Password Reset",
  payout_details: "Payout Details",
  payout_security: "Payout & Security",
  payout_details_before_withdr: "Kindly provide your bank account information, in order for us to credit you.",
  phone: "Phone Number",
  purchase: "Purchase",
  please_login: "Please login to have access to the selected area",
  purchase_product: "Purchase Product(s)",
  product_deleted: "Product removed successfully",
  product_name: "Product:",
  please_put_a_val: "You cannot buy more than ",
  policy: "Policy(s):",
  rate_roi:"Rate",
  rate_your_expe:"Rate your Experience",
  reset: "Reset",
  reset_pin: "Reset Pin",
  read_more: "... read more",
  remove: "Remove",
  returns: "Returns",
  rate_us: "Rate Us",
  remove_item: "Remove Item",
  ref_code: "Referral Code",
  referrals: "Referrals",
  reference: "Reference",
  relationship_kin: "Relationship with next of kin :",
  reference_code: "Reference Code",
  remove_from_cart:"Remove from Cart",
  remove_from_cart_prompt:"Are you sure you want to remove this product from your Cart?",
  resetLogin: "Reset Login Details",
  retypePassword: "Retype Password",
  retypePin: "Retype Pin",
  retype_new_pin: "Retype New Pin",
  retype_new_pass: "Retype New Password",
  resendLink: "Resend Link",
  resendCode: "Resend Code",
  req_withdrawal:"Request Withdrawal",
  refer_friends:"Refer friends & Win",
  req_tech:"reQuid Technologies Ltd. ",
  req_tech_detail:"reQuid is an investment marketplace that aggregates carefully profiled investment opportunities",
  roi:"ROI",
  referral_code:"Referral Code (optional)",
  referral_link:"Referral Link",
  req_amount:"Request Amount",
  retype_pass:"Re-type Password",
  signup: "Sign Up",
  share_ref:" Share referral link via:",
  sign_up_to_purchase:"Sign Up to purchase",
  sign_in: "Sign In",
  sell: "Sell  ",
  sale_in: "sale in Progress",
  save: "Save",
  security: "Security",
  security_a: "a. In order to ensure that this platform remains available to all users, the Organization may monitor network traffic to identify unauthorized attempts to upload or change information or to otherwise cause damage to the site. Anyone using this platform expressly consents to such monitoring.",
  security_b: "b. Unauthorized attempts to modify, alter, deface, destroy or corrupt any information stored on this platform or this system, to defeat or circumvent any security features, to probe, scan or test for vulnerabilities, to breach security or authentication measures, to forge TCP/IP headers, to install or attempt to install unauthorized software, to mount Denial of Service attacks or to utilize this system for other than its intended purposes are expressly prohibited and may result in criminal prosecution.",
  security_c: "c. Unauthorized attempts to modify, alter, deface, destroy or corrupt any information stored on this platform or this system, to defeat or circumvent any security features, to probe, scan or test for vulnerabilities, to breach security or authentication measures, to forge TCP/IP headers, to install or attempt to install unauthorized software, to mount Denial of Service attacks or to utilize this system for other than its intended purposes are expressly prohibited and may result in criminal prosecution.",
  security_d: "d. Any possible criminal activity will be reported, together with any evidence which may be gathered, to the appropriate authorities.",
  submit: "Submit",
  settings: "Settings",
  secret_ques:"Security Questions",
  secret_ques_1:"Security Question 1",
  secret_ques_2:"Security Question 2",
  set_pass: "Set Password",
  sold_out_paid: "Sold Out & Paid Out",
  select_quan: "Select Quantity",
  search_result: "Search Result",
  select_pay:"Select Payment Method",
  see_all:"See All",
  service_prod:"Services and Products",
  sold:"Sold",
  sorry:"Sorry",
  some_popular:"Some popular ATPs",
  sorry_no_port:"Sorry, you have not invested in any products. You are one step away from achieving your financial goals.",
  searching_for:"Searching for",
  secondary:"Secondary Market",
  share_referral:"Share referral link on social media",
  share_product_link:"Share product link on social media",
  severability:"Severability",
  severability_details:"If any of these terms is at any time held in any jurisdiction to be void, invalid or unenforceable, then it shall be treated as changed or reduced, only to the extent minimally necessary to bring it within the laws of that jurisdiction and to prevent it from being void and it shall be binding in that changed or reduced form. Subject to that, each provision shall be interpreted as severable and shall not in any way affect any other of these terms.",
  status:"Status:",
  still_avail:"Still Available",
  share_via:"share via",
  signup_bonus:"Signup Bonus",
  start_your:"Start your investment journey",
  sufficient_bal:"Sufficient Balance",
  success:"Success",
  sponsor_items:"Sponsor items in cart",
  sponsor_items_message:"If checked, this allows us to auto purchase items in your cart immediately you transfer your funds",
  sorry_no_tranx:"Sorry, you have not performed any transactions yet.",
  trans_id:"Transaction ID",
  total:"Total",
  token:"Token",
  to:"to",
  the_req:"The Requirements to use the Services",
  the_req_services:"You agree to promptly notify us of changes to your User Information by updating your Requid Account on the Platform; provided, however, that you must notify us at least one Business Day before any changes to your Bank Account information, including, but not limited to, the closure of your Bank Account for any reason by calling ……….. or calling +234 1 453 7990 or emailing support@requid.com or by updating your Requid Account via the Website. If we approve your registration, you will be authorized to use the Services, subject to these Terms.",
  the_req_services_detail:"For our compliance purposes and in order to provide the Services to you, you hereby authorize us to, directly or through a third-party, obtain, verify, and record information and documentation that helps us verify your identity and Bank Account information. When you register for the Services and from time to time thereafter, we may require you to provide and/or confirm information and documentation that will allow us to identify you, such as:",
  the_req_ser_a:" a. A copy of your government-issued photo ID, such as international passport or driver’s license;",
  the_req_ser_b:" b. A copy of a utility bill, bank statement, affidavit, or other bill, dated within three months of our request, with your name and Nigerian street address on it",
  the_req_ser_c:"c. Such other information and documentation that we may require from time to time.",
  tenor:"Tenor",
  to_protect:"To protect Personal Information from unauthorised access and use, we use security measures that comply with applicable federal and state laws. These measures may include device safeguards, secured files and buildings as well as oversight of our third-party service providers to ensure information remains confidential and secure.",
  transmitting:"Transmitting over the Internet",
  transfer_funds:"Transfer Funds",
  transfer:"Transfer",
  transmitting_detail:"Due to the nature of the Internet, transactions may be subject to interruption, transmission blackout, delayed transmission and incorrect data transmission. The Organization is not liable for malfunctions in communications facilities not under its control that may affect the accuracy or timeliness of messages and transactions you send. ",

  terms_condition_mob:"Terms & Conditions",
  terms_condition:"Terms & Cond..",
  transactions: "Transactions",
  tran_history: "Transaction History",
  trans_id_expire:"Transaction IDs expire after 24 hours",
  time: "Time of Payout",
  type: "Type",
  terms: "Terms",
  transmitt: "Transmitting over the Internet",
  transmitt_detail: "Due to the nature of the Internet, transactions may be subject to interruption, transmission blackout, delayed transmission and incorrect data transmission. The Organization is not liable for malfunctions in communications facilities not under its control that may affect the accuracy or timeliness of messages and transactions you send.",
  total_credits: "Total Credits",
  total_debits: "Total Debits",
  total_units: "Total Units",
  total_units_inv: "Total Units Invested",
  total_return: "Return",
  userEmail: "Email",
  unit: "Unit",
  units: "Units",
  unit_s: "Unit(s)",
  user_organiz:"User of the Organization’s Platform",
  user_org_detail:"Any user of the Organization’s platform accepts unreservedly, by using the platform, the terms and conditions for its use that are set out here. These terms include the legal jurisdiction within which any legal issues arising from the operation of the platform may be addressed. Any person who does not wish to accept these terms and conditions or who knows, or ought to know, of any reason why these terms and conditions might not be capable of applying to their purposes, is not authorized to use this platform. These terms apply to each and every visit that a user may make to this platform.",
  use_of_info:"Use of Information and Materials",
  up_for_sale: "Up for Sale",
  up_to: "up to",
  update_privacy: "Updates to this Privacy Policy",
  update_privacy_detail: "Requid reserves the right to make amendments to this Privacy Policy from time to time to reflect changes to our practices concerning the use of your personal information. Any changes to this Privacy Policy will become effective when we post the revised Privacy Policy on the platforms. Your use of the platforms following these changes means that you accept the revised Notice. ",
  units_left: "units left",
  uti_bill:"Utility Bill",
  units_available: "units left",
  unit_available: "unit left",
  units_purchased: "units purchased",
  upcoming:"upcoming",  
  verify: "Verify",
  version: "Version & Build",
  version_detail: "Version 1.000012. Build: 1.003456.",
  verificationCode:'Verification Code',
  verificationCodeResend:'A verification code has been sent successfully. Please check your email or phone.',
  view_cart:"View Cart",
  vest_lock:"Vestlock",
  wallet: "Wallet",
  whats_app: "Whatsapp Only",
  whats_app_num: "+234 708 528 3475",
  wallet_bal: "Wallet Balance",
  what_is_vest: "What is VestLock ?",
  welcome_back: "Welcome Back",
  withdraw_funds: "Withdraw Funds", 
  no_longer_edit: "Please contact support@requid.com to update your bank details", 
  you_are_abt:"You are about to sell this item",

  you_are_buying_less_than:"The rate has dropped, but you can get a higher rate if you bought up to ",

  your_roi_is_now_on_par:"We have increased your rate because you're buying more units. ",

  you_cant_sell:"You can't sell yet, product still in the market",
  you_hv_insufficient_bal:"You have insufficient funds. Kindly fund your wallet with enough money.",
  yes_remove:"Yes, Remove",
  yes_cancel:"Yes, Cancel",
  yes_sell:"Yes, Sell",
  your_requid:"Your requid account details",
  your_wallet_success:"Your wallet has been successfully credited",
  your_wallet_success_items_bought:"Your wallet has been credited successfully and items have been added to your portfolio.",
  your_access:"Your Access",
  your_access_detail:"In order to access certain features of Requid platform, you must register to create an account ('User Account'). When you register, you will be asked to choose a password, which you will be required to use to access your User Account. Requid Technologies has physical, electronic and procedural safeguards that comply with regulatory standards to guard Users' and Clients' non-public personal information (see Privacy Policy). You are responsible for safeguarding your password and other User Account information. You agree not to disclose your password to any third party and you will notify Requid Technologies immediately if your password is lost or stolen or if you suspect any unauthorized use of your User Account. As a User you agree that you shall be solely responsible for any activities or actions under your User Account, whether or not you have authorized such activities or actions. You agree that the information you provide to us on account registration through our platform will be true, accurate, current, and complete. If you wish to develop enhancements to the Requid Technologies service then you must do so by notifying us. You may not scrape or copy information through any means (including crawlers, browser plugins and add-ons, and any other technology or manual work) as explained in Section 3."
};

export default trans;
