<template>
<div>

    <div class="new-feature pb-4 pt-4" v-if="showTip && token !== null">
        <div style="display:flex; align-items:center; min-height:90vh">
        
        <div class="tip-div animated fadeIn">
            <div class="top-tip">
                <div style="display:flex" class="mb-4">
                    <div>
                        <h5 style="color: #828282">Fund Wallet</h5>
                    </div>
                    <div style="display:flex; justify-content:flex-end; flex-grow:1; "> <img src="../../assets/images/grey-exit.png" style="cursor:pointer" height="30"  alt="" @click="hideTip"></div>
                    </div>

                    <img src="../../assets/images/feat1.png" class="img-fluid" alt="">

                            </div>
                    <div class="bottom-tip">
                        <p class="text-left" style="color:#828282">Clicking the "Fund Wallet" button allows you credit your account on reQuid </p>

                        <div class="text-right mt-2">

                            <button style="background:#0094FF; border:1px solid #0094FF; border-radius:3px; color:#fff; min-width:100px; min-height:40px" class="next-btn mt-2" @click="nextTip($event)">{{tran.next}}</button>
                        </div>

                    </div>

                </div>
                <div class="tip-div animated fadeIn hide-elem">
                    <div class="top-tip">
                        <div style="display:flex">
                            <div>
                                <h5 style="color: #828282">Enter amount & Proceed </h5>
                            </div>
                            <div style="display:flex; justify-content:flex-end; flex-grow:1"> <img src="../../assets/images/grey-exit.png" height="30"  alt="" @click="hideTip"></div>
                            </div>
                           

                            <img src="../../assets/images/feat2.png" class="img-fluid" alt="">

                               
                            </div>
                            <div class="bottom-tip">
                                <p class="text-left pb-2" style="color:#828282">Simply input your desired amount to fund your wallet with and <b>PROCEED TO PAYMENT</b></p>

                                <p style="color:#828282"><b>NOTE:</b> For automatic investment on your behalf, click on the <b>Sponsor-Item-In-Cart</b> checkbox then click the <b>Proceed to payment</b> button.</p>
                                <div class="text-right mt-2">
                                    <button style="background:#f2f2f2; border:1px solid #f2f2f2; border-radius:3px; color:#828282;; min-width:100px; min-height:40px" class="mr-4 mt-2" @click="prevTip($event)">{{tran.prev}}</button>
                                    <button style="background:#0094FF; border:1px solid #0094FF; border-radius:3px; color:#fff; min-width:100px; min-height:40px" class="next-btn mt-2"  @click="nextTip($event)">{{tran.next}}</button>
                                </div>
                            </div>
                        </div>

                        <!-- New proto -->
                        <div class="tip-div animated fadeIn hide-elem">

                            <div class="top-tip">
                                <div style="display:flex" class="mb-4">
                                    <div>
                                        <h5 style="color: #828282">Your personal bank details </h5>
                                    </div>
                                    <div style="display:flex; justify-content:flex-end; flex-grow:1; "> <img src="../../assets/images/grey-exit.png" style="cursor:pointer" height="30"  alt="" @click="hideTip"></div>
                                    </div>

                                    <img src="../../assets/images/feat3.png" class="img-fluid" alt="">

                            </div>
                                    <div class="bottom-tip">
                                        <p class="text-left pb-2" style="color:#828282"> A <b>Unique Account </b> is provided.</p>
                                        <p class="text-left" style="color:#828282">This is your bank account for the reQuid platform. You can fund your wallet anytime by making transfers to the account details above.</p>

                                        <div class="text-right mt-2">

                                            <button style="background:#f2f2f2; border:1px solid #f2f2f2; border-radius:3px; color:#828282;; min-width:100px; min-height:40px" @click="prevTip($event)" class="mr-4 mt-2">{{tran.prev}}</button>
                                            <button style="background:#0094FF; border:1px solid #0094FF; border-radius:3px; color:#fff; min-width:100px; min-height:40px " class="next-btn mt-2"  @click="nextTip($event)">{{tran.next}}</button>
                                        </div>

                                    </div>

                                </div>
                        <div class="tip-div animated fadeIn hide-elem">

                            <div class="top-tip">
                                <div style="display:flex" class="mb-4">
                                    <div>
                                        <h5 style="color: #828282">Tranfer funds  </h5>
                                    </div>
                                    <div style="display:flex; justify-content:flex-end; flex-grow:1; "> <img src="../../assets/images/grey-exit.png" style="cursor:pointer" height="30"  alt="" @click="hideTip"></div>
                                    </div>


                            </div>
                                    <div class="bottom-tip">
                                        <p class="text-left pb-2" style="color:#828282"> Transfer funds to your Unique Account</p>
                                        <p class="text-left" style="color:#828282">Funds transferred to your reQuid account will be credited to your wallet automatically.</p>

                                        <div class="text-right mt-2">

                                            <button style="background:#f2f2f2; border:1px solid #f2f2f2; border-radius:3px; color:#828282;; min-width:100px; min-height:40px" @click="prevTip($event)" class="mr-4 mt-2">{{tran.prev}}</button>
                                            <button style="background:#0094FF; border:1px solid #0094FF; border-radius:3px; color:#fff; min-width:100px; min-height:40px " class="next-btn mt-2"  @click="nextTip($event)">{{tran.next}}</button>
                                        </div>

                                    </div>

                                </div>

                                <div class="tip-div animated fadeIn hide-elem">

                                    <div class="top-tip">

                                        <div style="display:flex">
                                            <div>
                                                <h5 style="color: #828282">Instant Credit</h5>
                                            </div>
                                            <div style="display:flex; justify-content:flex-end; flex-grow:1"> <img src="../../assets/images/grey-exit.png" height="30"  alt="" @click="hideTip"></div>
                                            </div>

                                            <img src="../../assets/images/feat4.png" class="img-fluid" alt="">

                            </div>
                                            <div class="bottom-tip">
                                                <p class="text-left" style="color:#828282">Your Wallet is credited automatically.</p>

                                                <div class="text-right mt-2">
                                                    <button style="background:#f2f2f2; border:1px solid #f2f2f2; border-radius:3px; color:#828282;; min-width:100px; min-height:40px" @click="prevTip($event)" class="mr-4">{{tran.prev}}</button>
                                                    <button style="background:#0094FF; border:1px solid #0094FF; border-radius:3px; color:#fff; min-width:100px; min-height:40px " class="next-btn mt-2"  @click="hideTip">{{tran.done}}</button>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    </div>

                                </div>
</template>

<script>
import {
    commonJs
} from "../dashboard/mixins/commonJs.js";

import {
    mapState
} from "vuex";
export default {
    mixins: [commonJs],

    methods: {
        nextTip(event) {

            event.currentTarget.parentNode.parentNode.parentNode.nextElementSibling.classList.remove('hide-elem')
            event.currentTarget.parentNode.parentNode.parentNode.classList.add('hide-elem')

        },
        prevTip(event) {

            event.currentTarget.parentNode.parentNode.parentNode.previousElementSibling.classList.remove('hide-elem')
            event.currentTarget.parentNode.parentNode.parentNode.classList.add('hide-elem')

        },

        hideTip() {

            this.$store.commit('domElements/hideTip')
            let tipStatus = false
            localStorage.setItem("tipStatus", JSON.stringify(tipStatus))
        }

    },
    computed: {
        ...mapState({
            tipsObject: state => state.domElements.tipsObject,
            showTip: state => state.domElements.showTip,

        })
    },

    mounted() {

        let tip = localStorage.getItem("tipStatus")

        tip = JSON.parse(tip)

        if (tip == false) {
            this.$store.commit('domElements/hideTip')
        }

    }

}
</script>

<style>

</style>
