<template>
<div class="mt-80">

    <!--  <div class="mobile-product-card">
                          <div>
                            <h4>Cashew</h4>
                            <p class="pb-2">Provided by AgroPartnership</p>
                            <p>5,000 units available</p>
                            <p>up to 33%, 7 months</p>
                        </div> 
                    </div>  -->

    <p v-if="noResult">{{ tran.no_result}}</p>
    <template v-if="productDetailComponent">
        <productDetail />
    </template>
    <img src="../../assets/images/chev.png" alt="" height="18" class="pr-3" @click="clearSearchString" style="cursor:pointer">
    <span  @click="clearSearchString" style="cursor:pointer">{{tran.go_back}}</span>
    <div class="mobile-product-div" v-if="windowWidth < 501">
        <template v-for="prod in filteredList">
            <div class="mb-2" :key="prod.id+prod.category">
                <div class="product-img-div" :style="{ backgroundImage: 'url(' + serVerImage+prod.product_image + ')' }" @click.prevent="showMobileDetailComponent(prod, $event)">
                </div>

                <div>
                    <h5>{{prod.name}}</h5>
                    <h6> <b> {{tran.up_to}} {{prod.roi}}% </b> </h6>
                    <small> {{prod.matures_at}}</small>
                </div>
            </div>
        </template>
    </div>

    <div v-show="windowWidth > 500" class="">
        <div class="display-div mb-2 pt-2">
            <div>
                <span><b>{{tran.search_result}} </b></span> <span><i class="fa fa-angle-double-right"></i> </span>
            </div>
        </div>

        <div v-for="(prod) in filteredList" :key="prod.id+prod.category" style="display:inline; border-radius:10px">
            <div class="item" @click="getProductObject(prod, $event)">
                <div class="img-div-1" :class="{'showHideDiv': productSelected == prod.id}" style="position: absolute; z-index: 90;" @click.prevent="closeAllDivs($event)">
                </div>
                <div class="img-div" :style="{ backgroundImage: 'url(' + serVerImage+prod.product_image + ')' }">
                    <p class="scrollDiv"></p>
      
<template v-if="prod.resell_units_available > 1 || prod.units_available > 1">
   
                                    <p class="text-right" style="text-decoration:underline" v-if="prod.units_available !== undefined "> {{formatNumber(prod.units_available) }}  <span v-if="prod.units_available >1"> {{tran.units_available}}</span> <span v-if="prod.units_available < 2">{{tran.unit_available}}</span> </p>

                                    <p class="text-right" style="text-decoration:underline" v-if="prod.resell_units_available !== undefined ">           {{formatNumber(prod.resell_units_available) }}  <span v-if="prod.resell_units_available > 1"> {{tran.units_available}}</span> <span v-if="prod.resell_units_available < 2">{{tran.unit_available}}</span> </p>

</template>

<template v-if="prod.resell_units_available < 1 || prod.units_available < 1">
   <div class="sold-out">
                                        <p>SOLD OUT</p>

                                    </div>
</template>

                    </p>
                    <div class="atp-preview">
                        <div>
                            <h6>
                                <b>{{prod.name}}</b>
                            </h6>
                            <p>{{prod.atp}}</p>
                        </div>

                        <div class="atp-roi">
                            <p>{{tran.up_to}} {{prod.roi}}%</p>
                            <p>{{prod.matures_at}}</p>
                        </div>
                    </div>
                </div>

                <div style="position:relative;text-align:center; right:12px">
                    <img src="../../assets/images/arrow.png"  class="fa-angle-down animated slideInDown mb-4" style="margin-top:0.8rem!important" height="20">
                                        </div>
                </div>

                <div class="info">
                 
                        <div class="investment-details">
                            <div class="img-detail mr-4" v-if="windowWidth > 1200">
                                <div style="min-height:130px; overflow:hidden; border-radius:3px;">
                                    <div style="min-height:230px; border-radius:5px;     background-position: center;" :style="{ backgroundImage: 'url(' +serVerImage+ prod.product_image + ')' }">

                                    </div>

                                    <div class="align-items-bottom">
                                        <span style="font-size:13px" class="pr-1">{{tran.insured_by}}</span>
                                        <span> </span>
                                        <span style="font-size:13px">
                                                           <b> {{prod.insurer_name}} </b>
                                                        </span>
                                    </div>
                                </div>
                                <hr>
                                <div class="align-items-bottom" style="    padding-top: 15px">
                                    <span class="head-font pr-1">{{tran.provided_by}}  </span> <span> </span>
                                    <span class="theme-color-und" @click="sendUserToAtpDetailView(prod.atp_id)">
                                                             {{ prod.atp}}
                                                        </span>
                                </div>
                             
                                <input class="prod-url" type="hidden" :value="productUrl+'/market/'+`${prod.category}`+'/'+`${prod.name}`+'/'+`${prod.id}`">
                                <div style="display:flex; justify-content:center" @click="copyProductUrl($event)" :content="tran.copied_to_clipboard" v-tippy="{trigger : 'click', hideOnClick : true, sticky : false}">
                                    <div class="mt-1" style="background: #E4FAE1 !important; height:50px; color:#000; border-radius:3px; display:flex; justify-content:center;     cursor: pointer; align-items:center; max-width:155px ">

                                        <p> {{tran.copy_prod_url}}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="product-detail mr-4">
                                <div>
                                    <h3>{{prod.name}}</h3>
                                    <img :src="serVerImage+prod.product_image" height="80" class="mb-2" v-if="windowWidth < 1200">
                                    <p>{{prod.details}}</p>
                                </div>
                                <!-- <div class=" text-left" v-if="windowWidth < 1200">
                                                    <p>{{tran.provided_by}}</p>
                                                            <p class="theme-color-und text-left" @click.prevent="sendUserToAtpDetailView(prod.atp_id)" v-if="windowWidth < 1200">{{prod.atp}}</p>
                                                    </div> -->

                                <div class="text-left" v-if="windowWidth < 1200">
                                    <span style="font-size:13px">{{tran.insured_by}}</span>
                                    <span> </span>
                                    <span style="font-size:13px">
                                                           <b> {{prod.insurer_name}} </b>
                                                        </span>

                                </div>
                                <template v-if="windowWidth < 1200">

                                    <hr width="50%">
                                    <div class="pb-2 pt-2">
                                        <span class="head-font">{{tran.provided_by}}  </span> <span> </span>
                                        <span class="theme-color-und" @click="sendUserToAtpDetailView(prod.atp_id)">
                                                             {{ prod.atp}}
                                                        </span>

                                    </div>
                                </template>

                                <template v-if="windowWidth < 1200">

                                    <input class="prod-url" type="hidden" :value="productUrl+'/market/'+`${prod.category}`+'/'+`${prod.name}`+'/'+`${prod.id}`">
                                    <div style="display:flex; justify-content:flex-start" @click="copyProductUrl($event)" :content="tran.copied_to_clipboard" v-tippy="{trigger : 'click', hideOnClick : true, sticky : false}">
                                        <div class="mt-1" style="background: #E4FAE1 !important; height:50px; color:#000; border-radius:3px; display:flex; justify-content:center;     cursor: pointer; align-items:center; max-width:155px ">

                                            <p> {{tran.copy_prod_url}}</p>
                                        </div>
                                    </div>
                                </template>
                            </div>

                            <div class="cart-point">
                                  <div style="" class="close-btn">

                                                        <img src="../../assets/images/close.png"  height="25" style="cursor:pointer" class="close-info-div" @click.prevent="closeInfoDiv($event)">
                                                   </div>
                                <div class="crt-div mb-4">
                                    <div style="display:flex;" class="pb-2">
                                        <p>
                                            <i class="fa fa-ticket add-curved-radius mr-4"></i>{{tran.cost_per_unit}}
                                        </p>
                                        <p class="text-right">{{formatAmount(prod.cost_per_unit)}}</p>
                                    </div>

                                    <div style="display:flex;" class="mt-2 pb-2">
                                        <p><i class="fa fa-percent add-curved-radius mr-4"></i> {{tran.roi}}
                                        </p>
                                        <p class="user-roi text-right fw-600">{{prod.roi}} % </p>
                                    </div>

                                    <div style="display:flex;" class="mt-2">
                                        <p><i class="fa fa-calendar add-curved-radius mr-4"></i> {{tran.tenor}}
                                        </p>
                                        <p class="text-right fw-600">{{prod.matures_at}}</p>
                                    </div>
                                    <div style="display:flex;" class="mt-2">
                                        <p><i class="fa fa-shopping-cart add-curved-radius mr-4"></i> {{tran.avail_unit}}
                                        </p>
                                        <p class="text-right fw-600" v-if="prod.resell_units_available > 1 || prod.units_available > 1">{{prod.resell_units_available || prod.units_available}} {{tran.units}}</p>
                                        <p class="text-right fw-600" v-if="prod.resell_units_available < 2 || prod.units_available < 2">{{prod.resell_units_available || prod.units_available}} {{tran.unit}}</p>
                                    </div>
                                </div>

                                <div class="add-units" :class="{'fade-elem': prod.units_available == 0 || prod.resell_units_available == 0}">
                                    <h6 class="pt-2 text-center"><b>{{tran.purchase}}</b></h6>
                                    <div style="padding:6px 15px; align-items:center; justify-content:center" class="display-div text-center">

                                        <i class="fa fa-minus text-left" @click="getUserRoi($event, prod, 'minus')"  :class="{'fade-elem':prod.defaultUnitSelected < 2 }"></i>

                                        <input type="text" class="cart-input text-center" @keyup="checkUnitValue( $event, prod)" onfocus="this.setSelectionRange(this.value.length,this.value.length);" :value="prod.resell_units_available" v-if="prod.resell_units_available < prod.defaultUnitSelected">

                                        <input type="text" class="cart-input text-center" @keyup="checkUnitValue( $event, prod)" onfocus="this.setSelectionRange(this.value.length,this.value.length);" :value="prod.defaultUnitSelected" v-else>

                                        <i class="fa fa-plus"  @click="getUserRoi($event, prod, 'add')" :class="{'fade-elem': prod.defaultUnitSelected == prod.resell_units_available  || prod.defaultUnitSelected == prod.units_available }"></i>

                                    </div>
                                    <p class="unit-string fw-600 text-center" v-if="prod.defaultUnitSelected < 2"><b>{{tran.unit}}</b></p>
                                    <p class="unit-string fw-600 text-center" v-else><b>{{tran.units}}</b></p>
                                    <div class="display-div mt-2" style="border-top:0.5px solid #ccc; padding:6px 15px">
                                        <div class="text-center" style="border-right:0.5px solid #ccc;">
                                            <span>{{tran.cost}} : </span>
                                            <span>&#8358;</span>
                                            <span class="cost-unit text-center">{{formatAmount(prod.user_cost)}}</span>

                                        </div>
                                        <div class="cost-unit text-center" style="color:#58B46F">
                                            <span>{{tran.roi}}: </span>
                                            <span>&#8358;</span>
                                            <span class="user-amount text-center">{{formatAmount(prod.user_roi)}}</span>

                                        </div>

                                    </div>
                                    <button @click.prevent="addToCart(prod,$event)" class="cart-btn" :class="{'fade-elem': loader}" v-if="token !== null">
                                            {{loader ? '' :  tran.add_tocart}}
                                            <span class="loader" v-if="loader"></span>
                                          </button>
                                    <button class="cart-btn"  @click="sendGuestTosignUp" v-else>
                                            {{  tran.sign_up_to_purchase}}
                                            <span class="loader" v-if="loader"></span>
                                          </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
</template>

<script>
import productDetail from "../partials/MobileProductDetail";
import Slick from 'vue-slick';
import {
    mapState
} from "vuex";

import {
    commonJs
} from './mixins/commonJs.js';
export default {
    mixins: [commonJs],
    data() {
        return {
            slickOptions: {
                slidesToShow: 3,
                slidesToScroll: 1,
                // autoplay: true,
                // autoplaySpeed: 2000,
                // dots: true,
                variableWidth: true

            },

            units: "",
            productSelected: this.$route.params.id,
            cartInput: 0,
            prodAdded: false,
            search: "",
            startDate: "",
            endDate: "",
            minInterest: "",
            maxInterest: ""
        };
    },
    methods: {

        getCategoryArray(data) {

            this.$store.commit("market/saveCategoryArray", data)
        },

        filteredData(data) {
            this.$helpers.log(this.minInterest);
            let vm = this;
            return _.filter(data, function (data) {
                if (
                    (vm.startDate == "" || vm.endDate == "") &&
                    (vm.minInterest == "" || vm.maxInterest == "")
                ) {
                    return true;
                } else if (vm.startDate && vm.endDate) {
                    let date = data.tenor;
                    return date >= vm.startDate && date <= vm.endDate;
                } else if (vm.minInterest && vm.maxInterest) {
                    let interestInLoop = data.interest;
                    return (
                        interestInLoop >= vm.minInterest && interestInLoop <= vm.maxInterest
                    );
                }
            });
        },

        clearSearchString() {
            this.$store.commit('market/emptySearchString')
                document.querySelectorAll('.form-input')[0].value = ''
        }

    },

    computed: {
        ...mapState({
            productObject: state => state.market.productObject,
            cartItems: state => state.market.cartItems,
            productsForMobile: state => state.market.productsForMobile,
            productDetailComponent: state => state.market.productDetailComponent,
        }),

        calculateTotal() {
            let total = 0;
            for (let i = 0; i < this.cartItems.length; i++) {
                total += this.cartItems[i].item.price * this.cartItems[i].quantity;
            }
            return total;
        },

        getUnitsLeft() {
            for (let i = 0; i < this.products.length; i++) {
                return this.products[i].inStock;
            }
        },
        productInStock() {
            return this.products;
        },
        cartInputValue() {
            return this.cartInput;
        }
    },
    components: {

        productDetail,

    },

    mounted() {

    }
};
</script>

<style>
.fa-angle-down {
    position: absolute;
    display: none;
    color: #000;
    top: 2px;
    font-size: 38px;
    z-index: -5;
    text-align: center;
}
</style>
