import Vue from "vue";
import Vuex from "vuex";
import router from "../../router";
import http from "../../http/index";
import api from "../../apis/index";
import CxltToastr from "cxlt-vue2-toastr";
import helpers from "../../main";
import moment from "moment";
Vue.use(CxltToastr);
Vue.use(Vuex);

const marketPlace = {
  namespaced: true,

  state: {
    searchString: "",
    allProducts: [],
    primaryMarket: [],
    secondaryMarket: [],
    resellMarket: [],

    soldPaidOut: [],
    productObject: {
      user_roi: "",
      user_cost: ""
    },
    categoryArray: {
      cost_per_unit: "",
      user_cost: 2,
      user_roi: 2
    },
    currentCategory: "",
    heading: {
      mainHeading: "",
      subHeading: ""
    },
    loader: false,
    loader2: false,
    customerRoiAmount: "",
    productDetailComponent: false,
    productsForSale: [],

    productsForMobile: {},
    cartItems: [],
    portfolioItems: [],
    removeCartItemObject: {},
    preLoader: true,

    offlineDetailModal: false,

    fundOptionModal: true,

    offlinePaymentDetail: {},

    accountOverview: {
      credits: null,
      debits: null
    },
    walletTransactions: [],
    walletData: [],
    portfolioTransactions: [],
    portfolioSummary: {},
    addMobileStatus: true,
    cartItemLength: null,

    cartItemData: "",

    notifyUser: false,

    cartTotalAmount: 0,

    cartTotalUnits: "",
    userBalance: 0,
    atpDetail: "",
    atpProducts: [],
    notifyMessage: "",
    scrollStatus: false,
    searchObjects: {
      cart: false,
      portfolio: false,
      wallet: false,
      products: true
    },

    isComingSoon: false,

    offlineMinAmount: null,

    roiSetting: null,
    accountOverviewCredits: null,
    accountOverviewDebits: null,
    accountOverviewBonus: 0,
    isFetching: false,

    vestLockData: null,
    vestLoader: false,
    vestPortfolio: [],
    totalVestedAmount: "",
    vestAmount:'',
    vestLockProducts:'',
    firstVestProductId:'',
    allTags:'',
    tagLoader:false,
    bookingSettings:{},
    bookingHistory:[],
    totalBookedValue:0,
    totalBookedAccruedInterestValue:0,
    userTotalBalance:0,
    dollarBondProductId:null,
    bondData:null,
    dollarBondData:{},
    dollarBondMaturityArray:{},
    dollarBondMaturityDate:{},
    serverTime:null,
    oneYearDollarBondHistory:{},
    SixMonthsDollarBondHistory:{},
    allProductsFromApi:[],
    portPreLoader:true,
  },

  mutations: {
    saveDollarBondHistory(state, payload){
      state.oneYearDollarBondHistory = payload.oneYear
      state.SixMonthsDollarBondHistory = payload.sixMonths
    },
    saveDollarBondData(state, payload){
      state.dollarBondData = payload
     
    },
    saveDollarBondMaturityDates(state, payload){
      state.dollarBondMaturityArray = payload
     
    },
    saveBondDataFromComponent(state, payload){
      state.bondData = payload
     
    },
    saveBondMaturityDate(state, payload){
      state.dollarBondMaturityDate = payload
     
    },
    saveDollarBondProductId(state, payload){
      state.dollarBondProductId = payload
     
    },
    saveServerTime(state, payload){
      state.serverTime = payload
     
    },
    saveTotalBal(state, payload){
      state.userTotalBalance = payload
     
    },
    saveBookingTotalValue(state, payload){
      state.totalBookedValue = payload.totalBookedValue
      state.totalBookedAccruedInterestValue = payload.totalAccruedValue
    },
    saveCurrentPortfolioValue(state, payload){
      state.portfolioSummary.total_value = payload.totalEstimate
      state.portfolioSummary.current_value = payload.currentVal
      state.portfolioSummary.total_value_inDollar = payload.totalEstimateInDollar
      state.portfolioSummary.current_value_inDollar = payload.currentValInDollar
    },
saveVestLockProducts(state, payload){
state.vestLockProducts = payload

},
saveFirstVestLockProduct(state, payload){
state.firstVestProductId = payload

},

savePortfolioData(state, payload){
  state.portfolioTransactions = payload
  
  },
  


    clearVestAmount(state){

state.vestAmount = ''
    },
  
    AddUnitsonMobile(state) {
      state.addMobileStatus = false;
    },
    stopAddUnitsonMobile(state) {
      state.addMobileStatus = true;
    },
    startScroll(state) {
      state.scrollStatus = true;
    },

    checkSearchObject(state, payload) {
      if (payload == "cart") {
        state.searchObjects.cart = true;
        state.searchObjects.portfolio = false;
        state.searchObjects.wallet = false;
        state.searchObjects.products = false;
      } else if (payload == "wallet") {
        state.searchObjects.cart = false;
        state.searchObjects.portfolio = false;
        state.searchObjects.wallet = true;
        state.searchObjects.products = false;
      } else if (payload == "portfolio") {
        state.searchObjects.cart = false;
        state.searchObjects.portfolio = true;
        state.searchObjects.wallet = false;
        state.searchObjects.products = false;
      }
    },

    activateIsComingSoon(state) {
      state.isComingSoon = true;
    },

    deactivateIsComingSoon(state) {
      state.isComingSoon = false;
    },
    saveSearchData(state, payload) {
      state.allProducts = payload;
    },
    startTagLoader(state) {
      state.tagLoader = true;
    },
    stopTagLoader(state) {
      state.tagLoader = false;
    },
    openOfflineDetailModal(state) {
      state.offlineDetailModal = true;
      state.fundOptionModal = false;
    },

    showFundOptionModal(state) {
      state.offlineDetailModal = false;
      state.fundOptionModal = true;
    },

    stopIsFetching(state) {
      setTimeout(() => {
        state.isFetching = true;
      }, 1500);
    },

    startIsFetching(state) {
      state.isFetching = false;
    },

    startPreloader(state) {
      state.preLoader = true;
    },
    startPortPreloader(state) {
      state.portPreLoader = true;
    },
    stopPortPreloader(state) {
      state.portPreLoader = false;
    },

    stopPreloader(state) {
      setTimeout(()=>{

        state.preLoader = false;

      }, 800)
    },

    saveRemoveCartItemObject(state, payload) {
      state.removeCartItemObject = payload;
    },
    saveBookingSettings(state, payload) {
      state.bookingSettings = payload;
    },
    saveBookingHistory(state, payload) {
      state.bookingHistory = payload;
    },
    saveTags(state, payload) {
      state.allTags = payload;
    },

    saveCartItems(state, payload) {
      state.cartItems = payload;
    },
    emptyCartItems(state) {
      state.cartItems = [];
      state.cartItemLength = undefined;
      state.portfolioItems = [];
      state.accountOverview = [];
    },

    saveProducts(state, payload) {
      state.allProducts = payload;
    },

    saveMarketTypes(state, payload) {
      // state.primaryMarket = [];
      // state.secondaryMarket = [];
      // state.resellMarket = [];

      
      state.primaryMarket = payload.primaryCategory;
      state.secondaryMarket = payload.secondaryCategory;
      state.resellMarket = payload.resellCategory;

      state.soldPaidOut = payload.soldOutPaidOut;

    },

    startLoader(state) {
      state.loader = true;
    },

    stopLoader(state) {
      state.loader = false;
    },

    startLoader2(state) {
      state.loader2 = true;
    },

    stopLoader2(state) {
      state.loader2 = false;
    },

    saveCustomerRoiAmount(state, payload) {
      state.customerRoiAmount = payload;
    },

    savePageHeading(state, payload) {
      state.heading.mainHeading = payload.main;
      state.heading.subHeading = payload.sub;
    },
    removePageHeading(state) {
      state.heading.mainHeading = "";
      state.heading.subHeading = "";
    },

    saveSearchString(state, payload) {
      state.searchString = payload;
    },

    emptySearchString(state) {
      state.searchString = "";
    },
    emptyPortfolioData(state) {
      state.portfolioTransactions = [];
    },
    emptyWalletData(state) {
      state.walletTransactions = [];
    },

    saveCategoryType(state, payload) {
      router.push("/" + `${payload}` + "/market");

      state.currentCategory = payload;
    },
    saveCategoryArrayOnMobile(state, payload) {
      state.currentCategory = payload;
    },

    emptyCategoryArray(state) {
      state.categoryArray = [];
    },

    saveProductObject(state, payload) {
      state.productObject = payload;
    },

    showMobileProductDetailComponent(state) {
      state.productDetailComponent = true;
    },
    hideMobileProductDetailComponent(state) {
      state.productDetailComponent = false;
    },

    openNotification(state) {
      state.notifyUser = true;
    },

    saveAllProducts(state, payload) {
      state.allProductsFromApi = payload;
    },

    saveNotifyMessage(state, payload) {
      state.notifyMessage = payload;
    },

    closeNotification(state) {
      state.notifyUser = false;
    },

    saveOfflineMinAmount(state, payload) {
      state.offlineMinAmount = payload;
    },
    saveRoiSetting(state, payload) {
      state.roiSetting = payload;
    },
    saveVestLockInvestmentData(state, payload) {
      state.vestLockData = payload;
    },
    saveVestPortfolio(state, payload) {
      state.vestPortfolio = payload;
    },
    saveTotalVestAmount(state, payload) {
      state.totalVestedAmount = payload;
    },
    setVestLoader(state, payload) {
      state.vestLoader = true;
    },
    stopVestLoader(state, payload) {
      state.vestLoader = false;
    },
    saveWalletBonus(state, payload) {
      state.accountOverviewBonus = payload

    },
    
    saveWalletTransaction(state, payload) {
      state.walletTransactions = payload.reverse()

    },
    saveWalletData(state, payload) {
      state.walletData = payload

    },
    
  },
  actions: {
    getAllProducts({ state, commit }) {
      http
        .get(api.getAllProducts)
        .then(response => {
          
          helpers.log(response, "all products Api");
          helpers.log(response.data.data.resell_products, "resell Api");






          commit("saveRoiSetting", response.data.data.roi_setting);
          let allProducts = response.data.data;



        function  prepare_datetime (datetime, offset = "+01:00") {
            if (datetime.search(' ') >= 0) datetime = datetime.replace(' ', 'T');
            if (!datetime.substr(-1, 1) == 'Z' && datetime.search('+') >= 0) datetime += offset;
        
            return new Date(datetime);
        }


          
          //Primary Market
          let primary = allProducts.products.filter(function(prod) {
            
            const isProducDateAheadOfServerTime = moment(response.data.server_time).isAfter(moment(prod.opens_at));

            if(!isProducDateAheadOfServerTime){

              prod.category = "upcoming";

            }
            
            // if ( prepare_datetime(prod.opens_at) > prepare_datetime(response.data.server_time)) {
            // }



            if (
              (prod.category === "primary" &&
              prepare_datetime(prod.opens_at) < prepare_datetime(response.data.server_time) &&
                prepare_datetime(prod.matures_at) > prepare_datetime(response.data.server_time))  || 
                
              prod.category === "resell" &&
              prepare_datetime(prod.opens_at) < prepare_datetime(response.data.server_time) &&
                prepare_datetime(prod.matures_at) > prepare_datetime(response.data.server_time) ||
              prod.category === "upcoming"
             
            ) {
              
              let date1 = moment(prod.matures_at);
              let date2 = "";

              if (prod.primary_closes_at !== null) {
                date2 = moment(prepare_datetime(prod.primary_closes_at));
              } else {
                date2 = moment(prepare_datetime(response.data.server_time));
              }

     

              prod.maturityInMonths = date1.diff(date2, "days");
              prod.numOfDays = date1.diff(date2, "days")
              prod.unitIsLessThanAtp1Unit = false;
              prod.product_opened_with = moment(prod.matures_at).diff(
                moment(prod.opens_at),
                "days"
              );
              prod.product_opened_with =
                Math.floor(prod.product_opened_with / 30) + " Month(s)";

          

              let divideDate = prod.maturityInMonths / 30.4375;
              prod.isMaturityNeg = divideDate

              // if (
              //   prod.fixed_tenor !== "0.00" &&
              //   divideDate > prod.fixed_tenor 
              // ) 
              if ( prod.fixed_tenor !== "0.00") 
              
              
              {

                if(prod.fixed_tenor % 1 == 0.00){
                  prod.maturityInMonths = Math.round(prod.fixed_tenor) + " Month(s)";
                }

                else{
                  prod.maturityInMonths = prod.fixed_tenor + " Month(s)";
                }

              } else {
                if (divideDate % 1 > 0.3 && divideDate % 1 < 0.75) {
                 

                  if (divideDate < 2) {
                    prod.maturityInMonths = Math.floor(divideDate) + ".5" + " Month(s)";
                  } else {
                    prod.maturityInMonths = Math.floor(divideDate) + ".5" + " Month(s)";
                  }
                } else if (divideDate % 1 >= 0.75) {
               

                  if (divideDate < 2) {
                    prod.maturityInMonths = Math.floor(divideDate) + " Month(s)";
                  } else {
                    prod.maturityInMonths = Math.floor(divideDate) + " Month(s)";
                  }
                } else if (divideDate % 1 <= 0.3) {
                
                  if (divideDate < 2) {
                    prod.maturityInMonths = Math.floor(divideDate) + " Month(s)";
                  } else {
                    prod.maturityInMonths = Math.floor(divideDate) + " Month(s)";
                  }
                }
              }
           

           
       

              // let diffS = moment.duration(date1.diff(date2));
              // prod.matures_at = (diffS.months() +" months,"+ diffS.weeks()%7 + "weeks");




              prod.defaultUnitSelected = Math.ceil(
                prod.vendor_cost_per_unit / prod.cost_per_unit
              );

              if (prod.units_available <= prod.defaultUnitSelected) {
                prod.defaultUnitSelected = prod.units_available;
              }

              let unitBought = prod.defaultUnitSelected * prod.cost_per_unit;

              if (
                unitBought < prod.vendor_cost_per_unit &&
                prod.units_available > 0
              ) {
                if (prod.roi > 24) {
                  prod.roi = (
                    prod.roi - response.data.data.roi_setting.less_atp_unit_gt24
                  ).toFixed(2);
                } else if (prod.roi >= 15) {
                  prod.roi = (
                    prod.roi - response.data.data.roi_setting.less_atp_unit_gt15
                  ).toFixed(2);
                } else if (prod.roi < 15) {
                  prod.roi = (
                    prod.roi - response.data.data.roi_setting.less_atp_unit_lt15
                  ).toFixed(2);
                }

                prod.unitIsLessThanAtp1Unit = true;
              }

              // check if product is in the cart, if true replace quantity in the market with that inside the cart
              for (var i = 0; i < state.cartItems.length; i++) {
                if (
                  prod.name !== state.cartItems[i].name &&
                  prod.category !== state.cartItems[i].market
                ) {
                  prod.defaultUnitSelected;
                }

                if (
                  prod.name == state.cartItems[i].name &&
                  prod.category == state.cartItems[i].market
                ) {
                  prod.defaultUnitSelected = state.cartItems[i].units;
                }
              }

              let atpRate = prod.roi;

              let totalAmountUserIsBuyingFor =
                prod.defaultUnitSelected * prod.cost_per_unit;

              let rateOfUser = atpRate * 0.01;
              let finalAmount = rateOfUser * totalAmountUserIsBuyingFor;

              prod.user_roi =
                parseFloat(finalAmount) +
                parseFloat(totalAmountUserIsBuyingFor);
              prod.user_cost = parseFloat(totalAmountUserIsBuyingFor);
             prod.urlName = prod.name.replace(/\s/g, '');
              return prod;
            }
          });

         


         
         
          //Secondary Market
          let secondary = allProducts.products.filter(function(prod) {
            if (
              prod.category === "secondary" ||
              (prod.category === "resell" &&
                prod.units_available == 0 &&
                prod.settlement_status == "settled")
                ||
                (
                  prod.settlement_status == "pending" && prod.category === "resell"  )
                
            ) {

              let date1 = moment(prod.matures_at);
              let date2 = "";
              if (
                prod.primary_closes_at !== null &&
                prod.primary_closes_at > prepare_datetime(response.data.server_time)
              ) {
                date2 = moment(prepare_datetime(prod.primary_closes_at));
              } else {
                date2 = moment(prepare_datetime(response.data.server_time));
              }
           
              prod.maturityInMonths = date1.diff(date2, "days");
              prod.numOfDays = date1.diff(date2, "days")
              prod.product_opened_with = moment(prod.matures_at).diff(
                moment(prod.opens_at),
                "days"
              );
              prod.product_opened_with =
                Math.floor(prod.product_opened_with / 30) + " Month(s)";

              let divideDate = prod.maturityInMonths / 30.4375;
    
              prod.isMaturityNeg = divideDate

              if (
                prod.fixed_tenor !== "0.00" &&
                divideDate > prod.fixed_tenor
              ) {
                if(prod.fixed_tenor % 1 == 0.00){
                  prod.maturityInMonths = Math.round(prod.fixed_tenor) + " Month(s)";
                }

                else{
                  prod.maturityInMonths = prod.fixed_tenor + " Month(s";
                }

              } else {
                if (divideDate % 1 > 0.3 && divideDate % 1 < 0.75) {
               
                  if (divideDate < 2) {
                    prod.maturityInMonths = Math.round(divideDate) + ".5" + " Month(s)";
                  } else {
                    prod.maturityInMonths = Math.round(divideDate)  + ".5" + " Month(s)";
                  }
                } else if (divideDate % 1 >= 0.75) {
              

                  if (divideDate < 2) {
                    prod.maturityInMonths = Math.round(divideDate)  + " Month(s)";
                  } else {
                    prod.maturityInMonths = Math.round(divideDate)  + " Month(s)";
                  }
                } else if (divideDate % 1 <= 0.3) {
                
                  if (divideDate < 2) {
                    prod.maturityInMonths = Math.round(divideDate)  + " Month(s)";
                  } else {
                    prod.maturityInMonths = Math.round(divideDate)  + " Month(s)";
                  }
                }
              }
           
              prod.defaultUnitSelected = Math.ceil(
                prod.vendor_cost_per_unit / prod.cost_per_unit
              );

              if (prod.units_available <= prod.defaultUnitSelected) {
                prod.defaultUnitSelected = prod.units_available;
              }

              //Remove x percent when unit bought is less or more than a unit of the Atp
              if (prod.roi > 24) {
                prod.roi = (
                  prod.roi - response.data.data.roi_setting.less_atp_unit_gt24
                ).toFixed(2);
              } else if (prod.roi >= 15) {
                prod.roi = (
                  prod.roi - response.data.data.roi_setting.less_atp_unit_gt15
                ).toFixed(2);
              } else if (prod.roi < 15) {
                prod.roi = (
                  prod.roi - response.data.data.roi_setting.less_atp_unit_lt15
                ).toFixed(2);
              }

              // check if product is in the cart, if true replace quantity in the market with that inside the cart

              for (var i = 0; i < state.cartItems.length; i++) {
                if (
                  prod.name !== state.cartItems[i].name &&
                  prod.category !== state.cartItems[i].market
                ) {
                  prod.defaultUnitSelected;
                }

                if (
                  prod.name == state.cartItems[i].name &&
                  prod.category == state.cartItems[i].market
                ) {
                  prod.defaultUnitSelected = state.cartItems[i].units;
                }
              }

              // prod.matures_at = diff_months(dt1, dt2);

              let totalAmountUserIsBuyingFor =
                prod.defaultUnitSelected * prod.cost_per_unit;

              let rateOfUser = prod.roi * 0.01;
              //  interest calculated
              let finalAmount = rateOfUser * totalAmountUserIsBuyingFor;

              prod.user_roi =
                parseFloat(finalAmount) +
                parseFloat(totalAmountUserIsBuyingFor);
              prod.user_cost = parseFloat(totalAmountUserIsBuyingFor);
              prod.urlName = prod.name.replace(/\s/g, '');
              return prod;
            }
          });

          //Resell Market
          let resell = allProducts.resell_products.filter(function(prod) {
        
              prod.defaultUnitSelected = Math.ceil(
                prod.vendor_cost_per_unit / prod.cost_per_unit
              );

              if (prod.resell_units_available <= prod.defaultUnitSelected) {
                prod.defaultUnitSelected = prod.resell_units_available;
              }

              for (var i = 0; i < state.cartItems.length; i++) {
                if (
                  prod.name !== state.cartItems[i].name &&
                  prod.category !== state.cartItems[i].market
                ) {
                  prod.defaultUnitSelected;
                }

                if (
                  prod.name == state.cartItems[i].resell_name &&
                  prod.category == state.cartItems[i].market
                ) {
                  prod.defaultUnitSelected = state.cartItems[i].units;
                }
              }

              let date1 = moment(prod.matures_at);
              let date2 = "";

              if (
                prod.primary_closes_at !== null &&
                prepare_datetime(prod.primary_closes_at) > prepare_datetime(response.data.server_time)
              ) {
                date2 = moment(prod.primary_closes_at);
              } else {
                date2 = moment(prepare_datetime(response.data.server_time));
              }

              prod.maturityInMonths = date1.diff(date2, "days");

              prod.numOfDays = date1.diff(date2, "days")
              prod.product_opened_with = moment(prod.matures_at).diff(
                moment(prod.opens_at),
                "days"
              );
              prod.product_opened_with =
                Math.floor(prod.product_opened_with / 30) + " Month(s)";
               
              // let divideDate = Math.round(prod.matures_at / 30);

              // prod.matures_at = divideDate + " Month(s)";

         

              let divideDate = prod.maturityInMonths / 30.4375;

              prod.isMaturityNeg = divideDate

              if (
                prod.fixed_tenor !== "0.00" &&
                divideDate > prod.fixed_tenor
              ) {
                if(prod.fixed_tenor % 1 == 0.00){
                  prod.maturityInMonths = Math.round(prod.fixed_tenor) + " Month(s)";
                }

                else{
                  prod.maturityInMonths = prod.fixed_tenor + " Month(s)";
                }

              } else {
                if (divideDate % 1 > 0.3 && divideDate % 1 < 0.75) {
               
                  if (divideDate < 2) {
                    prod.maturityInMonths = Math.round(divideDate)  + ".5" + " Month(s)";
                  } else {
                    prod.maturityInMonths = Math.round(divideDate)  + ".5" + " Month(s)";
                  }
                } else if (divideDate % 1 >= 0.75) {
                 
                  if (divideDate < 2) {
                    prod.maturityInMonths = Math.round(divideDate)  + " Month(s)";
                  } else {
                    prod.maturityInMonths = Math.round(divideDate)  + " Month(s)";
                  }
                } else if (divideDate % 1 <= 0.3) {
                 
                  if (divideDate < 2) {
                    prod.maturityInMonths = divideDate + " Month(s)";
                  } 
                  if (divideDate < 1) {
                    prod.maturityInMonths = Math.round(divideDate)  + " Month(s)";
                  } 
                  
                  else {
                    prod.maturityInMonths = Math.round(divideDate)  + " Month(s)";
                  }
              }
              let totalAmountUserIsBuyingFor =
                prod.defaultUnitSelected * prod.cost_per_unit;
              let rateOfUser = prod.resell_roi * 0.01;

              let finalAmount =
                parseFloat(rateOfUser) * parseFloat(totalAmountUserIsBuyingFor);

              prod.user_roi =
                parseFloat(finalAmount) +
                parseFloat(totalAmountUserIsBuyingFor);
              prod.user_cost = parseFloat(totalAmountUserIsBuyingFor);
              prod.urlName = prod.name.replace(/\s/g, '');
              return prod;
            }
  
          
          });

          let allProductsForSearch = primary.concat(secondary, resell);
localStorage.setItem('allProductData', JSON.stringify(allProductsForSearch))


          let secondAndResell = secondary.concat(resell);

          let soldOutPaidOut = allProductsForSearch.filter(prod => {
            if (
              prod.units_available == 0 ||
              prod.settlement_status == "settled" ||
              prod.resell_units_available == 0
            ) {
              return prod;
            }
          });


          // This is to remove duplicate products fromm array
          let names = soldOutPaidOut.map(o => o.name)

  
          soldOutPaidOut = soldOutPaidOut.filter(({name}, index) => !names.includes(name, index+1))


          // console.log(Object.values(soldOutPaidOut.reduce((acc,cur)=>Object.assign(acc,{[cur.name]:cur}),{})), 'accurate')

          // console.log(soldOutPaidOut, 'sold out')
          soldOutPaidOut.sort(function(a, b) {
            return prepare_datetime(b.opens_at) - prepare_datetime(a.opens_at);
          });





          let refilterSecondary = secondAndResell.filter(prod => {
            if (
              (prod.resell_units_available > 0 &&
                prod.settlement_status !== "settled") && prod.isMaturityNeg > 0 ||
              (prod.units_available > 0 && prod.settlement_status !== "settled"  && prod.isMaturityNeg > 0)
            ) {
              return prod;
            }
          });

          let secondaryProds = refilterSecondary.map(o => o.name)

  
          refilterSecondary = refilterSecondary.filter(({name}, index) => !secondaryProds.includes(name, index+1))



          refilterSecondary.sort(function(a, b) {
            return prepare_datetime(b.opens_at) - prepare_datetime(a.opens_at);
          });
          
          let refilterPrimary = primary.filter(prod => {
            if (
              prod.units_available > 0 &&
              prod.settlement_status !== "settled"
            ) {
              return prod;
            }
          });


          

          refilterPrimary.sort(function(a, b) {
            return prepare_datetime(a.opens_at) - prepare_datetime(b.opens_at);
          });

          
          let agrigultureTab = refilterPrimary.concat(refilterSecondary, soldOutPaidOut);
          commit('saveAllProducts', agrigultureTab)


          var marketTypes = {
            primaryCategory: refilterPrimary,
            secondaryCategory: refilterSecondary,
            resellCategory: resell.reverse(),
            soldOutPaidOut: soldOutPaidOut
          };

          let categoryData = primary.concat(secondary, resell);

          // localStorage.setItem("categoryData", JSON.stringify(categoryData));

          commit("saveProducts", allProductsForSearch);

          commit("saveMarketTypes", marketTypes);
          // commit("stopPreloader");
          commit("stopPreloader");

          setTimeout(() => {
            commit("startScroll");
          }, 1000);
        })
        .catch(error => {
          commit("stopPreloader");

          if (error.response) {
            helpers.log(error.response);
            if (
           
              error.response.status !== 401
            ) {
              commit("domElements/openNotificationMessage", null, {
                root: true
              });

              commit(
                "domElements/saveNotificationMessage",
                error.response.data.message,
                { root: true }
              );
            }
            if (error.response.data.message == "unauthenticated") {
              router.push("/login");
            }
          }
        });
    },

    getCartItems({ state, commit }) {
      http
        .get(api.addGetDeleteUpdateCart)

        .then(response => {
          helpers.log(response, "cartItems");
          commit("stopPreloader");
          commit("saveTotalBal", response.data.data.total_balance);
          state.cartItemLength = response.data.data.cart.length;
          state.userBalance = response.data.data.balance;
          let cartItems = response.data.data.cart;
          cartItems.filter(function(cartItem) {
            let unitAdded = cartItem.units;

            let totalAmountUserIsBuyingFor = unitAdded * cartItem.cost_per_unit;

            let unitOfAtp = cartItem.vendor_cost_per_unit;
            let atpRate = cartItem.roi;

            if (cartItem.market == "primary") {
              if (totalAmountUserIsBuyingFor < unitOfAtp) {
                if (cartItem.roi > 24) {
                  atpRate = cartItem.roi - 4;
                } else if (cartItem.roi >= 15) {
                  atpRate = cartItem.roi - 2;
                } else if (cartItem.roi < 15) {
                  atpRate = cartItem.roi - 2;
                }
              }
            }

            if (cartItem.market == "secondary") {
              if (cartItem.roi >= 24) {
                atpRate = cartItem.roi - 4;
              } else if (cartItem.roi >= 15) {
                atpRate = cartItem.roi - 2;
              } else if (cartItem.roi < 15) {
                atpRate = cartItem.roi - 2;
              }
            }

            if (cartItem.market !== "resell") {
              let rateOfUser = atpRate * 0.01;
              let finalAmount =
                parseFloat(unitAdded) * parseFloat(cartItem.cost_per_unit);
              cartItem.customerRoi = finalAmount;
              cartItem.roi = atpRate;

              // Total return user is expecting
              let finalAmountUser = rateOfUser * totalAmountUserIsBuyingFor;
              cartItem.totalReturn =
                finalAmountUser + totalAmountUserIsBuyingFor;
            }

            if (cartItem.market == "resell") {
              let rateOfUser = cartItem.resell_roi * 0.01;

              let totalAmountUserIsBuyingAt =
                unitAdded * cartItem.resell_cost_per_unit;

              cartItem.customerRoi = totalAmountUserIsBuyingAt;

              // Total return user is expecting
              let finalAmountUser = rateOfUser * totalAmountUserIsBuyingAt;
              cartItem.totalReturn =
                finalAmountUser + totalAmountUserIsBuyingAt;
            }

          
          });

          commit("saveCartItems", cartItems);
       //   helpers.log("cartsaved", cartItems);
          const cartTotalItemAmount = cartItems
            .map(item => item.customerRoi)
            .reduce((prev, curr) => prev + curr, 0);

          const totalCartUnits = cartItems
            .map(item => item.units)
            .reduce((prev, curr) => prev + curr, 0);

          state.cartTotalAmount = cartTotalItemAmount;
          state.cartTotalUnits = totalCartUnits;

          commit("stopIsFetching");
        })

        .catch(error => {
          if (error.response) {
            if (error.response.status !== 401) {
              commit("domElements/openNotificationMessage", null, {
                root: true
              });
              commit("stopPreloader");
              commit(
                "domElements/saveNotificationMessage",
                error.response.data.message,
                { root: true }
              );
            } else {
              router.push("/market");
            }
          }
        });
    },

    deleteCartItem({ state, commit, dispatch }, payload) {
      commit("startLoader");
      http
        .delete(api.addGetDeleteUpdateCart, {
          data: {
            product_id: payload.product_id,
            units: payload.units,
            market: payload.market
          }
        })

        .then(response => {
          helpers.log(response);
          commit("stopLoader");
          commit("saveNotifyMessage", "product removed successfully");
          commit("openNotification");
          dispatch("getCartItems");
          // dispatch("getCartItems");

          http
            .get(api.addGetDeleteUpdateCart)

            .then(response => {
              if (response.status == 204) {
                state.cartItemLength = undefined;
              }
            });
        })

        .catch(error => {
          if (error.response) {
            helpers.log(error.response);
          }

          if (
         
            error.response.status !== 401
          ) {
            commit("domElements/openNotificationMessage", null, { root: true });

            commit(
              "domElements/saveNotificationMessage",
              error.response.data.message,
              { root: true }
            );
          }

          commit("stopLoader");
        });
    },

    getCategoryData({ state }) {
      let categoryType = localStorage.getItem("categoryType");
      let categoryData = localStorage.getItem("categoryData");
      categoryData = JSON.parse(categoryData);

      state.currentCategory = categoryType;
      helpers.log(categoryData, "type");
      state.categoryArray = categoryData
    
    },

    fundWalletOffline({ state, commit }, payload) {
      commit("startLoader");
      http
        .post(api.payOffline, payload)

        .then(response => {
          helpers.log(payload);
          helpers.log(response);
          commit("stopLoader");
          state.offlinePaymentDetail = response.data.data;
          commit("openOfflineDetailModal");

          let offlineAmount =
            parseFloat(response.data.data.processor_charges) +
            parseFloat(payload.amount);

          commit("saveOfflineMinAmount", offlineAmount);
        })

        .catch(error => {
          if (error.response) {
            if (
           
              error.response.status !== 401
            ) {
              commit("domElements/openNotificationMessage", null, {
                root: true
              });

              commit(
                "domElements/saveNotificationMessage",
                error.response.data.message,
                { root: true }
              );
            }
          }
          commit("stopLoader");
        });
    },

    // withdrawFund({ commit, dispatch }, payload) {
    //   commit("startLoader");
    //   http
    //     .post(api.withdrawFund, payload)

    //     .then(response => {
    //       helpers.log(response);
    //       commit("stopLoader");

    //       dispatch("getAccountOverview");
    //       swal(
    //         "success",
    //         "Withdrawal process started for " +
    //           " " +
    //           "₦" +
    //           payload.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    //         "success"
    //       );
    //     })

    //     .catch(error => {
    //       if (error.response){

    //         if (error.response.data.message !== "unauthenticated") {
    //           swal("error", error.response.data.message, "error");
    //           }
    //       }
    //       commit("stopLoader");
    //     });
    // },

    getAccountOverview({ state, commit }) {
      http
        .get(api.getAccount)

        .then(response => {
          helpers.log(response, "account overview");

          state.accountOverview = response.data.data.user;
          state.userBalance = response.data.data.user.balance;
        })

        .catch(error => {
          if (error.response) {
            if (
           
              error.response.status !== 401
            ) {
              // commit("domElements/openNotificationMessage", null, {
              //   root: true
              // });

              commit(
                "domElements/saveNotificationMessage",
                error.response.data.message,
                { root: true }
              );
            }
          }
        });
    },

    getWalletData({ state, commit }) {
      commit("startPortPreloader");
      commit("emptyWalletData");
      http
        .get(api.getWalletData)

        .then(response => {
          helpers.log(response, "wallet transaction");

let walletData = response.data.data.transactions.filter((data)=>{

return data.amount = data.amount.replace(/,/g, '')
})

commit("saveWalletTransaction",  walletData)
commit("saveWalletData",  response.data.data)
commit("saveWalletBonus", response.data.data.wallet_bonus )
  
    
commit("stopPortPreloader");
     
        })

        .catch(error => {
          if (error.response) {
            if (
           
              error.response.status !== 401
            ) {
              commit("domElements/openNotificationMessage", null, {
                root: true
              });
              commit("stopPortPreloader");
              commit(
                "domElements/saveNotificationMessage",
                error.response.data.message,
                { root: true }
              );
            } else {
              router.push("/market");
            }
          }
        
         
        });
    },
  
    getPortfolio({ state, commit }) {
      commit("startPortPreloader");
      commit("emptyPortfolioData");
      http
        .get(api.getTransaction)

        .then(response => {
          helpers.log(response, "portfolio");

          state.portfolioSummary = response.data.data;
         
          commit('saveServerTime', response.data.server_time)
         let portfolioTransactions = response.data.data.transactions.filter(prod => {
              let date1 = moment(prod.matures_at);
              let date2 = moment(prod.created_at);
              let diff = date1.diff(date2, "days");
        // console.log(prod, prod.units_available, prod.name, 'units Left')
              // check if product is matured by comparing today's date
              if (moment(prod.matures_at) <= moment(response.data.server_time)) {
                prod.isProductMatured = true;
              } else {
                prod.isProductMatured = false;
              }

              if(prod.transfer_data == null){

                prod.transfer_data = {
                  transfer_charge:'',
                  receiver_email:''
              }
              }

              



           






              let dailyInterestPerDay = prod.roi / 100;

              dailyInterestPerDay = dailyInterestPerDay / diff;

              let accruedInterest = dailyInterestPerDay * diff;

              prod.accruedInterest = Math.ceil(accruedInterest);
              let rate = prod.roi * 0.01;
              let finalAmount = rate * (prod.units_available*5000);
prod.interestGottenInDollars = (rate * parseFloat(prod.amount))+parseFloat(prod.amount)
              prod.amountInvested = prod.units_available*5000

            prod.interestGotten = finalAmount.toFixed(0)
              prod.totalReturn =
                parseFloat(finalAmount.toFixed(2)) + parseFloat((prod.units_available*5000));



                if(prod.name.replace(/\s/g, "").toLowerCase() == 'poultry01' || prod.name.replace(/\s/g, "").toLowerCase() == 'poultry06'){
                  let staggeredRate = 10 * 0.01;
                  prod.staggeredActive = true
                  let staggeredPay = staggeredRate * (prod.units_available*5000);
                  let remainingStaggeredPay = prod.totalReturn - staggeredPay 
                  prod.staggeredPay = staggeredPay
                  prod.remainingPay = remainingStaggeredPay
                }

                else{
                  prod.staggeredActive = false

                }
                
                






              return prod;
            });

            portfolioTransactions.sort(function(a, b) {
              return moment(b.created_at) - moment(a.created_at);
            });
            commit("savePortfolioData", portfolioTransactions )

            


            let portfolioEstimatedValueNaira = response.data.data.transactions
            .map(item => item.isProductMatured || item.status == 'sys_rvsl' || item.status == 'sys_trx_rvsl' || item.currency_denomination == 'dollar' ? 0 : parseFloat(item.totalReturn))
            .reduce((prev, curr) => prev + curr, 0);
            
            let portfolioCurrentValueNaira = portfolioTransactions
            .map(item => item.isProductMatured || item.status == 'sys_rvsl' || item.status == 'sys_trx_rvsl'|| item.currency_denomination == 'dollar' ? 0 : parseFloat(item.amountInvested))
            .reduce((prev, curr) => prev + curr, 0);


            // Dollar calc
            let portfolioEstimatedValueDollar = response.data.data.transactions
            .map(item => item.isProductMatured || item.status == 'sys_rvsl' || item.status == 'sys_trx_rvsl' || item.currency_denomination == 'naira' ? 0 : parseFloat(item.interestGottenInDollars))
            .reduce((prev, curr) => prev + curr, 0);
            
            let portfolioCurrentValueDollar = portfolioTransactions
            .map(item => item.isProductMatured || item.status == 'sys_rvsl' || item.status == 'sys_trx_rvsl'|| item.currency_denomination == 'naira' ? 0 : parseFloat(item.amount))
            .reduce((prev, curr) => prev + curr, 0);

            let portfolioCalcValue = {

              currentVal:portfolioCurrentValueNaira,
              totalEstimate:portfolioEstimatedValueNaira,
              currentValInDollar:portfolioCurrentValueDollar,
              totalEstimateInDollar:portfolioEstimatedValueDollar,
            }
  
            commit("saveCurrentPortfolioValue", portfolioCalcValue)

            commit("stopPortPreloader");
        })

        .catch(error => {
          if (error.response) {
            if (
              error.response.status !== 401
             ) {
              commit("domElements/openNotificationMessage", null, {
                root: true
              });

              commit(
                "domElements/saveNotificationMessage",
                error.response.data.message,
                { root: true }
              );
              commit("stopPreloader");
            } else {
              router.push("/market");
              commit("stopPortPreloader");
            }
          }
          
    
        });
    },

    purchaseProduct({ state, commit, dispatch }, payload) {
      commit("startLoader2");
      http
        .post(api.getAllProducts)

        .then(response => {
         // helpers.log(response);

          commit("saveNotifyMessage", "product purchased successfully");
          commit("openNotification");
          dispatch("getCartItems");

          http
            .get(api.addGetDeleteUpdateCart)

            .then(response => {
              if (response.status == 204) {
                state.cartItemLength = undefined;
              }
              router.push("/portfolio");

              try {
                //facebook pixel
                fbq("track", "Purchase", { value: payload, currency: "NGN" });

                // Google Tracking code
                gtag("event", "conversion", {
                  send_to: "AW-703278616/wZeYCMXv9bMBEJjcrM8C"
                });
              } catch {}

          commit("stopLoader2");

            });

        })

        .catch(error => {
          if (error.response) {
            if (
           
              error.response.status !== 401
            ) {
              commit("domElements/openNotificationMessage", null, {
                root: true
              });

              commit(
                "domElements/saveNotificationMessage",
                error.response.data.message,
                { root: true }
              );
            }
          }
          commit("stopIsFetching");
          commit("stopLoader2");
        });
    },

    viewAtp({ state, commit }, payload) {
      commit("startPreloader");
      let categoryData = localStorage.getItem("allProductData");
      
      categoryData = JSON.parse(categoryData);
     
      http
        .get(api.atpDetail + "?atp_id=" + payload)
        .then(response => {
          helpers.log(response, "atpDetail");

          state.atpDetail = response.data.data.atp;

          let atpProds = categoryData.filter(function(prod) {
            if (prod.atp_id == payload) {
              return prod;
            }
          });
          let names = atpProds.map(o => o.id)
        
      let filterData =     atpProds.filter(({id}, index) => !names.includes(id, index+1))
// console.log(atpProds, 'atp pro')

          state.atpProducts = filterData
          commit("stopPreloader");
        })
        .catch(error => {
          if (error.response) {
            helpers.log(error.response, "atpDetail");
            commit("stopPreloader");
          }
        });
    },

    getTags({ state, commit }, payload) {
      

      http
        .get(api.tags)
        .then(response => {
          helpers.log(response, "tags");


commit("saveTags", response.data.data)
        
        })
        .catch(error => {
          if (error.response) {
            helpers.log(error.response, "tags");
          }
        });
    },
    getBookingSettings({ state, commit }, payload) {
      

      http
        .get(api.getBookingSettings)
        .then(response => {
        //  helpers.log(response, "book settings");


commit("saveBookingSettings", response.data.data)
        
        })
        .catch(error => {
          if (error.response) {
            helpers.log(error.response, "booking settings");
          }
        });
    },
    getBookFundsSummary({ state, commit }, payload) {
      commit("stopPreloader");

      http
        .get(api.bookFundsSummary)
        .then(response => {
          // helpers.log(response, "book summary");


          let bookingTotalValueAndInterest = {

            totalAccruedValue:response.data.data.interest_accrued,
            totalBookedValue:response.data.data.total_booked_amount
          }

          commit("saveBookingTotalValue", bookingTotalValueAndInterest)
        })
        .catch(error => {
          if (error.response) {
            helpers.log(error.response, "booking settings");
            commit("stopPreloader");
          }
        });
    },
    getBookingHistory({ state, commit }, payload) {
      

      http.get(api.bookFundsHistory)
        .then(response => {
           helpers.log(response, 'days spent')


          let filteredHistory = response.data.data.reverse().filter((data)=>{

            let dateBought = moment(data.created_at)
            let serverDate =  moment(response.data.server_time)

            let daysLeft = serverDate.diff(dateBought, 'days')
     
            data.numberOfDaySpent = daysLeft
            return data
          })



          // let totalAccruedBookInterenst = filteredHistory
          // .map(item => parseFloat(item.interest_accrued))
          // .reduce((prev, curr) => prev + curr, 0);

          // let totalBookedValue = filteredHistory
          // .map(item => parseFloat(item.booked_amount))
          // .reduce((prev, curr) => prev + curr, 0);


          // let bookingTotalValueAndInterest = {

          //   totalAccruedValue:totalAccruedBookInterenst,
          //   totalBookedValue:totalBookedValue
          // }

          // commit("saveBookingTotalValue", bookingTotalValueAndInterest)

         // helpers.log(filteredHistory, "book history");


commit("saveBookingHistory", filteredHistory)
        
        })
        .catch(error => {
          if (error.response) {
            helpers.log(error.response, "booking settings");
          }
        });
    },
   

    sellProduct({ commit, dispatch }, payload) {
      commit("startLoader");

      http
        .delete(api.getTransaction, {
          data: { tranx_id: payload }
        })

        .then(response => {
          helpers.log(response, "delete api");

          dispatch("getAccountOverview");
          dispatch("getPortfolio");
          commit("stopLoader");
        })
        .catch(error => {
          if (error.response) {
            helpers.log(error.response,);
            if (
           
              error.response.status !== 401
            ) {
              commit("domElements/openNotificationMessage", null, {
                root: true
              });

              commit(
                "domElements/saveNotificationMessage",
                error.response.data.message,
                { root: true }
              );
            }
          }
       
        });
    },

    verifyCardPayment({ state, commit }, payload) {
      http
        .post(api.verifyPaymentByCard, payload)
        .then(response => {
          helpers.log(response, "card verified");
          commit("saveNotifyMessage", "payment Successfull");
          http
            .get(api.getAccount)

            .then(response => {
              state.userBalance = response.data.data.user.balance;
            });
        })
        .catch(error => {
          if (error.response) {
            if (
           
              error.response.status !== 401
            ) {
              commit("domElements/openNotificationMessage", null, {
                root: true
              });

              commit(
                "domElements/saveNotificationMessage",
                error.response.data.message,
                { root: true }
              );
            }
          }
         
        });
    },
    getCartStatus({ state, commit }) {
      http
        .get(api.addGetDeleteUpdateCart)
        .then(response => {
          if (response.status == 204) {
            state.cartItemLength = undefined;
          }
        })
        .catch(error => {
          if (error.response) {
            if (
           
              error.response.status !== 401
            ) {
              commit("domElements/openNotificationMessage", null, {
                root: true
              });

              commit(
                "domElements/saveNotificationMessage",
                error.response.data.message,
                { root: true }
              );
            }
          }

        });
    },
    getVestLockProducts({ state, commit }, payload) {
      // state.vestLoader = true;

      http
        .get(api.getVestLockProduct, {
          params: {
            amount: payload.amount
          }
        })
      
        .then(response => {
     
          helpers.log(response, 'vestLock')

          let products = response.data.data.products


       let   filteredProducts = products.filter((prod)=>{




            let user_roi =
            (100 * parseFloat(prod.roi)) /
            (parseFloat(100) + parseFloat(prod.roi));
          
            user_roi = Math.floor(
              parseFloat(user_roi) - parseFloat(prod.vestlock_spread)
              );
              
              
              let rateOfUser = user_roi * 0.01;
              //  interest calculated
              let finalAmount = rateOfUser * payload;
              
              prod.calcRoi = user_roi;
              prod.calcInterest = finalAmount;
              prod.amountInvested = payload;
          
    
              if(prod.fixed_tenor % 1 == 0.00){
                prod.fixed_tenor = Math.round(prod.fixed_tenor) 
              }
    
              else{
                prod.fixed_tenor = prod.fixed_tenor
              }
    
              
            
              
              return prod
            })
            commit("saveVestLockProducts", filteredProducts);


          commit("saveFirstVestLockProduct", response.data.data.products[0].id)
        
          // check if max purchased amount is more than the user's amount
     

    

        })
        .catch(error => {
          if (error.response) {
            state.vestLoader = false;
            commit("domElements/openNotificationMessage", null, {
              root: true
            });

            commit(
              "domElements/saveNotificationMessage",
              error.response.data.message,
              { root: true }
            );

          }
        });
    },
    getSelectedVestLockProduct({ state, commit },payload){

      let vestLockData = state.vestLockData

      if (
        parseFloat(vestLockData.max_purchase_amount) <
        parseFloat(payload)
      ) {
        commit("domElements/openNotificationMessage", null, {
          root: true
        });
        commit(
          "domElements/saveNotificationMessage",
          "Sorry no investment available for" +
            " " +
            "NGN" +
            payload.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          { root: true }
        );

        return;
      }

        let user_roi =
        (100 * parseFloat(vestLockData.roi)) /
        (parseFloat(100) + parseFloat(vestLockData.roi));
      
        user_roi = Math.floor(
          parseFloat(user_roi) - parseFloat(vestLockData.vestlock_spread)
          );
          
          
          let rateOfUser = user_roi * 0.01;
          //  interest calculated
          let finalAmount = rateOfUser * payload;
          
          vestLockData.calcRoi = user_roi;
          vestLockData.calcInterest = finalAmount;
          vestLockData.amountInvested = payload;
      

          if(vestLockData.fixed_tenor % 1 == 0.00){
            vestLockData.fixed_tenor = Math.round(vestLockData.fixed_tenor) 
          }

          else{
            vestLockData.fixed_tenor = vestLockData.fixed_tenor
          }

          commit("saveVestLockInvestmentData", vestLockData);
          localStorage.setItem("vestLockDataFromLocalStorage",  JSON.stringify(vestLockData) )
      router.push("/vest/summary");
      commit("auth/saveVestRouteVisited", "/vest/summary", { root: true });
      
      localStorage.setItem("investAmount", true)
      },
    getVestPortfolio({ state, commit, dispatch }) {
      http
        .get(api.getVestPortfolio)

        .then(response => {
          helpers.log(response, "vest portfolio");
          let portfolioData = response.data.data;

          portfolioData = portfolioData.filter(function(data) {
            let returnOfInvestment = data.roi * 0.01;
            data.returnOfInvestment = returnOfInvestment * data.amount;
            data.totalReturn =
              parseFloat(data.returnOfInvestment) + parseFloat(data.amount);

            return data;
          });

          if (portfolioData.length > 0) {
            const vestTotalItemAmount = portfolioData
              .map(item => parseFloat(item.amount))
              .reduce((prev, curr) => prev + curr, 0);

            commit("saveVestPortfolio", portfolioData.reverse());

            commit("saveTotalVestAmount", vestTotalItemAmount);
          }

  
        })
        .catch(error => {
          if (error.response) {
            helpers.log(error.response, "vest api");
          }
        });
    },
    getEuroBondHistory({ state, commit, dispatch }) {
      http
        .get(api.euroBondHistory)

        .then(response => {
          helpers.log(response, "euro bond history");
      

          let payload = {
            oneYear:response.data.data.one_year,
            sixMonths:response.data.data.six_months
          }

          localStorage.setItem('euroBond', JSON.stringify(payload))
commit('saveDollarBondHistory', payload)
  
        })
        .catch(error => {
          if (error.response) {
            helpers.log(error.response);
          }
        });
    },
    getEuroBondData({ state, commit, dispatch }) {
      http
        .get(api.getEurobondata)

        .then(response => {
          //helpers.log(response, "euro bond Data");
        
commit('saveServerTime', response.data.server_time)
          let dollarBondData = response.data.data
          let dollarBondMaturityArray = response.data.data.maturity_dates.filter((data)=>{

            let date1 = moment(data.maturity_date);
            let date2 = moment(response.data.server_time);
            let diffInDays = date1.diff(date2, "days");
            let diffInMonths   = date1.diff(date2, "months");

            data.numOfMonths = diffInMonths
            data.numOfDays = diffInDays


            if(diffInMonths <= 2){

              data.period = 'Very Short'
            }

            else if(diffInMonths <= 4){

              data.period = 'Short'
            }
            else if(diffInMonths <= 7){

              data.period = 'Moderate'
            }
            else{
              data.period = 'Extended'
            }

            return data
          })
       
          commit('saveDollarBondData', dollarBondData)
          commit('saveDollarBondMaturityDates', dollarBondMaturityArray)
  
        })
        .catch(error => {
          if (error.response) {
            helpers.log(error.response, "vest api");
          }
        });
    },
    purchaseEuroBond({ state, commit, dispatch }) {
      http
        .post(api.purchaseEuroBond)

        .then(response => {
          //helpers.log(response, "euro bond history");
        

  
        })
        .catch(error => {
          if (error.response) {
            helpers.log(error.response, "vest api");
          }
        });
    },
  }
};

export default marketPlace;
