<template>
  <div id="app" class=" zoom-80">
    <!-- <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
    <router-view/>

    <div style="max-width:200px; z-index:0; position:absolute" class="drift-chat-div mt-4">
      <div
        style="display:flex; bottom:55px; right: 0; z-index:200;  justify-content:flex-end; position:fixed"
        class="pr-4 mb-3"
      >
        <img
          src="./assets/images/chat2.png"
          class="drift-open-chat"
          style="margin-top:0.8rem!important; cursor:pointer"
          height="25"
        >
      </div>
    </div>
  </div>
</template>

<script>
import market from "./components/dashboard/GeneralMarket";
export default {
  name: "app"
};
</script>

<style lang="scss">
@import "assets/css/variables.scss";

@font-face {
  font-family: Circular;
  src: url("./assets/fonts/CircularStd-Book.ttf");
}

@font-face {
  font-family: Circular-bold;
  src: url("./assets/fonts/CircularStd-Bold.ttf");
}

html {
  scroll-behavior: smooth;
  //overflow-x: hidden;
}

body {
  font-family: Circular, sans-serif !important;
  //overflow-x: hidden;
  min-height: 100% !important;
   padding-right: 0 !important;
}

@mixin mq($arg) {
  @media screen and (max-width: $arg) {
    @content;
  }
}

p,
th,
h6,
h4,
h5 {
  margin-bottom: 0rem !important;
}

h1,
h2,
h3,
h4 {
  font-family: Circular-bold, sans-serif !important;
}


h5, h6{
  font-family: Circular, sans-serif !important;
}
td:focus, p:focus{

  outline: none !important;
}
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  outline: none;
}

div:focus {
  outline: none;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $lightBg;
  min-height: 100vh;

     @include mq(767px) {
         padding-bottom: 0px;
    background: $colorWhite;
  }
  @include mq(455px) {
    min-height: 73vh !important;
  }

}

body {
 // overflow-x: hidden;
}

.row {
  min-width: 100%;
  margin-left: 0px !important;
}

.nav-img {
  height: 78px;
}

#content-wrapper {
  min-width: 100%;
}

.separator {
  border-top: 1px solid $borderColor;
}

.sidebar {
  position: fixed;
  margin-top: 70px;
  z-index: 96;
  background-size: cover;
  background: $colorWhite;
  border-right: 1px solid $contentColor;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 40px;

  a {
    color: $colorBlack;
    padding: 17px;
    font-weight: 600;
  }

  a:hover {
    text-decoration: none !important;
    color: #000;
    background: $sideBarActiveCol;
  }

  .router-link-active {
    background: $sideBarActiveCol;
    color: #000;

    .fa {
      color: $themeColor;
    }
  }

  .nav-item .fa {
    padding-right: 15px;
  }
   @include mq(500px) {
  margin-top: 40px;
  }
}

.displ-menu-btn {
  display: none !important;
}

.navbar {
  position: fixed !important;
  width: 100%;
  z-index: 100;

  .sidebar-brand {
    width: 194px !important;
  }

  @include mq(991px) {
    .displ-menu-btn {
      display: inline-block !important;
    }

    .nav-link,
    .router-link-active,
    a {
      padding: 0.2rem 0.5rem ;
    }

    .sidebar-brand {
      width: 171px !important;
    }
  }

  @include mq(500px) {
    padding-right: 0rem !important;
  }
}

.content-passed {
  position: relative;
  min-height: 91vh;
  margin-left: 224px;
  padding-top: 70px;
  background: $lightBg;
  // overflow: auto;
 // overflow-x: hidden;
  padding-bottom: 75px;
  // @include mq(500px){

  //      background: $colorWhite;
  // }
  @include mq(1400px) {
    min-height: 93vh !important;
  }

  @include mq(767px) {
        padding-top: 50px !important;
  }

  @include mq(455px) {
    //background: $colorWhite;
    min-height: 93vh !important;
    padding-bottom: 30px;
  }
}

.sign-up-bonus {
  background-image: url(./assets/images/signup.png);
  background-repeat: no-repeat;
  background-size: cover;

  .bonus-desc {
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    );
    border-radius: 5px;
    min-height: 100px;
    margin: 0 auto;
    padding: 14px;
    min-width: 230px !important;
  }
}

.login-div {
  display: flex;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url(./assets/images/tractor.jpg);
  // background-size: cover;
  z-index: 0;

  .login-container {
    background: $colorWhite;
    min-height: 100vh;
    padding-bottom: 20px;

    .auth-img {
      margin-top: 60px;
    }

    img {
      cursor: pointer;
    }

    @include mq(767px) {
      .auth-img {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }
}

.sign-up {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url(./assets/images/woman.jpg);
}

.forgot {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url(./assets/images/tractor.jpg);
}

.notification-bar {
  min-height: 30px;
  position: relative;
  top: 70px;
  z-index: 99;

  cursor: pointer;

  div {
    background: linear-gradient(
      to right,
      rgba(0, 148, 255, 1),
      rgba(0, 148, 255, 1)
    );
  }
}

.form-group {
  position: relative;

  & + .form-group {
    margin-top: 30px;
  }
}

.form-label {
  position: absolute;
  left: 0;
  top: 10px;
  color: #999;
  cursor: auto !important;

  z-index: 10;
  transition: transform 150ms ease-out, font-size 150ms ease-out;
}

.focused .form-label {
  transform: translateY(-125%);
  font-size: 0.75em;
}

// .form-input :focus ~ .form-label, .form-input:valid {
//   transform: translateY(-125%) !important;
//     font-size: 0.75em !important;
// }

.v-money {
  position: relative;
  padding: 5px;
  width: 100%;
  min-height:50px;
  text-align: center;
  font-weight: 600;
 border-radius:5px !important;
border: 1px solid #828282 !important;

}


.form-input {
  position: relative;
  padding: 12px 0px 5px 0;
  width: 100%;
  outline: 0;
  border: 0;
  box-shadow: 0 1.1px 0 0 #000;
  transition: box-shadow 150ms ease-out;
  text-align: center;
  font-weight: 600;
  background: transparent !important;

  &:focus {
    box-shadow: 0 1.1px 0 0 $themeColor !important;
  }
}

.form-input.filled {
  box-shadow: 0 1.1px 0 0 #000;
}

.theme-text-color {
  color: $themeColor;
}

.form-div {
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  flex-direction: column;

  form {
    min-width: 100%;
  }

  @include mq(701px) {
    min-height: 75vh;
  }
}

.form-group {
  min-width: 100%;
}

.form-group + .form-group {
  margin-top: 15px;
}

.submit-btn {
  min-width: 100%;
  background: $themeColor;
  color: $colorWhite;
  border: 1px solid $themeColor;
  padding: 5px;
  min-height: 50px;
  border-radius: 3px;
}

@media (max-width: 991px) {
  .content-passed {
    margin-left: 0px;
  }
}

.card {
  .card-img-top {
    height: 200px;
    overflow: hidden;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}

.card-footer {
  cursor: pointer;
}

.cart-div {
  background: #fff;
  position: fixed;
  min-height: 60px;
  width: 100%;
  z-index: 20;
  color: #000;
  text-align: right;
  top: 70px;
  display: flex;
  box-shadow: -1px 15px 33px 4px rgba(0, 0, 0, 0.15);
  align-items: center;
  justify-content: flex-end;
}

.fade-elem {
  pointer-events: none !important;
  opacity: 0.6;
}

.fade-elem-2 {
  pointer-events: none !important;
  opacity: 0.9;
}

.fade-elem-3 {
  font-weight: 600;
  color: #828282;
  cursor: pointer !important;
}

.fade-elem-4 {
  opacity: 0.4;
}



.hide-elem {
  display: none !important;
}
.show-elem {
  display: block !important;
}
.show-elem-inline {
  display: inline !important;
  text-align: center !important;
  font-size: 35px !important;
  z-index: 20 !important;
}

.fa-angle-down {
  z-index: 2 !important;
}

.rm-frm-cart {
  background: red !important;
  color: #fff !important;
}

.mt-12 {
  margin-top: 12px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-100 {
  margin-top: 100px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-100 {
  padding-top: 100px;
}

div.item {
  border-radius: 10px !important;
  display: inline-block;
  width: 16.4%;
  margin-right: 5px;
  margin-bottom: 0px;
  border-radius: 3px;
  transition: margin-top 2s ease-in-out 2s;
  cursor: pointer;
  z-index: 1;
  transition: all 0.6s cubic-bezier(0.41, 0.094, 0.54, 0.07) 0s;

  &:hover {
    // transform: translateY(-10px);
    box-shadow: rgba(45, 45, 45, 0.05) 0px 2px 2px,
      rgba(49, 49, 49, 0.05) 0px 4px 4px, rgba(42, 42, 42, 0.05) 0px 8px 8px,
      rgba(32, 32, 32, 0.05) 0px 16px 16px, rgba(49, 49, 49, 0.05) 0px 32px 32px,
      rgba(35, 35, 35, 0.05) 0px 64px 64px;
    //  transform: translate(0, -4px);
    z-index: 1;
  }

  .img-div {
    min-height: 159px;
    overflow: hidden;
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding: 10px;
    box-shadow: inset 2000px 0 0 0 rgba(0, 0, 0, 0.4);
    color: #fff;
    background-size: cover;
    border-radius: 10px;
    
  }

  img {
    background-size: cover;
  }

  @include mq(2147px) {
    width: 16.3%;
  }
  @include mq(1634px) {
     width: 19.5%;
  }


  @include mq(1250px) {
    width: 49.3%;
  }

  @include mq(1150px) {
    width: 32.3%;
  }

  @include mq(700px) {
    width: 48.3%;
  }
}

.img-div-1 {
  box-shadow: inset 2000px 0 0 0 rgba(0, 0, 0, 0.4);
  min-height: 159px;
  overflow: hidden;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding: 10px;
  box-shadow: inset 2000px 0 0 0 rgba(0, 0, 0, 0.4);
  color: #fff;
  background-size: cover;
  border-radius: 10px;
  opacity: 0;
  display: none;
  width: 16%;

  @include mq(1450px) {
    width: 24.5%;
  }

  @include mq(1250px) {
    width: 49.3%;
  }

  @include mq(1150px) {
    width: 32.3%;
  }

  @include mq(700px) {
    width: 48.3%;
  }
}

.showHideDiv {
  display: block !important;
}

.display-in {
  display: inline;
}

.hide-div {
  animation: slide 0.5s ease 0.5s forwards;
}

@keyframes slide {
  20% {
    opacity: 0.9;
  }

  60% {
    opacity: 0.7;
  }

  100% {
    opacity: 0;
    display: none !important;
  }
}


.product-cover{

background:$colorWhite;
 border-radius: 20px;
 
  padding:10px;
  
}

div.info {
 background: #F5F5F5;
  float: left;
  clear: both;
  min-width: 100%;
 // margin: 10px 25px 30px 0;
  min-height: 300px;
  display: none;
  // border: 1px solid lighten($borderColor, 12%);
  border-radius: 10px;

  z-index: 3;
  animation-delay: 1s;
  animation: moveDown 1s;
  transition: 0.9s;
    margin-top: 36px;
  @keyframes moveDown {
    0% {
      opacity: 0;
      transform: translate(0, -26px);
      transition: 0.9s;
    }

    100% {
      opacity: 1;
      transform: translate(0, 0px);
      transition: 0.9s;
    }
  }

  .close-btn {
    display: flex;
    // justify-content: flex-end;
    min-width: 100%;
    margin-bottom: 10px;
  }

  .investment-details {
    display: flex;
    padding: 10px;

    justify-content: space-between;

    div {
      flex: 1;
      flex-basis: 25%;
    }

    .img-detail {
      flex-basis: 16%;
      flex-grow: 1;
      width: 16%;
    }

    .product-detail {
      flex-grow: 10;
      flex-basis: 35%;

      h3 {
        font-weight: bold;
      }
    }

    .cart-point {
      flex-basis: 20%;
      flex-grow: 3;
      margin-top: 3px !important;
    }

    @include mq(1440px) {
      .product-detail {
        flex-grow: 5;
        flex-basis: 20% !important;
      }

      .cart-point {
        flex-basis: 20% !important;
        flex-grow: 3;
      }
    }

    @include mq(1100px) {
      .product-detail {
        flex-grow: 5;
        flex-basis: 20% !important;
      }

      .cart-point {
        flex-basis: 30% !important;
        flex-grow: 3;
      }
    }

    .crt-div {
      display: flex;
      flex-direction: column;
      background: $colorWhite;
      min-height: 140px;
    
      border-radius: 5px;
      padding: 15px;
      color: $colorBlack;

      div {
        min-height: 30px;
        align-items: center;

        p {
          flex-grow: 1;
        }
      }

      div:last-child {
        border-bottom: 0.5px solid transparent;
      }
    }

    .add-units {
      min-height: 50px;
      background: $colorWhite;

      border-radius: 5px;

      .cart-input {
        width: 90px;
        border-radius: 6px;
        border: 1px solid transparent;
        font-size: 29px;
      }

      .cart-input:focus {
        outline: none !important;
      }

      .fa {
        cursor: pointer;
         color: #0094FF;
        font-size: 20px;
      }

      h6 {
        padding: 1px 15px;
        padding-top: 5px;
      }
    }

    button {
      background: #0094FF;
    //  min-width: 100%;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
   //   height: 40px;
      color: $colorWhite;
      border: 1px solid #0094FF;
      outline: none;
      padding:0px 15px;
      border-radius: 5px;
    }

    @include mq(768px) {
      flex-direction: column;
    }
  }

  @include mq(768px) {
    min-width: 100%;
  }
}

.add-shadow {
  // box-shadow: -1px 15px 33px 4px rgba(0, 0, 0, 0.35);
  box-shadow: rgba(45, 45, 45, 0.05) 0px 2px 2px,
    rgba(49, 49, 49, 0.05) 0px 4px 4px, rgba(42, 42, 42, 0.05) 0px 8px 8px,
    rgba(32, 32, 32, 0.05) 0px 16px 16px, rgba(49, 49, 49, 0.05) 0px 32px 32px,
    rgba(35, 35, 35, 0.05) 0px 64px 64px;
}

.atp-preview {
  display: flex;
  min-height: 110px;
  align-items: flex-end;

  div {
    flex: 1;
  }

  .atp-roi {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
  }

  p {
    font-size: 15px;
  }
}

.add-curved-radius {
  border-radius: 10px;
  border: 0.5px solid $borderColor;
  padding: 3px 7px;
}

.fa-plus,
.fa-minus {
  transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;

  // &:hover {
  //   transform: scale(1.2);
  // }

    // &:active {
    //   transform: translate(0, 3px) scale(1.2);
    // }
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

table tr {
  border: 1px solid #ddd;
  padding: 0.35em;
  background: $colorWhite;
}

table th,
table td {
  padding: 0.625em 0.625em 0.625em 0em !important;
  text-align: left;
  font-size:14px !important;
  //   border-left: 1px solid $contentColor;
  //   border-right: 1px solid $contentColor;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  vertical-align: middle !important;
}

table td {
  // white-space: nowrap;
  overflow: hidden;
  text-align: left !important;
}

table td:first-child,
th:first-child {
  padding: 0.757em !important;
}

.flex-items {
  display: flex;
  flex-basis: 30%;

  div {
    justify-content: space-evenly;
    flex-basis: 40%;
  }
}

/*  Wallet vue Css */
.invest-cost-div {
  min-height: 200px;
  background: $colorWhite;
  flex-flow: column;
}

.inner-div-wallet {

  justify-content: center;
  align-items: center;
  min-height: 50px !important;
  cursor: pointer;
  background: $colorWhite;

  // #fund-btn {
  //   box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  // }

  #fund-btn2 {
    padding: 10px 14px;
  }

  .withdraw-btn {
    border: 1px solid $payActiveColor !important;
  }

  @include mq(701px) {
    min-height: 40px !important;
  }
}

.display-div {
  display: flex;
  clear: both;
  min-width: 100%;
  
  div,
  p {
    flex-grow: 1;
  }
}



.flex-btn-vert {
  display: flex;
  min-height: 70px;
  justify-content: center;
  padding: 15px;
  background: $themeColor;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: $colorWhite;
  align-items: center;
  text-align: center;
  cursor: pointer;

  div {
    flex-grow: 1;
  }

  i {
    font-size: 31px;
  }
}

.modal-footer {
  justify-content: center !important;
}

// .modal-dialog{
//    max-width: 439px !important;

//   margin: auto;
//   .eye-div i {

//     left: 310px;
    
// }
// }

.mobile-sum-div-port {
  min-height: 100px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(59, 64, 69, 0.1) !important;
  display: flex;
  padding: 10px;
  background: #fff !important;

  div {
    flex-grow: 1;
    flex-basis: 50%;
  }

  .first-child {
    flex-basis: 40%;
  }

  hr {
    margin: 0rem !important;
    
  }
}
 
.eye-div {
  position: relative;

  i {
    position: absolute;
    left: 357px;
    top: 15px;
    cursor: pointer;

    @include mq(500px) {
      left: 330px;
    }

    @include mq(370px) {
      left: 240px;
    }
  }
}

.align-items-bottom {
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin-bottom: 0em !important;
  }

  @include mq(768px) {
    min-height: 50px;
    margin-bottom: 20px;
  }
}

.mobile-product-card {
  min-width: 150px;
  max-width: 130px;
  border-radius: 6px;
  min-height: 112px;
  align-items: flex-end;
  color: $colorWhite;
  margin-right: 6px;
  max-height: 160px;
  // box-shadow: inset 2000px 0 0 0 rgba(0, 0, 0, 0.6);
  // background-image: url(./assets/images/img-tag.png);
  background-size: cover;

  p,
  span {
    font-size: 12px !important;
  }

  div {
    display: flex;
    min-height: 158px;
    flex-flow: column;
    justify-content: flex-end;
  }
}

select {
  min-width: 100%;
  height: 46px;
}

.see-all {
  display: flex !important;
  justify-content: center;
  align-items: center;
  min-height: 175px;
  flex-flow: column;
  min-width: 130px;
  background: $colorWhite !important;
  border: 1px solid $themeColor !important;
  border-radius: 6px;
  font-weight: 600 !important;
}

.cart-total-bg {
  background: linear-gradient(0deg, #f1faf0, #f1faf0),
    linear-gradient(0deg, #e2e3e7, #e2e3e7),
    linear-gradient(0deg, #f2f2f2, #f2f2f2), #fafafa;
  padding: 0.75rem 1rem 1rem 0.3rem !important;
}

.table-head {
  background: $colorWhite;
}
.table-hover tbody tr:hover {
    color: #212529;
    background-color: #fafafa;
}
.theme-btn {
  background: $themeColor;
  color: $colorWhite;
  border: 1px solid $themeColor;
  max-width: 120px;
  min-width: 120px;
  border-radius: 3px;
  min-height: 35px;
  padding: 10px 14px;
  font-size: 13px;

  @include mq(767px) {
    max-width: none;
  }
}

.theme-btn-2 {
  background: $colorWhite;
  color: $colorBlack;
  
  border-radius: 5px;
  min-height: 35px;
  padding: 10px 14px;
  font-size: 13px;
}

.remove-btn {
  background: $removeElemBg;
  border: 0.5px solid $removeElemBg;
  min-width: 120px;
  min-height: 35px;
  border-radius: 3px;
  color: $colorRed;
  font-size: 13px;
  padding: 10px 14px;
}

.withdraw-btn {
  background: #f1faf0;
  border: 0.5px solid #58b46f;
  min-width: 120px;
  min-height: 35px;
  border-radius: 3px;
  color: $themeColor;
  font-size: 13px;
  padding: 10px 14px;

  @include mq(1290px) {
    min-width: 90px;
  }
}

.matured {
  background: #e9ecef;
  min-width: 120px;
  min-height: 35px;
  border: 0.5px solid #e9ecef;
  border-radius: 3px;
}

.color-red {
  color: $colorRed !important;
}

.color-red {
  color: $colorRed !important;
}

.theme-color {
  color: $themeColor !important;
}

.wallet-div {
  background: $colorWhite;
  flex-grow: 6;
  flex-basis: 60%;

  padding: 20px;
  border-radius: 5px;
  min-height: 87vh;
}

.acc-inv-cost {
  flex-grow: 1;
  flex-basis: 20%;
  border-radius: 10px;

  @include mq(1400px) {
    flex-basis: 30%;
  }
}

.position-el {
  display: flex;
  justify-content: center;
  align-items: center;
}

.position-el-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  flex-direction: column;
}

.modal-header {
  padding: 1rem 2em !important;
  border-bottom: 1px solid transparent !important;

  @media(max-width:767px){
    padding: 1rem 0.6em !important;
  }
}

.modal-body {
  padding: 1rem 2em !important;

  .card-body {
    padding: 0rem !important;

    .fa {
      color: $themeColor !important;
    }
  }

  @include mq(767px) {
    padding: 1rem 0.6em !important;
  }
}

.modal-footer {
  background: $themeColor;
  color: $colorWhite;
  min-height: 80px;
  cursor: pointer;
}

.modal-content {
  border: transparent !important;
  border-radius: 0.6rem !important;
  z-index: 9 !important;

  .card-header {
    color: $themeColor;
    font-weight: 600;
  }

  .card {
    border: transparent !important;
    background: $colorWhite !important;
  }

  // input {
  //     opacity: 0 !important;
  // }

  label {
    cursor: pointer;
  }

  .close {
    background: linear-gradient(0deg, #faf0f0, #faf0f0),
      linear-gradient(0deg, #f0f0f0, #f0f0f0), #c4c4c4 !important;
    border-radius: 50% !important;
    border: 1px solid $sellItemBg !important;
    height: 30px !important;
    width: 30px !important;
    margin: -4px !important;
    position: relative;
    opacity: 1;

    span {
      position: absolute;
      left: 9px;
      top: 3px;
      color: $colorRed;
    }
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: $themeColor !important;
  background-color: $themeColor !important;
  cursor: pointer !important;
}

button:focus {
  outline: none !important;
}

.pay-active {
  background: $payActiveColor;
  // border: 1px solid #E2E3E7;
  border-radius: 5px;
  transition: 0.9s;
  cursor: pointer;
}

.pay-option:hover {
  transition: 0.9s;
  background: $payActiveColor;
}

.mobile-product-div {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;

  div {
    margin: 2px;
    flex-grow: 1;
    flex-basis: 30%;
    cursor: pointer !important;

    @include mq(500px) {
      flex-basis: 40%;

      &:last-child {
        max-width: 240px;
        flex-grow: 0;
        flex-basis: 48%;
      }
    }
  }
}
.active-tr {

    -webkit-animation: fadein 4s ease 3;

    background: #fff !important;
   animation: fadein 4s ease 3;

}

@keyframes fadein {
  0% {
    background: lightblue;
  }
  50% {
    background: #fff;
  }
    70% {
    background: lightblue;
  }
    80% {
    background: #fff;
  }
  100% {
    background: lightblue;
  }
}


.product-img-div {
  min-height: 80px;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  cursor: pointer !important;
  flex-flow: column;
  justify-content: flex-end !important;
  background-size: cover;
}

.mobile-product-detail {
  position: fixed;
  left: 220px;
  width: 100%;
  height: 100%;
  z-index: 19;
  background: #f9f9f9;
  display: flex;
  top: 54px;
  overflow-y: auto;
  justify-content: center;

  @media (max-width: 991px) {
    left: 0px;
  }

  li {
    list-style-type: none;
    padding-bottom: 10px;

    i {
      font-size: 12px;
    }
  }

  ul {
    padding-left: 0px !important;
  }

  .navbar {
    background: $colorWhite;
    min-height: 50px;
  border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    .add-units {
      min-width: 100%;
      border-radius: 5px;

      .cart-input {
        width: 90px;
        border-radius: 6px;
        border: 1px solid $themeColor;
      }

      .cart-input:focus {
        outline: none !important;
      }

      .fa {
        cursor: pointer !important;
        color: $themeColor;
      }

      h6 {
        padding: 1px 15px;
        padding-top: 5px;
      }
    }

    .cart-input {
      width: 90px;
      border-radius: 6px;
      border: 1px solid $themeColor;
    }

    .cart-input:focus {
      outline: none !important;
    }

    .fa {
      cursor: pointer;
      color: $themeColor;
    }

    h6 {
      padding: 1px 15px;
      padding-top: 5px;
    }

    button {
      background: $themeColor;

      min-width: 100% !important;
      border-radius: 5px;
      height: 50px;
      font-size: 13px;
      color: $colorWhite;
      border: 1px solid $themeColor;
      outline: none;
    }
  }

  .input-roi-div {
    display: flex;
    padding: 10px 0px;

    div {
      flex-grow: 1;
    }
  }
}

.theme-color-und {
  cursor: pointer;
  color: $themeColor;
  text-decoration: underline;
}

.search-input {
  .form-input {
    width: 30%;
    padding: 0px;
    text-align: left !important;
  }
}

.relat-abs-ele {
  position: relative;

  i {
    position: absolute;
    right: 9px;
    bottom: 2px;
  }
}

.sticky-div {
  position: sticky;
  top: 71px;
  background: red;
  padding: 5px;
  z-index: 611;
  width: 92%;
  background: $colorWhite;
  box-shadow: rgba(0, 0, 0, 0.02) 1px 7px 15px;
  transition: 0.9s;
}

.vertical-align-bottom {
  display: flex;
  align-items: flex-end;
}

.vertical-align-center {
  display: flex !important;
  align-items: center;
  min-height: 10px;
}

.cart-count {
  height: 15px !important;
  width: 15px !important;
  background: $errorColor !important;
  border-radius: 50% !important;
  font-size: 11px;
  position: absolute;
  color: $colorWhite;

  @include mq(769px) {
    padding-left: 4px;
  }
}

.error-col {
  color: $errorColor;
}

.success-status {
  color: $settled;
}

.payout {
  background: $lightBg;
  padding: 10px;
}

.withdraw-btn-mob {
  display: flex;
  background: #f1faf0;
  border: 0.5px solid #58b46f;
  min-width: 120px;
  min-height: 35px;
  border-radius: 3px;
  color: $themeColor;
  font-size: 13px;
  padding: 10px 14px;
  margin-bottom: 50px;

  div {
    flex-grow: 1;
  }
}

.buy-withdr-btn {
  display: flex;
  background: $themeColor;
  border: 0.5px solid #58b46f;
  min-width: 100%;
  min-height: 35px;
  border-radius: 3px;
  color: #f1faf0;
  font-size: 13px;
  padding: 10px 14px;

  div {
    flex-grow: 1;
  }
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}

p,
.theme-btn {
  font-size: 14px;

  @include mq(767px) {
    font-size: 13px;
  }
}

h5,
h2,h6 {
  @include mq(1150px) {
    font-size: 17px !important;
  }

  @include mq(767px) {
    font-size: 14px !important;
  }
}

h4 {
  @include mq(1150px) {
    font-size: 19px !important;
  }

  @include mq(767px) {
    font-size: 18px !important;
  }
}

.portfolio-img-div {
  background-repeat: no-repeat !important;
  min-width: 100%;
  background-size: cover;
  box-shadow: inset 2000px 0 0 0 rgba(0, 0, 0, 0.5);
  min-height: 200px;
  padding: 15px;
  color: $colorWhite;
  border-radius: 5px;

  .display-div {
    div {
      flex-basis: 30%;
    }
  }

  button {
    background: $colorWhite;
    height: 45px;
    border-radius: 5px;
    min-width: 90px;
    color: $themeColor;
  }
}

.sell-item {
  background: $sellItemBg;
  min-height: 150px;
  border: 0.5px solid $sellBorder;
  border-radius: 5px;
  max-width: 100%;

  div {
    padding: 10px;
    flex-basis: 50%;
  }

  .unit-block-parent {
    padding: 0px;
    border-left: 0.5px solid $sellBorder;
    display: flex;
    flex-flow: column;
    box-sizing: border-box;

    div {
      flex-basis: 50%;
    }
  }

  .unit-block {
    border-bottom: 0.5px solid $sellBorder;
  }
}

.cancel-sale,
.remove-cart {
  background: linear-gradient(0deg, #f1faf0, #f1faf0),
    linear-gradient(0deg, #58b46f, #58b46f),
    linear-gradient(0deg, #b9c1d9, #b9c1d9),
    linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #556080, #556080),
    linear-gradient(0deg, #000000, #000000), #cd1c44;
  border-radius: 0px 0px 0px 10px;
  min-height: 70px;
  color: $colorBlack;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
}

.proceed-to-sale,
.cancel-cart {
  background: linear-gradient(0deg, #58b46f, #58b46f),
    linear-gradient(0deg, #51caee, #51caee),
    linear-gradient(0deg, #b9c1d9, #b9c1d9),
    linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #556080, #556080),
    linear-gradient(0deg, #000000, #000000), #cd1c44;
  border-radius: 0px 0px 10px 0px;
  color: $colorWhite;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
}

.remove-cart {
  border-radius: 0px 0px 10px 0px;
}

.cancel-cart {
  border-radius: 0px 0px 0px 10px;
}

.withdraw-bal {
  background: linear-gradient(0deg, #fafafa, #fafafa),
    linear-gradient(0deg, #ffffff, #ffffff), #23afd9;
  min-height: 60px;
  padding: 10px;
}

.withdraw-input,
.fund-input {
  padding: 10px;

  input {
    opacity: 1 !important;
  }
}

.fixed-search {
  position: fixed;
  min-height: 70px;
  background: $colorWhite;
  width: 100%;
  margin-top: 50px;
  display: flex;
  top: 20px;

  .display-div {
    padding: 10px;
    max-width: 80%;
    box-sizing: border-box;
  }

  .search-div {
    margin-right: 13vw;
    position: relative;
    display: flex;
    justify-content: flex-end;

    .btn-search {
      position: absolute;
    }

    @include mq(1440px) {
      margin-right: 17vw;
    }

    @include mq(1335px) {
      margin-right: 20vw;
    }

    @include mq(1024px) {
      margin-right: 24vw;
    }

    @include mq(991px) {
      margin-right: 2vw;
    }
  }
}

.trans-modal {
  .withdraw-bal {
    text-align: center;
    min-height: 40px;
  }

  .withdraw-input {
    padding-left: 0px !important;
    min-height: 60px !important;
    // border-bottom: 0.5px solid $lightBorder2;
  }

  .fund-input {
    padding-left: 0px !important;
    min-height: 60px !important;
  }

  .withdraw-input-last {
    padding-left: 0px !important;
    min-height: 60px !important;

    .ref-block {
      border: 1px solid #bdbdbd;
      padding: 10px;
    }
  }

  .title-label {
    color: #828282;
  }

  .note-div {
    min-height: 100px;
    background: $lightBg;
    padding: 15px;
  }
}

.copy-to-clip {
  background: $copyToClipbg;
  min-height: 50px;
  border-radius: 3px;
  text-align: center;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

::placeholder {
  opacity: 0.3;
}

.cart-btn {
  text-align: center;
  position: relative !important;
}

.loader {
  position: absolute;
  top: 50%;
  height: 15px;
  width: 15px;
  background-color: transparent;
  box-sizing: border-box;
  opacity: 1;
  border-radius: 50%;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  border-right: 2px solid rgba(#fff, 0.35);
  transition: all 0.5s ease;
  animation-name: spin;
  animation-duration: 0.75s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@-moz-keyframes spin {
  from {
    -moz-transform: translateY(-50%) rotate(0deg);
  }

  to {
    -moz-transform: translateY(-50%) rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: translateY(-50%) rotate(0deg);
  }

  to {
    -webkit-transform: translateY(-50%) rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: translateY(-50%) rotate(0deg);
  }

  to {
    transform: translateY(-50%) rotate(360deg);
  }
}

.pre-loader {
  position: fixed;
  left: 220px;
  top: 50px;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
    top: 40%;
    right: 60%;
    border-top: 2px solid $themeColor;
    border-left: 2px solid $themeColor;
    border-bottom: 2px solid$themeColor;
    border-right: 2px solid rgba(255, 255, 255, 0.35);

    @media (max-width: 991px) {
      right: 50%;
    }
  }

  @media (max-width: 991px) {
    left: 0px;
  }
}

.spinner-border {
  display: inline-block;
  width: 10px !important;
  height: 10px !important;
}

.dropdown-item {
  cursor: pointer;

  &:focus {
    background: transparent !important;
  }

  &:active {
    background: transparent !important;
  }
}

.empty-cart {
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.head-font {
  font-family: Circular-bold, cursive !important;
}

.profile-div {
  display: flex;
  // min-height: 50vh;
  padding-top: 70px;
  max-width: 50vw;
  margin: 0 auto;
  flex-wrap: wrap;

  @include mq(1450px) {
    max-width: 60vw;
  }

  @include mq(1110px) {
    max-width: 100vw;
  }

  @include mq(674px) {
    padding-top: 20px;
    flex-direction: column !important;
  }

  div {
    flex-grow: 1;
    flex-basis: 40%;
  }

  .profile-info {
    display: flex;

    .avatar-img {
      margin: 15px;
      flex-grow: 1;
      flex-basis: 30%;
      // height: 50px;
    }

    .profile-data {
      flex-grow: 4;
      flex-basis: 50%;
      // height: 90px;
    }
  }

  .prof-cover {
    border-radius: 8px;

    border: 1px solid $contentColor;
    background: $colorWhite;
    max-width: 450px;

    @include mq(1440px) {
      margin-right: 0px !important;
      margin-bottom: 20px;
      max-width: 420px;
    }

    @include mq(1200px) {
      margin-right: 0px !important;
      margin-bottom: 20px;
      max-width: 400px;
    }

    @include mq(767px) {
      margin-right: 0px !important;
      margin-bottom: 20px;
      max-width: 100%;
    }

    .avatar-div {
      // min-height: 250px;

      @include mq(674px) {
        min-height: 270px;
        flex-direction: column !important;

        .profile-data {
          margin-left: 14px;
          margin-top: 5px !important;
        }

        .position-el {
          margin-top: 42px !important;
        }
      }
    }

    .position-el {
      cursor: pointer;
      margin: 15px;

      @include mq(674px) {
        margin: 25px 15px;
      }
    }
  }
}

.add-link:hover{

  text-decoration:underline;
}

.select2 {
  width: 100% !important;
}

.select2-container .select2-selection--single {
  height: 44px !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  top: 9px !important;
}

.select2-container--default .select2-selection--single {
  outline: none;
  padding-top: 7px !important;
}

.point-cursor {
  cursor: pointer;
}

.slick-product {
  // margin-left: 3px;
  span {
    font-size: 12px;
  }
}

.no-border-no-bg {
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer !important;
  font-size: 17px;
}

.card-mobile,
.dropdown-menu {
  -webkit-box-shadow: 0 4px 8px rgba(59, 64, 69, 0.1) !important;
  box-shadow: 0 4px 8px rgba(59, 64, 69, 0.1) !important;
  border: 1px solid transparent !important;
}

.card-mobile {
  p {
    font-size: 12px !important;
  }
}

.width-100 {
  width: 100% !important;
}

.width-50 {
  width: 10%;
}

.width-70 {
  width: 70%;
}

.width-90 {
  width: 90%;
}
.fw-600 {
  font-weight: 600 !important;
}

.progress {
  min-height: 7px !important;
  height: 7px !important;
  width: 120px !important;

  .progress-bar {
    padding: 0px 10px;
  }

  .bg-success {
    color: $colorBlack;
   background-color:#B7F2B6 !important;
    font-weight: 600;
  }
    @include mq(380px) {
    width: 102px !important;
  }
}

.nav-subheading {
  @include mq(380px) {
    font-size: 10px !important;
  }
}

.mobile-sum-div {
  min-height: 100px;
  border-radius: 10px;
  max-width:100%;
  overflow:hidden;
  
  padding: 10px;
  margin-top:20px;
        background-repeat: round;
background-image: url(./assets/images/walletBg.png);

}

.wallet-bonus-btn{

  @media(max-width:1199px){

    flex-direction:column;
      align-items: flex-end;

     .display-div{

       min-width:200px;
     }
  }
}

.btn-link {
  color: $colorBlack !important;
}

.sold-btn {
  background: $lightBg !important;
  color: #bdbdbd !important;
  font-weight: bold;
}

.card-header {
  background: $lightBg !important;
  cursor: pointer !important;
  margin-bottom: 5px !important;
  border: 1px solid transparent !important;
}

.card .card-header[data-toggle="collapse"] {
  padding: 0.75rem 0.25rem 0.75rem 0.25rem;

  .btn-link {
    text-align: left;
  }

  @include mq(457px) {
    .btn-link {
      // font-size: 12px;
    }
  }
}

.card .card-header[data-toggle="collapse"]::after {
  display: none;
}

.accordion {
  padding: 0px 5px;

  .card {
    border: 1px solid transparent !important;
  }

  @include mq(457px) {
    padding: 0px 0px;
    // font-size: 12px;
  }
}

.shadow-r {
  -webkit-box-shadow: 0 4px 8px rgba(59, 64, 69, 0.1) !important;
  box-shadow: 0 4px 8px rgba(59, 64, 69, 0.1) !important;
}

// AD TOOL
.parent-tool-div {
  //      background: linear-gradient(
  //     to bottom,
  //     rgba(0,0,0, 0.2)
  //   ),url(./assets/images/farm.jpg);

  //  background: #F7F9FA;
  background: linear-gradient(
      to right,
      rgba(225, 236, 244, 0.93),
      rgba(225, 236, 244, 0.93)
    ),
    url(./assets/images/line.png);
  min-height: 100vh;
  position: relative;
  font-family: Circular-bold;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  padding: 20px;
  background-size: 100% 100%;
  background-repeat: repeat;

  .search-div {
    max-width: 500px;
    min-width: 500px;
    overflow: hidden;
    margin: 0 10px;
    box-shadow: 0 4px 8px rgba(59, 64, 69, 0.2) !important;
    background: #fff;
    position: relative;
    padding: 20px;
    min-height: 400px;
    border-radius: 5px;
    // border: 2px solid #f0f2f3;
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.93),
        rgba(255, 255, 255, 0.93)
      ),
      url(./assets/images/line.png);
    background-size: 150% 150%;
    background-repeat: repeat;

    ul {
      padding-left: 17px;
      margin-bottom: 0rem !important;

      p {
        display: inline;
      }
    }

    img {
      cursor: pointer;
    }

    .atp-preview {
      border: 1px solid #f0f2f3;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 70px;
      margin-bottom: 20px;
      cursor: pointer;
    }

    .requid-sign-up {
      min-height: 100px;
      box-shadow: 0 4px 8px rgba(59, 64, 69, 0.2) !important;
      background: #1d9bd1;
      border-radius: 5px;
      border: 2px solid #1d9bd1;
      padding: 10px;
      color: $colorWhite;
      overflow: hidden;
      display: flex;
      align-items: center;

      button {
        background: $colorWhite;
        border: 1px solid $colorWhite;
        min-width: 150px;
        border-radius: 5px;
        min-height: 35px;
        box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
          0 8px 16px -8px rgba(0, 0, 0, 0.1),
          0 -6px 16px -6px rgba(0, 0, 0, 0.025);
      }

      @include mq(500px) {
        flex-direction: column;
      }
    }

    .prod-div {
      box-shadow: 0 4px 8px rgba(59, 64, 69, 0.2) !important;
      background: #fff;
      margin-bottom: 10px;
      margin-top: 15px;
      min-height: 50px;
      padding: 10px;
      border-radius: 5px;
      display: flex;
      // border: 2px solid #f0f2f3;
      align-items: center;
    }

    @include mq(767px) {
      .col-md-6 {
        padding-left: 0px;
      }
    }

    @include mq(700px) {
      margin: 10px 0px;
      min-width: 489px;
      max-width: 489px;
    }

    @include mq(500px) {
      margin: 10px 0px;
      min-width: 340px;
      max-width: 370px;
    }

    @include mq(360px) {
      margin: 10px 0px;
      min-width: 310px;
      max-width: 310px;
    }
  }

  p {
    font-family: Circular;
  }
}

.sidebar .nav-item .nav-link[data-toggle="collapse"].collapsed::after {
  content: "\f105" !important;
}

.sidebar .nav-item .nav-link[data-toggle="collapse"]::after {
  width: 1rem !important;
  text-align: center !important;
  float: right !important;
  vertical-align: 0 !important;
  border: 0 !important;
  font-weight: 900 !important;
  content: "\f107" !important;
  font-family: FontAwesome !important;
}

.guest-auth-btn {
  @include mq(500px) {
    padding: 10px 5px !important;
    min-width: 66px !important;
    margin: 0px 7px;
  }
}

.toast-icon {
    @include mq(480px) {
    top: 13px !important; 
}
}

.pg-title {
  @include mq(991px) {
    margin-left: -17px;
  }
}

.new-feature {
  z-index: 196;
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  margin-left: -34px;
  max-height: auto;
  overflow-y: auto;

  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.4)
  );

  @include mq(970px) {
    margin-left: -182px;
  }

  @include mq(650px) {
    margin-left: -219px;
  }

  @include mq(470px) {
    margin-left: -231px;
  }

  @include mq(450px) {
    margin-left: -205px;
  }

  @include mq(380px) {
    margin-left: -174px;
  }

  @include mq(338px) {
    margin-left: -175px;
  }
}

.tip-div {
  display: flex;
  max-width: 30%;
  margin: 0 auto;
  flex-flow: column;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  @include mq(976px) {
    max-width: 25%;
  }
}

.bottom-tip {
  min-height: 100px;
  background: #fff;
  min-width: 400px;
  padding: 20px;
  border-top: 1px solid #f2f2f2;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  @include mq(450px) {
    min-width: 350px;
  }

  @include mq(380px) {
    min-width: 300px;
  }
}

.top-tip {
  min-height: 50px;
  background: #fafafa;
  min-width: 400px;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  @include mq(450px) {
    min-width: 350px;
  }

  @include mq(380px) {
    min-width: 300px;
  }
}

.sold-out {
  // display: inline-block;
  display: flex;
  justify-content: flex-end;

  p {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;

    font-size: 10px;
    border-radius: 3px;
    padding: 2px 8px;
    background-color: red;
  }
}
.phased-out{
 display: flex;
  justify-content: flex-end;

   p {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;

    font-size: 10px;
    border-radius: 3px;
    padding: 2px 8px;;
   background:#dda812
  }
}
.paid-out {
  // display: inline-block;

  display: flex;
  justify-content: flex-end;

  p {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    font-size: 10px;
    border-radius: 3px;
    padding: 2px 8px;
    background-color: green;
  }
}

.sold-out-mob {
  color: rgb(255, 255, 255);
  background: red;
  padding: 3px;
  border-radius: 3px;
}
.phased-out-mob {
  color: rgb(255, 255, 255);
  background: #dda812;
  padding: 3px;
  border-radius: 3px;
}

.paid-out-mob {
  color: rgb(255, 255, 255);
  background: green;
  padding: 3px;
  border-radius: 3px;
}

.info-block {
  min-height: 100px;
  display: flex;
  background: $lightBg;
  border-radius: 10px;
  padding: 20px;

  .num-block {
    flex-grow: 1;
    flex-basis: 4%;
    padding-right: 10px;

    h3 {
      margin-bottom: 0rem !important;
      font-size: 16px;
      font-weight: 600;
    }

    .num-case {
      border-radius: 50%;
      height: 50px;
      width: 50px;
      border: 1px solid $termsBorder;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .logo-block {
    flex-grow: 1;
    flex-basis: 5%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .detail-block {
    flex-grow: 21;
    flex-basis: 80%;

    h4 {
      text-transform: uppercase;
      color: $hearderColor;
      font-weight: bold;
      font-size: 20px;
    }

    p {
      text-align: justify;
    }

    h6 {
      color: $termsBorder;
      font-weight: bold;
      font-size: 15px;
    }
  }
}

.contact-div {
  display: flex;

  .email-div {
    padding-top: 50px;
    // background: $colorWhite;
    flex-basis: 100%;
    display: flex;
    align-items: center;
    min-height: 92vh;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
  }

  .contact-support {
    min-height: 90vh;
    background: $lightBg;
    display: flex;
    justify-content: center;
    flex-basis: 50%;
    flex-direction: column;
    align-items: center;
  }

  .message-box {
    border: 1px solid #e0e0e0;
    display: flex;
    border-radius: 10px;
    min-width: 100%;
    box-shadow: 0 4px 8px rgba(59, 64, 69, 0.2) !important;

    div {
      flex-basis: 40%;
    }

    .icon-div {
      flex-basis: 20%;
      padding-left: 20px;
      flex-grow: 3;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .contact-mes {
      flex-basis: 50%;
      padding: 20px;
      flex-grow: 10;
      font-weight: 600;
    }

    .circle-div {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-basis: 20%;
    }
  }
}

.about-div {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;

  h4 {
    font-size: 19px;
    font-weight: 600;
    color: $hearderColor;
  }

  .block-detail {
    flex-basis: 70%;
  }
}

.no-border {
  input {
    box-shadow: 0 0px 0 0 transparent !important;
  }
}

#winPic2 {
  background-size: cover;
  box-shadow: rgba(45, 45, 45, 0.05) 0px 2px 2px,
    rgba(49, 49, 49, 0.05) 0px 4px 4px, rgba(42, 42, 42, 0.05) 0px 8px 8px,
    rgba(32, 32, 32, 0.05) 0px 16px 16px, rgba(49, 49, 49, 0.05) 0px 32px 32px,
    rgba(35, 35, 35, 0.05) 0px 64px 64px;
}

.fa-twitter,
.fa-linkedin,
.fa-facebook,
.fa-whatsapp,
.fa-telegram {
  padding: 5px;
  border-radius: 2px;
  cursor: pointer;
  color: $colorWhite;
  margin-right: 7px;
  margin-top: 5px;
  min-width: 22px;
}

.fa-twitter,
.fa-telegram {
  background: #1da1f2;
}

.modal-body > .fa-twitter,
.fa-facebook,
.fa-whatsapp,
.fa-linkedin {
  font-size: 25px;
  margin: 16 auto;
}

.fa-linkedin {
  background: #0077b5;
}

.fa-facebook {
  background: #3578e5;
}

.fa-whatsapp {
  background: #69e781;
}

.fade-ele-btn {
  color: $lightBorder;
  background: $lightBg;
  min-height: 35px;
  text-align: center;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  // max-width: 200px;
}

.table-btn {
  background: $lightBg;
  min-height: 35px;
  text-align: center;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  // max-width: 200px;
}

// hide drift widget on max-width 0f 767 pixels
._2ngXYtlcHXA-EjBwuz_RKI {
  display: none !important;
}

iframe#drift-widget.drift-widget-welcome-online {
  display: none !important;
}

.navbar-expand .navbar-nav .dropdown-menu {
  z-index: 1004;
}

.picture-preview {
  background-color: transparent !important;
  z-index: 0 !important;
}

.picture-inner {
  border: 1px solid #b9c1d9 !important;
  border-radius: 5px !important;
  z-index: 0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.rate-div {
  display: flex;
  justify-content: center;

  span i {
    background: transparent;
    font-size: 20px;
  }

  label {
    margin-bottom: 0rem;
  }
}

.rating {
  border: none;
  float: left;
}

.rating > input {
  display: none;
}

.rating > label:before {
  margin: 5px;
  font-size: 1.25em;
  font-family: FontAwesome;
  display: inline-block;
  content: "\f005";
}

.rating > .half:before {
  content: "\f089";
  position: absolute;
}

.rating > label {
  color: #ddd;
  float: right;
}

/***** CSS Magic to Highlight Stars on Hover *****/

.rating>input:checked~label,
/* show gold star when clicked */
.rating:not(:checked)>label:hover,
/* hover current star */
.rating:not(:checked)>label:hover~label {
  color: #ffd700 !important;
}

/* hover previous stars in list */

.rating>input:checked+label:hover,
/* hover current star when changing rating */
.rating>input:checked~label:hover,
.rating>label:hover~input:checked~label,
/* lighten current selection */
.rating>input:checked~label:hover~label {
  color: #ffed85;
}

.pin-div {
  background: linear-gradient(0deg, #fafafa, #fafafa),
    linear-gradient(0deg, #ffffff, #ffffff), #23afd9;
  min-height: 100px;
}

textarea {
  padding: 6px;
}

.create-pin-btn {
  background: $sellBorder;
  border: 1px solid $sellBorder;
  min-width: 250px;
  margin: 0 auto;
  border-radius: 5px;
  height: 50px;
  font-weight: bold;
}

.com-pub {
  min-height: 100px;
  background: #fffaf0;
  border: 1px solid #ffe8b3;
  border-radius: 5px;
}

.vest-box {
  min-height: 400px;
  background: #fff;
  min-width: 100%;
  border-radius: 10px;
  display: flex;
  max-width: 100%;
  padding: 20px;
  justify-content: space-evenly;

  .img-div {
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 45%;
  }

  .text-div {
    flex-basis: 45%;
  }

  @include mq(767px) {
    flex-direction: column;
    padding: 10px;
    justify-content: center;

    .vest-btn{
      min-width: 100%;
    }
  }
  @include mq(370px) {

    padding: 0px;
  
  }
}

.vest-input {
  border: 1px solid $termsBorder;
  border-radius: 5px;
  min-height: 35px;
  padding: 10px;
}

.vest-btn {
  display: flex;
  max-width: 350px;
  border: 1px solid $termsBorder;
  border-radius: 5px;
  min-height: 80px;
  align-items: center;
  padding: 15px;
  background: $colorWhite;

  .first-div {
    flex-basis: 70%;
  }
  .sec-div {
    flex-basis: 30%;
  }

  @include mq(767px) {
    padding: 10px;
    min-height: 50px;
  }
}

.tran-height {
  height: 0;
  transition: height 0.5s ease;
  overflow: hidden;
}

.tran-show-height {
  height: 50px;
}

.vest-btn-2 {
  border: 1px solid #e6f4ff;
  background: #e6f4ff;
  min-width: 200px;
  min-height: 50px;
  border-radius: 10px;
  transition: background 0.6s ease;
}

.row {
  max-width: 100% !important;
}

.display-flex{

  display:flex;
  align-items:center;

  div{
flex-basis:50%;

  }
}


.vest-container {
  display: flex;
  flex-direction: column;
  min-height: 70vh;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;
}
@include mq(767px) {
  .vest-container {
max-width: 600px;
 justify-content: flex-start;
  }
}
.input-group-text {
background-color: #fff !important;
    border-top-left-radius: 2.25rem !important;
    border-bottom-left-radius: 2.25rem !important;
}
.tab-div {
  min-width: 60%;
}

@media (max-width: 600px) {
  .tab-div {
    min-width: 100%;
  }
}

@media (max-width: 767px) {
  .drift-chat-div {
    display: none;
  }
  .topbar {
    height: 3.375rem;
}

.project-tab {
    padding: 1% !important;
    padding-top: 10% !important;
   
}
}
.auth-nav-btn{

  padding:0.5rem !important;
}

@media (max-width: 650px) {
.auth-nav-btn{

  padding:0rem !important;
  .guest-auth-btn {

    margin: 0px 3px;
}
}

}

.flex-22{

    flex-basis:22% !important;
}
.flex-36{

    flex-basis:36% !important;
}
.flex-38{

    flex-basis:38% !important;
}
.flex-45{

    flex-basis:45% !important;
}
 .enable-input{

    background: #fff;
    border: 1px solid #BDBDBD; 
    border-radius: 5px;
    padding: 5px;
    min-height: 45px;
    color: #000;
    min-width: 100%;
    margin-bottom: 10px;
}
.enable-input:focus{
   border: 1px solid #0094FF; 
  }
  .active-market-color{

    color:#0094FF;
  }

  .active-book-filter{
border-radius: 26px; 
color:#0094FF;
background:#F5F5F5;
  }

 

    @media(max-width:1500px){
.zoom-80{
    zoom:80%;
}
}
    @media(max-width:991px){
.zoom-80{
    zoom:100%;
}
}
  .rotate-icon{

   transition: transform .5s ease;
   transform: rotate(180deg) ;
    transform-origin: center center;
}

.horizontal-item-scroll{
    width: 398px;
    overflow: hidden;
    overflow-x: scroll;
    flex-basis:100% !important;
}

.neg-margin{
    margin-top:-60px
}
</style>
