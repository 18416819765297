import Vue from "vue";
import Vuex from "vuex";
import domElements from "./modules/dom";
import market from "./modules/marketPlace";
import auth from "./modules/auth";
Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    domElements,
    auth,
    market
  }
});

export default store;
